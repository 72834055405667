@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt056-menu-mob {
  $root: &;
  $evidence-card-max-width: 400px;
  display: block;
  position: fixed;
  left: -100%;
  width: 100%;
  top: 0;
  height: 100vh;
  pointer-events: none;
  transition: left 0.4s ease-in-out;
  z-index: setZ(header);

  &--open {
    left: 0;
  }

  @include respond-above(l) {
    display: none;
  }

  &__menu {
    display: block;
    position: absolute;
    @extend %container;
    padding-top: rem(17px);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);

    &:not(#{&}--root) {
      transition: left 0.3s ease-in-out;
    }

    &--root,
    &.active {
      left: 0;
      pointer-events: all;
    }
  }

  &__submenu {
    display: block;
    position: absolute;
    @extend %container;
    padding-top: rem(17px);
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);

    &:not(#{&}--root) {
      transition: left 0.3s ease-in-out;
    }

    &--root,
    &.active {
      left: 0;
      pointer-events: all;
    }
  }

  &__top {
    height: rem(24px);
    margin-bottom: rem(16px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__scroller {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{rem(24px)} - #{rem(16px)});
    padding-bottom: rem(34px);
    overflow-y: auto;
  }

  &__close {
    font-size: rem(24px);
  }

  &__searchInput {
    margin-bottom: rem(16px);
    color: var(--color-primaryGray);

    .rt008-text-field__label {
      color: var(--color-primaryGray);
    }

    .rt008-text-field__icon {
      color: var(--color-primaryGray);
    }
  }

  &__menuItem {
    position: relative;
    display: block;
    padding-bottom: rem(8px);
    border-bottom: 1px solid lightness(--color-black, 90%);
    margin-bottom: rem(16px);

    font-size: rem(16px);
    line-height: rem(24px);
    @extend %dmSansFontMedium;
    color: var(--color-darkCharcoal);

    &--submenu {
      &::after {
        position: absolute;
        @extend %ecIcon;
        content: $ec-icon-chevron-right;
        right: 0;
        font-size: rem(24px);
      }
    }
  }

  &__social {
    display: flex;
    align-items: flex-end;
    flex: 1 1 auto;
  }

  &__socialLabel {
    @extend %dmSansFontRegular;
    font-size: rem(14px);
    line-height: rem(20px);
    margin-right: rem(16px);
    color: var(--color-primaryGray);
  }

  &__socialItems {
    display: flex;
  }

  &__socialItem {
    margin-right: rem(16px);
  }

  &__gotos {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(6px);
  }

  &__gotoLink {
    position: relative;
    @extend %dmSansFontMedium;
    font-size: rem(14px);
    line-height: rem(16px);
    display: block;
    width: calc(50% - #{rem(8px)});
    margin-right: rem(16px);
    padding: 8px 8px;
    background-color: var(--color-neutral);
    border-radius: 8px;
    margin-bottom: rem(8px);
    color: var(--color-primaryGray);

    &:nth-child(2n) {
      margin-right: 0;
    }

    &::after {
      position: absolute;
      @extend %ecIcon;
      content: $ec-icon-chevron-right;
      right: rem(8px);
      font-size: rem(16px);
    }
  }

  &__gotoLinkIcon {
    font-size: rem(16px);
    line-height: 1em;
    margin-right: rem(8px);
  }

  &__back {
    font-size: rem(24px);
    transform: rotateY(180deg);
  }

  &__menu--root {
    #{$root}__top {
      height: rem(40px);
      margin-bottom: rem(32px);
    }

    #{$root}__scroller {
      height: calc(100% - #{rem(40px)} - #{rem(32px)});
    }
  }

  &__subTitle {
    @extend %dmSansFontBold;
    font-size: rem(20px);
    line-height: rem(24px);
    margin-bottom: rem(32px);
  }

  &__columnTitle {
    position: relative;
    display: block;
    padding-bottom: rem(8px);
    border-bottom: 1px solid lightness(--color-black, 90%);
    margin-bottom: rem(16px);

    font-size: rem(16px);
    line-height: rem(24px);
    @extend %dmSansFontMedium;
  }

  &__brands {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex: 1 1 auto;
  }

  &__brand {
    display: block;
    width: calc(50% - #{rem(8px)});
    border-radius: rem(12px);
    box-shadow: 0 1px 1px 0 lightness(--color-darkCharcoal, 85%), 0 0 1px 0 lightness(--color-darkCharcoal, 85%);
    padding-top: rem(7px);
    margin-bottom: rem(16px);

    &:nth-of-type(odd) {
      margin-right: rem(8px);
    }

    &:nth-of-type(even) {
      margin-left: rem(8px);
    }
  }

  &__brandTxt {
    font-size: rem(10px);
    line-height: rem(14px);
    text-align: center;
    background-color: var(--color-neutral);
    padding: rem(4px) rem(8px) rem(8px) rem(8px);
    border-bottom-left-radius: rem(12px);
    border-bottom-right-radius: rem(12px);
  }

  &__brandImg {
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }

  &__brandImgContainer {
    padding: 0 rem(7px);
    display: flex;
    height: rem(49px);
    width: 100%;
    margin-bottom: rem(7px);
    align-items: center;
    justify-content: center;
  }

  &__brandsTitle {
    display: block;
    width: 100%;
  }

  &__evidenceTitle,
  &__brandsTitle {
    display: block;
    @extend %dmSansFontRegular;
    font-size: rem(14px);
    line-height: rem(20px);
    color: var(--color-primaryGray);
    margin-bottom: rem(14px);
  }

  &__evidenceImage {
    display: block;
    width: 100%;
    height: rem(128px);
    object-fit: cover;
    max-width: $evidence-card-max-width;
  }

  &__evidenceLink {
    position: relative;
    display: block;
    border-radius: rem(12px);
    overflow: hidden;
    max-width: $evidence-card-max-width;
  }

  &__evidenceLabel {
    position: absolute;
    display: block;
    height: rem(34px);
    width: 100%;
    bottom: 0;
    background: var(--color-neutral);
    font-size: rem(14px);
    line-height: rem(20px);
    padding: rem(5px) rem(8px);
  }

  &__menu--brands {
    #{$root}__menuItem {
      &:last-child {
        margin-bottom: rem(32px);
      }
    }
  }

  &__menu--wcard {
    #{$root}__menuItem {
      &:last-child {
        margin-bottom: rem(32px);
      }
    }
  }

  &__menu--twocolumns {
    #{$root}__columnItems {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: rem(16px);
    }

    #{$root}__menuItem {
      border: 0;
      display: block;
      width: calc(50% - #{rem(16px)});
    }
  }
}
