@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt251-select-pos-step2-map {
    display: none;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;

    &--preShow {
        display: block;
        opacity: 0;
        pointer-events: none;
    }

    &--show {
        opacity: 1;
        pointer-events: all;
    }

    &__map {
        width: 100%;
        height: 100%;
    }

    &__closer {
        position: absolute;
        right: rem(40px);
        top: rem(40px);
        background: lightness(--color-darkCharcoal, 20%);
        height: rem(56px);
        width: rem(56px);
        padding: rem(20px);
        border-radius: rem(12px);
        color: var(--color-white);
        display: none;

        @include respond-above(l) {
            display: block;
        }
    }
}