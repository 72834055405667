@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt211-card-lanci {
    $root: &;
    width: 100%;
    min-height: rem(471px);
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    @include respond-above(l) {
        min-height: rem(446px);
    }

    &__image.rt022-picture--cover {
        border-radius: rem(12px);
        height: rem(270px);

        @include respond-above(l) {
            height: rem(214px);
        }
    }

    &__logo {
        width: rem(90px);
        height: rem(52px);
        object-fit: contain;
        margin-top: rem(16px);
    }

    &__texts {
        flex: 1 1 auto;

        margin-top: rem(16px);
    }

    &__logo ~ &__texts {
        margin-top: rem(8px);
    }

    &__title {
        @extend %dmSansFont;
        font-size: rem(18px);
        font-weight: 500;
        line-height: rem(24px);
        margin-bottom: rem(8px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        font-weight: 400;
        color: var(--color-primaryGray);
        line-height: rem(20px);
        padding-bottom: rem(16px);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &--small {
        min-height: rem(336px);
    }

    &--large {
        min-height: rem(446px);

        #{$root}__image {
            height: rem(270px);
        }
    }

    &__ctaLink {
        font-size: rem(16px);
        line-height: rem(24px);
    }
}
