@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt071-flyer-filters {
    &--container {
        @extend %container;
    }

    &__carousel {
        overflow: hidden;
        width: 100%;
        padding-bottom: rem(24px);
        margin-top: rem(-10px);
    }

    &__title {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(16px);
    }

    &__track {
        display: flex;
        width: 100%;
        padding-top: rem(10px);
    }

    &__slideContainer {
        display: flex;
        width: fit-content;
        padding-left: rem(5px);
        padding-right: rem(5px);
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: rem(19px);
        width: 100%;
    }

    &__prevNext {
        display: flex;
        flex-direction: row;
    }

    &__btn {
        @extend %buttonReset;
        cursor: pointer;
        transform: translateY(-50%);

        &--prev {
            display: none;
            transform: translateY(-50%) rotateZ(180deg);

            @include respond-above(l) {
                display: block;
                margin-right: rem(18px);
                margin-left: rem(18px);
                margin-top: rem(8px);
            }
        }

        &--next {
            display: none;

            @include respond-above(l) {
                display: block;
                margin-top: rem(8px);
            }
        }

        &--hidden {
            display: none;
        }

        &--disabled {
            opacity: 0.5;
        }

        &::after {
            content: '';
            background-image: url('#{$resourcesFolder}/corporate/icons/next.svg');
            display: block;
            background-size: rem(16px);
            background-repeat: no-repeat;
            background-position: center;
            width: rem(16px);
            height: rem(10px);
        }
    }

    &__dsk {
        display: none;
        flex-flow: row wrap;
        gap: rem(16px);
        
        @include respond-above(l) {
            display: flex;
            flex-direction: column;
        }
    }

    &__cta {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        display: flex;
        align-items: center;
        cursor: pointer;

        & > span:first-child {
            margin-right: rem(5px);
        }

        & > span:last-child {
            text-decoration: underline;
        }
    }

    &__filters {
        display: flex;
        flex-direction: row;
    }

    &__filter {
        margin-right: rem(16px);
    }

    &__form {
        width: 100%;
    }

    &__productsTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__productsNumber {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        margin-top: rem(8px);

        @include respond-above(l) {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }
    
    &__mob {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}