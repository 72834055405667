@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt057-store-header {
    $root: &;
    background-color: var(--color-secondary3);
    position: relative;

    &__content {
        background-color: var(--color-neutral);
    }

    &__container {
        @extend %container;
        padding: 0;

        @include respond-above(l) {
            display: flex;
        }
    }

    //////////// LEFT CONTENT

    &__left {
        display: flex;
        flex-direction: column;
        @extend %container;
        padding-top: rem(52px);
        background-color: var(--color-secondary3);
        padding-bottom: rem(32px);
        flex: 0 0 auto;

        @include respond-above(l) {
            margin: 0;
            width: poc(392px, 1024px);
        }
    }

    &__insigniaPref {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__insignia {
        width: rem(158px);
        height: rem(52px);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('#{$resourcesFolder}/corporate/icons/conad.svg');
        background-size: contain;
    }

    &__preferred {
        color: var(--color-primary1);

        &:hover {
            color: var(--color-primary1);
        }

        &--hide {
            visibility: hidden;
        }
    }

    &__address {
        margin-top: rem(29px);
        @extend %dmSansFontBold;
        line-height: rem(24px);
        font-size: rem(20px);
        margin-bottom: rem(50px);

        @include respond-above(l) {
            flex: 1 0 auto;
            line-height: rem(32px);
            font-size: rem(24px);
        }
    }

    &__telDirections {
        display: flex;
        padding-bottom: rem(16px);
        border-bottom: 1px solid lightness(--color-black, 90%);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            justify-content: space-between;
        }
    }

    &__tel,
    &__directions {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);

        display: block;
        .text {
            text-decoration: underline;
        }
        .icon {
            margin-right: rem(8px);
            font-size: rem(16px);
            vertical-align: text-bottom;
        }

        @include respond-above(m) {
            white-space: nowrap;
        }
    }

    &__coop {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(18px);
        padding-top: rem(15px);
    }

    &__coopName {
        @extend %dmSansFontBold;
        text-transform: uppercase;
    }

    &__directions {
        margin-left: rem(76px);
    }

    &__stateLink {
        display: flex;
        justify-content: space-between;
    }

    &__state {
        @extend %dmSansFont;
        font-size: rem(16px);
        line-height: rem(24px);
    }

    //RIGHT CONTENT
    &__right-bg {
        background-color: var(--color-grayscale8);
        display: none;
        position: absolute;
        width: 50%;
        left: 50%;
        height: 100%;
        top: 0;
        z-index: 0;

        @include respond-above(l) {
            display: block;
        }
    }

    &__right {
        background-color: var(--color-grayscale8);
        padding-top: rem(32px);
        padding-bottom: rem(32px);
        flex: 1 1 auto;
        width: 100%;
        z-index: 1;

        @include respond-above(l) {
            height: rem(438px);
            width: poc(632px, 1024px);
            padding-top: rem(63px);
            padding-bottom: rem(67px);
        }

        &--noFlyer {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    //FLYIER ITEM
    &__flyer {
        display: flex;
        background-color: var(--color-white);
        padding: rem(8px);
        margin-bottom: rem(16px);
        border-radius: rem(12px);
        flex: 0 0 auto;

        @include respond-above(l) {
            width: rem(224px);
            height: rem(244px);
            flex-direction: column;
            padding: rem(16px);
        }

        @include respond-below(l) {
            /* !important needed to override style attribute put by Swiper */
            margin-right: 0 !important;
        }
    }
    &__flyersTrack &__flyer--hidden {
        @include respond-below(l) {
            display: none;
        }
    }

    &__flyerImg {
        margin-right: rem(16px);
        max-height: 100%;
        @include respond-above(l) {
            max-width: rem(82px);
            max-height: rem(103px);
            margin-right: 0;
            margin-bottom: rem(16px);
        }
    }

    &__flyerValidity {
        @extend %dmSansFontRegular;
        line-height: rem(16px);
        font-size: rem(12px);
        margin-bottom: rem(4px);

        @include respond-above(l) {
            margin-bottom: rem(8px);
        }
    }

    &__flyerTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        margin-bottom: rem(8px);
        flex: 1 1 auto;
    }

    &__flyerLink {
        @extend %dmSansFontMedium;
        text-decoration: underline;
        color: var(--color-semantic4);
        line-height: rem(16px);
        font-size: rem(12px);

        @include respond-above(l) {
            display: none;
        }
    }

    //FLYIERS LIST
    &__flyers {
        @extend %container;

        @include respond-above(l) {
            margin: 0;
            padding-left: rem(48px);
        }
    }
    &__flyers--noArrows {
        @include respond-above(l) {
            #{$root}__flyersPrev,
            #{$root}__flyersNext {
                display: none;
            }
        }
    }

    &__flyersCarousel {
        overflow: hidden;
        width: 100%;
    }

    &__flyersTitle {
        @extend %dmSansFontBold;
        line-height: rem(24px);
        font-size: rem(18px);
        margin-bottom: rem(24px);
        flex: 1 1 auto;

        @include respond-above(l) {
            margin-bottom: 0;
        }
    }

    &__flyersTop {
        display: flex;
        align-items: center;

        @include respond-above(l) {
            margin-bottom: rem(24px);
        }
    }

    &__flyersPrev,
    &__flyersNext {
        display: none;
        cursor: pointer;

        @include respond-above(l) {
            display: block;
            flex: 0 0 auto;
        }

        &--disabled {
            cursor: default;
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &__flyersPrev {
        margin-right: rem(16px);
    }

    &__flyersTrack {
        width: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        &--mod {
            flex-direction: column;
        }

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__loadMore {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-semantic4);
        display: block;
        margin: rem(24px) auto;
        align-self: center;

        @include respond-above(l) {
            display: none;
        }
    }
    &__loadMore:hover {
        color: var(--color-semantic4);
    }
    &__loadMore--hidden {
        display: none;
    }

    &__strilloTop {
        background-color: #fed404;
        position: relative;
        z-index: 1;

        .rt024-strillo__img {
            display: block;
        }

        .rt024-strillo__imgMob {
            display: none;
        }

        .rt024-strillo__container {
            justify-content: center;
        }

        .rt024-strillo__text {
            text-align: center;
        }
    }

    &__strilloBottom {
        position: relative;
        z-index: 1;
    }

    &__contSlider {
        position: relative;
        background-color: white;
    }

    &__fuelCard {
        min-width: rem(224px);
        min-height: rem(136px);

        @include respond-above(l) {
            min-height: rem(221px);
        }
    }

    &__image {
        width: rem(162px);
        height: rem(140px);
        object-fit: contain;
        display: flex;
        align-self: center;
        justify-content: center;
        @include respond-below(l) {
            margin-top: rem(129px);
        }
    }

    &__temporaryTitle {
        @extend %dmSansFontBold;
        line-height: rem(24px);
        font-size: rem(16px);
    }

    &__temporaryText {
        @extend %dmSansFontRegular;
        line-height: rem(20px);
        font-size: rem(14px);
    }

    &__temporaryTexts {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-top: rem(8px);
        @include respond-below(l) {
            margin-bottom: rem(130px);
        }
    }

    /* store (specialized) */
    &[data-insignia='PARAFARMACIA CONAD'] &__insignia {
        width: rem(130px);
        background-image: url('#{$resourcesFolder}/corporate/icons/parafarmacia-store.png');
    }

    &[data-insignia='PARAFARMACIA CONAD'] &__left, &[data-insignia='PARAFARMACIA CONAD'] {
        background-color: var(--color-bgStorePara1);
    }

    &[data-insignia='PARAFARMACIA CONAD'] &__right, &[data-insignia='PARAFARMACIA CONAD'] &__right-bg {
        background-color: var(--color-bgStorePara2);
    }

    &[data-insignia='OTTICO CONAD'] &__insignia {
        width: rem(110px);
        background-image: url('#{$resourcesFolder}/corporate/icons/eye.png');
    }

    &[data-insignia='OTTICO CONAD'] &__left, &[data-insignia='OTTICO CONAD'] {
        background-color: var(--color-bgStoreOttico1);
    }

    &[data-insignia='OTTICO CONAD'] &__right, &[data-insignia='OTTICO CONAD'] &__right-bg {
        background-color: var(--color-bgStoreOttico2);
    }

    &[data-insignia='CONAD SELF 24h'] &__left, &[data-insignia='CONAD SELF 24h'] {
        background-color: var(--color-bgStoreConadSelf1);
    }

    &[data-insignia='CONAD SELF 24h'] &__right, &[data-insignia='CONAD SELF 24h'] &__right-bg {
        background-color: var(--color-bgStoreConadSelf2);
    }

    &[data-insignia='PET STORE CONAD'] &__insignia {
        width: rem(110px);
        height: rem(66px);
        background-image: url('#{$resourcesFolder}/corporate/icons/pet-store.png');
        border-radius: rem(12px);
    }

    &[data-insignia='PET STORE CONAD'] &__left, &[data-insignia='PET STORE CONAD'] {
        background-color: var(--color-bgStorePetStore1);
    }

    &[data-insignia='PET STORE CONAD'] &__right,  &[data-insignia='PET STORE CONAD'] &__right-bg {
        background-color: var(--color-bgStorePetStore2);
    }

    &[data-insignia='PET STORE CONAD'] &__telDirections, &[data-insignia='PARAFARMACIA'] &__telDirections,&[data-insignia='OTTICO'] &__telDirections {
        border-bottom: 1px solid var(--color-black);
    }

    &[data-insignia='CONAD SELF 24h'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/fuel.png');
    }

    &[data-insignia='MARGHERITA CONAD'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/margherita-conad.png');
    }

    &[data-insignia='CONAD'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/conad.svg');
    }

    &[data-insignia='MARKET CONAD'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/conad.svg');
    }

    &[data-insignia='CONAD CITY'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-city.svg');
    }

    &[data-insignia='CONAD IPERMERCATO'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-ipermercato.svg');
    }

    &[data-insignia='SAPORI & DINTORNI CONAD'] &__insignia {
        width: rem(116px);
        background-image: url('#{$resourcesFolder}/corporate/icons/sapori-e-dintorni.png');
    }

    &[data-insignia='SPAZIO CONAD'] &__insignia {
        background-image: url('#{$resourcesFolder}/corporate/icons/spazio-conad.png');
    }

    &[data-insignia='SPESA FACILE'] &__insignia {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-spesa-facile.png');
    }

    &[data-insignia='TUDAY CONAD'] &__insignia {
        background-image: url('#{$resourcesFolder}/corporate/icons/tuday-conad.png');
    }

    &__slider.rt126-slider-card-spec-stores {
        padding-top: rem(32px);

        &--hidden {
            display: none;
        }
    }
}
