@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt151-flyer-listing {
    &__backWrapper {
        @extend %container;
    }

    &__back {
        padding-left: 0;
    }

    &__topWrapper {
        @extend %container;
        margin-bottom: rem(24px);
    }
}
