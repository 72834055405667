@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt126-slider-card-spec-stores {
    @extend %container;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include respond-above(l) {
        width: 100%;
    }

    &__slideContainer {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        @include respond-above(l) {
            margin: 0 auto;
        }
    }

    /* default texts style */

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        margin-bottom: rem(24px);
        color: var(--color-darkCharcoal);
        
        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: rem(21px);
        }
    }
}
