@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt024-strillo {
    position: relative;
    $root: &;

    &__container {
        @extend %container;
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        display: flex;
        flex-direction: row;

        @include respond-above(l) {
            justify-content: center;
            flex-direction: column;
        }
    }

    &__top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: rem(8px);

        @include respond-above(l) {
            justify-content: center;
        }
    }

    &__img {
        margin-right: rem(8px);
        height: rem(32px);
        width: rem(40px);
        display: none;

        @include respond-above(l) {
            display: block;
        }
    }

    &__imgMob {
        margin-right: rem(8px);
        height: rem(38px);
        width: rem(40px);
        display: block;
        align-self: center;
        flex: 0 0 auto;

        @include respond-above(l) {
            display: none;
        }
    }

    &__link {
        display: block;
        flex: 1 1 auto;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(20px);
        }
    }

    &__text {
        text-align: left;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            text-align: center;
        }
    }

    &--cta {
        #{$root}__text {
            text-decoration: underline;
        }
    }

}
