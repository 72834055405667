@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.rt066-result-premio {
    @extend %container;
    position: relative;
    display: flex;
    flex-flow: column;
    gap: rem(40px);

    &__container {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__image {
        max-width: rem(254px);
        max-height: rem(158px);


        @include respond-above(l) {
            max-width: rem(304px);
            max-height: rem(189px);
        }
    }

    &__textContainer {
        @include respond-above(l) {
            margin-left: rem(70px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-darkCharcoal);
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: unset;
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(16px);
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: rem(32px);
        margin-top: rem(32px);

        @include respond-above(l) {
            flex-flow: row-reverse;
            margin-top: rem(64px);
        }
    }

    &__homeLink {
        @include respond-below(l) {
            width: 100%;
            text-align: center;
        }
    }

    &__playCodeLink {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
    }
}
