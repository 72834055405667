@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='sapori-e-idee'],
body.theme-sapori-e-idee {
    @include defineColorsHexMap(map.merge($default-theme, $sapori-e-idee-theme));
}
