@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt070-contact-heading {
    @extend %container;
    display: flex;
    flex-flow: column;
    gap: rem(24px);
    
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: lightness(--color-black, 20%);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__image {
        height: rem(127px);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 60%);
    }
}
