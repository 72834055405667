@use '../modules/commons' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:math';

//== foundational variables ==//
$black: hsl(0, 0%, 4%) !default;
$white: hsl(0, 0%, 100%) !default;
$light: hsl(0, 0%, 96%) !default; // white-ter
$dark: hsl(0, 0%, 21%) !default; // grey-darker
$link: hsl(217, 71%, 53%) !default; // blue

$grey-dark: lighten($black, 25%) !default;
$grey-light: darken($light, 25%) !default;
$grey-lighter: darken($light, 10%) !default;

//== datepicker variables ==//
$dp-background-color: $white !default;
$dp-border-color: $grey-lighter !default;
$dp-border-radius: rem(16px) !default;
$dp-border-radius-small: 2px !default;
$dp-line-height-base: 1.5 !default;
$dp-font-size-normal: 1rem !default;
$dp-font-size-small: 0.75rem !default;
$dp-font-weight-semibold: 600 !default;
$dp-font-weight-bold: 700 !default;
$dp-dropdown-offset: 4px !default;
$dp-dropdown-shadow: 0 2px 3px rgba($black, 0.1), 0 0 0 1px rgba($black, 0.1) !default;
$dp-dropdown-z: setZ(top) !default;

$dp-title-background-color: $light !default;

$dp-cell-size-base: 2.25rem !default;
$dp-cell-focus-background-color: darken($light, 5%) !default;
$dp-cell-prevnext-color: hsl(0, 0%, 48%) !default; // grey
$dp-cell-disabled-color: $grey-lighter !default;
$dp-cell-selected-background-color: $link !default;
$dp-cell-selected-color: #fff !default; // link(blue)-invert
$dp-cell-selected-font-weight: 600 !default;
$dp-cell-today-background-color: hsl(171, 100%, 41%) !default; // turquoise (primary)
$dp-cell-today-color: #fff !default; // turquoise-invert
$dp-cell-highlighted-background-color: $light !default;
$dp-range-start-end-background-color: $grey-light !default;
$dp-range-start-end-color: $dp-cell-selected-color !default;
$dp-range-background-color: $grey-lighter !default;
$dp-range-today-background-color: $dp-cell-today-background-color !default;
$dp-week-color: $grey-light !default;

$dp-footer-background-color: $light !default;

$dp-input-in-edit-border-color: darken($link, 5%) !default;
$dp-input-in-edit-focus-box-shadow-size: 0 0 0.25em 0.25em !default;

//== non-configurable variables ==//
$dp-cell-shrink-threshold: $dp-cell-size-base * 10; // = 8 * 1.25
$dp-cell-shrinked-width: math.div($dp-cell-size-base * 7, 8);

//== styles ==//
.datepicker {
    display: none;

    &.active {
        display: block;
    }

    @include respond-below(m) {
        left: 15px ;
    }
}

.datepicker-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $dp-dropdown-z;
    padding-top: $dp-dropdown-offset;

    &.datepicker-orient-top {
        padding-top: 0;
        padding-bottom: $dp-dropdown-offset;
    }
}

.datepicker-picker {
    display: inline-block;
    border-radius: $dp-border-radius;
    background-color: lightness(--color-black, 20%);

    .datepicker-dropdown & {
        box-shadow: $dp-dropdown-shadow;
    }

    span {
        display: block;
        flex: 1;
        border: 0;
        cursor: default;
        text-align: center;
        -webkit-touch-callout: none;
        user-select: none;
    }
}

.datepicker-main {
    padding-bottom: rem(28px);
    padding-left: rem(30px);
    padding-right: rem(30px);
}

.datepicker-footer {
    box-shadow: inset 0 1px 1px rgba($black, 0.1);
    background-color: $dp-footer-background-color;
}

%flex-container {
    display: flex;
}

%flex-wrap {
    flex-wrap: wrap;
}

%flex-basis-day {
    flex-basis: percentage(math.div(1, 7));
}

%flex-basis-month-year {
    flex-basis: 25%;
}

%datepicker-cell-height {
    height: rem(40px);
    line-height: rem(40px);
}

.datepicker-title {
    box-shadow: inset 0 -1px 1px rgba($black, 0.1);
    background-color: $dp-title-background-color;
    padding: 0.375rem 0.75rem;
    text-align: center;
    font-weight: $dp-font-weight-bold;
}

.datepicker-controls {
    @extend %flex-container;

    .datepicker-header & {
        padding-bottom: rem(16px);
        padding-top: rem(28px);
        padding-left: rem(30px);
        padding-right: rem(30px);
    }

    .button {
        
        @extend %font-label;
        font-weight: 500;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 0;
        box-shadow: none;
        cursor: pointer;
        text-align: center;
        white-space: nowrap;
        color: var(--color-white);

        &:focus,
        &:active {
            outline: none;
        }

        &:hover {
            background-color: var(--color-black);
        }

        &[disabled] {
            cursor: not-allowed;
        }
    }

    .view-switch {
        flex: auto;
    }

    .prev-btn,
    .next-btn {
        padding-right: rem(10px);
        padding-left: rem(10px);

        &.disabled {
            visibility: hidden;
        }
    }

    .prev-btn {
        margin-left: rem(-1 * 10px);
    }

    .next-btn {
        margin-right: rem(-1 * 10px);
    }
}

.datepicker-view {
    @extend %flex-container;
    padding-top: rem(9px);
    border-top: 1px solid alpha(--color-white, 0.6);

    .days-of-week {
        @extend %flex-container;
    }

    .dow {
        @extend %font-body2;
        color: var(--color-white);
        margin-bottom: rem(10px);
    }

    .week {
        @extend %datepicker-cell-height;
        @extend %font-body2;
        color: var(--color-white);

        @media (max-width: $dp-cell-shrink-threshold) {
            width: $dp-cell-shrinked-width;
        }
    }
}

.datepicker-grid {
    @extend %flex-container;
    @extend %flex-wrap;

    width: rem(284px);

    @media (max-width: $dp-cell-shrink-threshold) {
        .calendar-weeks + .days & {
            width: $dp-cell-shrinked-width * 7;
        }
    }
}

.datepicker-cell {
    @extend %datepicker-cell-height;
    color: var(--color-white);
    @extend %font-body2;

    &:not(.disabled):hover,
    &.focused:not(.selected) {
        background-color: var(--color-white);
        cursor: pointer;
        color: var(--color-black);
    }

    &.selected {
        &,
        &:hover {
            background-color: var(--color-black);
            font-weight: bold;
        }
    }

    &.disabled {
        pointer-events: none;
        color: alpha(--color-white, 0.3);
    }

    &.prev,
    &.next {
        pointer-events: none;
        color: alpha(--color-white, 0.3);
    }

    &.highlighted:not(.selected):not(.range):not(.today) {
        border-radius: 0;
        background-color: var(--color-black);

        &:not(.disabled):hover {
            background-color: var(--color-black);
        }

        &.focused {
            background-color: var(--color-black);
        }
    }

    &.today {
        &:not(.selected) {
            background-color: var(--color-black);
        }

        &.focused:not(.selected) {
            background-color: var(--color-black);
        }
    }

    &%range-start-end-common {
        background-color: $dp-range-start-end-background-color;
        color: $dp-range-start-end-color;
    }

    &%range-start-end-focused-common {
        background-color: darken($dp-range-start-end-background-color, 2.5%);
    }

    &.range-start {
        border-radius: $dp-border-radius 0 0 $dp-border-radius;

        &:not(.selected) {
            @extend %range-start-end-common;
        }

        &.focused:not(.selected) {
            @extend %range-start-end-focused-common;
        }
    }

    &.range-end {
        border-radius: 0 $dp-border-radius $dp-border-radius 0;

        &:not(.selected) {
            @extend %range-start-end-common;
        }

        &.focused:not(.selected) {
            @extend %range-start-end-focused-common;
        }
    }

    &.range {
        border-radius: 0;
        background-color: $dp-range-background-color;

        &:not(.disabled):not(.focused):not(.today):hover {
            background-color: darken($dp-range-background-color, 2.5%);
        }

        &.disabled {
            color: darken($dp-range-background-color, 10%);
        }

        &.focused {
            background-color: darken($dp-range-background-color, 5%);
        }

        @if $dp-range-today-background-color != $dp-cell-today-background-color {
            &.today {
                background-color: $dp-range-today-background-color;
            }
        }
    }

    .datepicker-view .days & {
        @extend %flex-basis-day;
    }

    .datepicker-view.datepicker-grid & {
        @extend %flex-basis-month-year;
        height: $dp-cell-size-base * 2;
        line-height: $dp-cell-size-base * 2;
    }
}
