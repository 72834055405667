@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.rt109-container-card-text {
    @extend %container;

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);
    }
    &__title--separator {
        @include addSeparator();
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        margin-top: rem(24px);
    }

    &__track {
        position: relative;
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: rem(24px);
            margin-left: rem(-8px);
            margin-right: rem(-8px);
        }

        &--preShow {
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__slide {
        display: block;
        position: absolute;
        width: 100%;
        z-index: 1;
        margin-top: rem(24px);
        transition: all .2s ease-in-out;

        &:first-child {
            margin-top: 0;
        }

        @include respond-above(l) {
            width: calc(#{math.div(100,3) * 1%} - #{rem(16px)});
            margin: rem(8px);
            
            &:first-child {
                margin-top: 8px;
            }
        }
    }

    &__slide &__card {
        width: 100%;
        margin-bottom: 0;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        margin-top: rem(24px);
    }

    &__assetLink {
        display: block;
        margin: rem(24px) auto 0;
    }
}
