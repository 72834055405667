@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt062-faq {
    $root: &;

    &__container {
        @extend %container;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(20px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }

        &::after {
            display: block;
            content: ' ';
            width: 32px;
            height: 4px;
            border-radius: 4px;
            background-color: var(--color-primary2);
            margin-top: rem(10px);
        }
    }

    &__acc {
        @include respond-below(l) {
            padding-left: unset;
            padding-right: unset;
        }
    }
    &__acc:not(:first-child) {
        margin-top: rem(24px);

        @include respond-above(l) {
            margin-top: rem(16px);
        }
    }

    &__accContent {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-primaryGray);
    }

    &__tabs {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        @include respond-above(l) {
            justify-content: unset;
        }

        @include respond-below(l) {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;

            /* margin for scrollbar mobile */
            & > button {
                margin-bottom: rem(10px);
            }
        }

        /* hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

    }

    /* hide scrollbar for Chrome, Safari and Opera */
    &__tabs::-webkit-scrollbar {
        display: none;
    }

    &__tabLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-mediumDarkGrey);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
            padding-bottom: rem(8px);
        }
    }

    /* buttons */
    &__tab {
        @extend %buttonReset;
        flex: 1 0 0;
        position: relative;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        border-width: 2px;
        border-style: solid;
        border-color: var(--color-mediumDarkGrey);
        border-radius: rem(12px);
        padding: rem(20px);
        margin-bottom: 3px;
        max-height: 71px;
        min-width: 128px;

        @include respond-above(l) {
            flex-flow: row;
            border: unset;
            border-radius: unset;
            flex: unset;
            padding: unset;
            max-height: unset;
            min-width: unset;
        }

        /* mobile/desktop div */
        & > div {
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__tab:focus-visible {
        @extend %button-focused;
    }
    &__activeTab {
        border-color: var(--color-black);

        #{$root}__tabLabel {
            color: var(--color-black);
        }

        #{$root}__tabDecoration {
            display: flex;
            width: rem(60px);

            & > div {
                width: rem(32px);
            }
        }
    }

    &__tab {
        margin-right: rem(3px);

        @include respond-above(l) {
            margin-right: rem(16px);
        }
    }

    &__tabDecoration {
        position: absolute;
        bottom: rem(-2.2px);
        width: rem(20px);
        height: rem(2.5px);
        background-color: var(--color-bg);
        display: flex;
        justify-content: center;

        @include respond-above(l) {
            display: none;
            height: rem(4px);
            bottom: rem(-3px);
        }

        & > div {
            width: rem(3px);
            height: inherit;
            background-color: var(--color-yellow);
            border-radius: rem(4px);
        }
    }

    &__content {
        @extend %container; 

        & > div:first-child {
            display: flex;
            flex-flow: column;
            width: 100%;

            @include respond-above(l) {
                & > div:first-of-type {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
    }
}