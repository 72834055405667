@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt060-recipe {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__container {
        @extend %container;
        width: 100%;
        @include respond-above(l) {
            width: poc(624px, 1024px);
        }

        @include respond-below(l) {
            position: relative;
        }
    }

    &__containerIngr {
        width: 100%;
        @extend %container;

        @include respond-above(l) {
            width: 71%;
            padding-top: rem(28px);
            padding-bottom: rem(16px);
        }
    }

    /* default texts style */

    &__text {
        margin-bottom: rem(24px);
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);

        @include respond-above(l) {
            margin-bottom: rem(16px);
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(24px);
        line-height: rem(32px);
        margin-bottom: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__ctaBookmark,
    &__ctaShare {
        border-radius: 50%;
        display: inline-block;
        color: var(--color-bookmark);
        box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        &:hover {
            background-color: #ffffff;
        }
    }

    &__ctas {
        @include respond-below(l) {
            display: flex;
            flex-direction: column;
            position: absolute;
            gap: rem(8px);
            right: rem(16px);
            top: rem(123px);
        }
    }

    &__ctaShare {
        .rt002-cta__icon {
            font-weight: 800;
        }
    }

    &__image.rt022-picture--cover {
        width: 100%;
        height: rem(214px);
        border-radius: rem(12px);
        z-index: 1;

        @include respond-between(l,xl) {
            width: 65%;
            height: rem(240px);
        }

        @include respond-above(xl) {
            width: 65%;
            height: rem(376px);
        }
    }

    &__ctaBookmark {
        margin-right: rem(10px);
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(16px);
    }

    &__pencil {
        color: var(--color-grayscale10);
        margin-right: rem(4px);
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        font-weight: 500;
        line-height: rem(16px);
        margin-bottom: rem(18px);
    }

    &__authorDate,
    &__infoItem {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
    }

    &__role {
        font-weight: 200;
        color: var(--color-darkCharcoal);
    }

    &__authorName {
        font-weight: 500;
        color: var(--color-darkCharcoal);
    }

    &__date {
        font-weight: 200;
        color: var(--color-primaryGray);
    }

    &__info {
        display: flex;
        flex-direction: column;
        color: var(--color-darkCharcoal);
        min-height: rem(110px);
    }

    &__infoItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: rem(16px);
    }

    &__details {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__ingredientsTabContainer {
        position: relative;
        top: -50px;

        @include respond-below(l){
            top: -95px;
        }
        
        width: 100%;
        display: flex;
        justify-content: center;

    }

    &__ingredientsTab {
        background-color: var(--color-secondary3);
        margin-bottom: rem(40px);
        border-radius: rem(12px);
        height: fit-content;

        @include respond-below(l) {
            width: 100%;
            margin: 0;
            margin-bottom: rem(40px);
            padding: rem(16px);
            padding-top: rem(115px);
        }

        @include respond-above(l) {
            width: 86%;
            margin-left: rem(80px);
            margin-right: rem(80px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &__stepsTitle,
    &__ingrTitle,
    &__stepTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-darkCharcoal);
    }

    &__stepsTitle {
        margin-bottom: rem(16px);
    }

    &__barDsk,
    &__barMob {
        height: rem(1px);
        width: 80%;
        background-color: lightness(--color-black, 90%);
        border-radius: rem(12px);
    }

    &__barDsk {
        display: none;

        @include respond-above(l) {
            display: block;
            margin-left: rem(20px);
        }
    }

    &__barMob {
        display: block;
        margin-bottom: rem(16px);

        @include respond-above(l) {
            display: none;
        }
    }

    &__topTab {
        @include respond-below(l) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: rem(16px);
        }

        @include respond-above(l) {
            margin-bottom: rem(28px);
        }
    }

    &__peopleBar {
        @include respond-above(l) {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: rem(28px);
        }

        @include respond-between(m, l) {
            margin-right: rem(70px);
        }
    }

    &__people {
        width: 40%;
        white-space: nowrap;
        color: var(--color-darkCharcoal);
    }

    &__ingredients {
        display: flex;
    }

    &__steps {
        display: flex;
        flex-direction: column;
    }

    &__step {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(40px);

        @include respond-above(m) {
            flex-direction: row;
            margin-bottom: rem(33px);
        }
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(24px);
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-bottom: rem(16px);
            margin-top: rem(8px);
        }
    }

    &__stepText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
    }

    &__stepTexts {
        @include respond-below(m) {
            margin-top: rem(16px);
        }

        @include respond-above(m) {
            margin-left: rem(46px);
        }
 
        @include respond-between(l,xl) {
            width: rem(686px);
        }

        @include respond-above(xl) {
            width: 80%;
        }
    }

    &__stepImage {
        width: 100%;
        height: rem(228px);
        border-radius: rem(12px);

        @include respond-above(l) {
            width: 80%;
            height: rem(140px);
        }

        @include respond-above(xl) {
            width: 70%;
            height: rem(240px);
        }
    }

    &__bottomTab {
        display: flex;
        @include respond-below(l) {
            flex-direction: column;
        }
        @include respond-above(l) {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }

    &__ingredient {
        margin-bottom: rem(16px);
        color: var(--color-darkCharcoal);
        @include respond-above(l) {
            width: 50%;
        }
    }

    &__minus,
    &__plus {
        pointer-events: auto;
        @include respond-above(l) {
            margin-left: rem(12px);
            margin-right: rem(12px);
        }
    }

    &__articleBody {
        margin-bottom: rem(40px);
        color: var(--color-darkCharcoal);
    }

    &__paddingTop {
        padding-top: rem(28px);
    }

    &__video {
        width: 100%;
        height: rem(350px);
        margin-bottom: rem(40px);

        @include respond-below(l) {
            height: rem(211px);
        }
    }

    &__ingredientsActions {
        margin-top: rem(-27px);
    }
}
