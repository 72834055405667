@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt133-flyer-viewer {
    &__wrapper {
        @extend %container;
        display: flex;
        flex-flow: column;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-darkCharcoal);
        text-align: center;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        text-align: center;
    }
    
    &__title ~ &__text {
        margin-top: rem(8px);
    }

    &__validity {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-bookmark);
        text-align: center;
    }
    &__title ~ &__validity,
    &__text ~ &__validity {
        margin-top: rem(8px);
    }

    /* styles needed for Yumpu iframe */
    &__embedContainer {
        position: relative;
        padding-bottom: 56.25%;
        height: 400px;
        overflow: hidden;
        max-width: 100% !important;

        @include respond-above(m) {
            height: 600px;
        }

        @include respond-above(xl) {
            height: 800px;
        }

        & iframe,
        & object,
        & embed {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__title ~ &__embedContainer,
    &__text ~ &__embedContainer,
    &__validity ~ &__embedContainer {
        margin-top: rem(24px);
    }

    &__trailingText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        margin-top: rem(20px);

        @include respond-above(l) {
            margin-top: rem(40px);
        }
    }

    & #yp-sb-0 {
        display: none;
    }

    & #yp-pctb-0 {
        display: none;
    }

    &--hidden {
        opacity: 0;
        width: 0;
        height: 0;
        pointer-events: none;
    }
    
    &__download,
    &__share {
        color: var(--color-primary1);
        flex: 1 0 auto;
        display: flex;
        gap: rem(10px);
        max-width: rem(100px);
        & > span:first-child {
            font-size: rem(16px);
        }
    }

    .rt002-cta-link__label  {
        color: var(--color-primary1);
        text-decoration: underline;
        text-decoration-color: var(--color-primary1);
    }

    &__downloadShare {
        display: flex;
        gap: rem(79px);
        margin-top: rem(16px);
        justify-self: center;
        justify-content: center;

        @include respond-below(l) {
            margin-left: rem(40px);
            margin-right: rem(40px);
            margin-top: rem(20px);
        }
    }
}
