@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt302-add-store-popup {
    --color-cta1: var(--color-primary1);
    --color-cta2: var(--color-semantic4);

    --display-max: none;
    --display-add: block;

    &--maxStores{
        --display-max: block;
        --display-add: none;
    }

    @include actionPopup();

    &__textMax, &__titleMax, &__backToFlyer, &__goTo{
        display: var(--display-max);
    }

    &__textAdd, &__titleAdd, &__storeContainer, &__proceed, &__cancel{
        display: var(--display-add);
    }

    &__storeContainer{
        margin-top: rem(20px);
        padding-left: rem(16px);

        @include respond-above(l) {
            padding-left: rem(24px);
            margin-top: rem(24px);
        }
    }

    &__flyerStoreText{
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        color: #707070;
    }

    &__storeIcon{
        width: rem(16px);
        height: rem(16px);
        
    }

    &__store{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: rem(8px);
        margin-top: rem(8px);
        margin-bottom: rem(2px);
    }

    &__storeName{
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
    }

    &__storeAddress{
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
    }

    &__ctas {
        margin-top: rem(24px);
    }

    &__goTo {
        margin-right: rem(16px);
        background: var(--color-white);
        border: rem(2px) solid var(--color-primary1);
        color: var(--color-black);

        @include respond-below(l) {
            padding: rem(20px) rem(32px);
            width: 100%;
            text-align: center;
        }
    }

}