@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt252-pos-list {
    @include modal(&, true);

    &__wrapper {
        margin-top: rem(16px);
        margin-bottom: rem(31px);

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            width: 4px;
            background-color: alpha(--color-black, 0.2);
            margin-top: 0;
            margin-bottom: 0;
            margin-right: rem(52px);
            opacity: 0.6;
            border-radius: 2px;
            visibility: hidden;
            pointer-events: none;

            @include respond-above(l) {
                visibility: visible;
                pointer-events: all;
            }

            & > .ps__thumb-y {
                width: 4px;
                right: 0;
                background-color: var(--color-black);
                border-radius: 2px;
            }
        }
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: stretch;
        gap: rem(16px);
    }

    &__item {
        width: calc(50% - 16px);
        flex-grow: 1;
        display: flex;
        flex-flow: column;
        gap: rem(4px);
        margin-bottom: rem(16px);
    }

    &__name {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
    }

    &__address {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        flex-grow: 1;
    }

    &__link {
        font-size: rem(12px);
        white-space: nowrap;
    }
}
