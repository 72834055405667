@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt156-excel-to-map-search-stores-form {

    &__regionTitle {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(36px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);

        @include addSeparator();
    }

    &__searchText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        margin-bottom: rem(24px);
    }

    &__form {
        display: flex;
        flex-flow: column;
        gap: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            align-items: center;
        }
    }

    &__filter {
        width: 100%;

        @include respond-above(l) {
            max-width: 220px;
        }
    }

    &__search {
        @include respond-below(l) {
            width: 100%;
            text-align: center;
        }
    }

    &__numResults {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        margin: rem(24px) 0 rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__results {
        display: flex;
        flex-flow: column;
        gap: rem(20px);

        @include respond-above(l) {
            flex-flow: row wrap;
            gap: rem(48px) rem(20px);
        }
    }

    &__loadMore {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        display: block;
        margin: rem(40px) auto 0;

        &--hidden {
            display: none;
        }
    }

    /* store card */
    &__store {
        display: flex;
        gap: rem(16px);

        @include respond-above(l) {
            flex: 0 0 31.9%;
        }
    }

    &__icon {
        height: rem(80px);
        min-width: rem(80px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: rem(20px);
        border-radius: 50%;
        background-color: alpha(--color-neutral, 10%);
        box-shadow: 0 1px 1px 0 alpha(--color-darkCharcoal, 0.15),
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__textContainer {
        display: flex;
        flex-direction: column;
        gap: rem(4px);
    }

    &__name {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(19.2px);
            line-height: rem(25.6px);
        }
    }

    &__subtitle {
       @extend %dmSansFontRegular;
       font-size: rem(12px);
       line-height: rem(16px);
       color: var(--color-primaryGray);

       @include respond-above(l) {
           font-size: rem(16px);
           line-height: rem(24px);
       }
    }

    &__address {
       @extend %dmSansFontRegular;
       font-size: rem(12px);
       line-height: rem(16px);
       color: var(--color-primaryGray);

       @include respond-above(l) {
           font-size: rem(16px);
           line-height: rem(24px);
       }
    }

    &__link {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-semantic4);
        text-decoration: underline;

        @include respond-above(l) {
           font-size: rem(16px);
           line-height: rem(24px);
        }
    }
}
