@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt125-banner-carousel {
    @extend %container;
    width: 100%;

    &__carousel {
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        position: relative;
    }

    &__track {
        width: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
    }

    &__slideContainer {
        width: 100%;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
    }

    &__slide {
        height: 100%;
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: rem(16px);
        left: rem(16px);

        @include respond-above(l) {
            top: rem(24px);
            left: rem(24px);
        }

        &--hidden {
            display: none;
        }
    }

    &__bullet {
        flex: 0 0 auto;
        height: rem(4px);
        width: rem(4px);
        border-radius: rem(9px);
        background-color: alpha(--color-darkCharcoal, 0.7);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem(14px);
        }

        &--active {
            height: rem(16px);
            width: rem(16px);
            background-color: var(--color-primary2) !important; /* necessary to always override custom bg color set via js */
        }
    }
}
