@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt011-select {
    $root: &;
    position: relative;
    width: 100%;

    &--borderless > &__button {
        border-style: hidden;
    }

    &__button {
        width: 100%;
        height: rem(56px);
        padding: rem(8px) rem(16px) rem(6px) rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        border-radius: rem(16px);
        border-width: 1px;
        border-style: solid;
        border-color: alpha(--color-darkCharcoal, 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            display: flex;
            flex-flow: column-reverse;
            justify-content: space-between;
            overflow: hidden;
        }
    }

    &--disabled &__button {
        opacity: 0.2;
        pointer-events: none;
    }

    &--errorState &__button {
        border-color: var(--color-semantic2);
    }

    &--validState &__button {
        border-color: var(--color-semantic1);
    }

    &--warningState &__button {
        border-color: var(--color-semantic3);
    }

    &__selectLabel {
        @extend %dmSansFontRegular;
        font-weight: 500;
        font-size: rem(13px);
        line-height: rem(13px);
        color: var(--color-darkGray);
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: lightness(--color-black, 20%);
        padding-top: rem(8px);
        padding-bottom: rem(2px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--hidden {
            display: none;
        }
    }

    &__label--hidden + &__selectLabel {
        font-size: rem(17px);
        line-height: rem(17px);
    }

    &__icon {
        font-size: rem(24px);
        transition: transform 0.1s ease-in-out;
    }

    &__content {
        position: absolute;
        border-radius: rem(16px);
        box-shadow: 0 8px 16px -4px alpha(--color-headerShadow, 0.15), 
                    0 0 1px 0 alpha(--color-headerShadow, 0.2);
        background-color: var(--color-darkCharcoal);
        overflow-y: hidden;
        visibility: hidden;
        max-height: 0;
        pointer-events: none;
        left: 0;
        width: 100%;
        z-index: setZ(dropdown);
        margin-top: rem(5px);
        transition: max-height 0.4s ease-out;

        @include adjustPerfectScrollbar();

        .ps__rail-y {
            margin-top: rem(6px);
            margin-bottom: rem(6px);
            margin-right: rem(8px);
            opacity: 1;
            width: rem(6px);
            background-color: var(--color-black);
        }

        .ps__thumb-y {
            width: rem(4px);
            background-color: var(--color-scrollbar);
            right: 1px;
        }

        &.ps--active-x > .ps__rail-x,
        &.ps--active-y > .ps__rail-y {
            background-color: var(--color-black);
        }

        &.ps:hover > .ps__rail-x,
        &.ps:hover > .ps__rail-y,
        &.ps--focus > .ps__rail-x,
        &.ps--focus > .ps__rail-y,
        &.ps--scrolling-x > .ps__rail-x,
        &.ps--scrolling-y > .ps__rail-y,
        &.ps .ps__rail-x:hover,
        &.ps .ps__rail-y:hover,
        &.ps .ps__rail-x:focus,
        &.ps .ps__rail-y:focus,
        &.ps .ps__rail-x.ps--clicking,
        &.ps .ps__rail-y.ps--clicking {
            opacity: 1;
        }

        &.ps .ps__rail-y:hover,
        &.ps .ps__rail-y.ps--clicking {
            width: rem(12px);
            background-color: var(--color-black);
        }

        .ps__rail-y:hover > .ps__thumb-y,
        .ps__rail-y:focus > .ps__thumb-y,
        .ps__rail-y.ps--clicking .ps__thumb-y {
            width: rem(10px);
            background-color: var(--color-scrollbar);
        }
    }

    &__item {
        position: relative;
        min-height: rem(56px);
        @extend %font-dataLabel1;
        color: var(--color-white);
        padding: rem(16px);
        padding-right: rem(56px);
        display: flex;
        align-items: center;
        cursor: pointer;

        &.selected {
            background-color: var(--color-black);
        }
    }
    &__item:focus,
    &__item:hover {
        background-color: alpha(--color-black, 0.6);

        &.selected {
            background-color: var(--color-black);
        }
    }

    &__check {
        position: absolute;
        right: rem(16px);
        display: none;
        font-size: rem(24px);
    }

    &__item.selected &__check {
        display: block;
    }

    &--open {
        #{$root}__content {
            max-height: rem(228px);
            pointer-events: all;
            visibility: visible;
        }
        #{$root}__button {
            border-color: var(--color-darkCharcoal);
        }
        #{$root}__icon {
            transform: rotateX(180deg);
        }
    }

    &__input {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        max-width: 100%;
    }

    &__inputLabel {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        max-width: 100%;
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
