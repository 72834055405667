@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt137-strillo-app {
    background-color: var(--color-secondary1);

    &__wrapper {
        @extend %container;
        display: flex;
        flex-flow: column;
        gap: rem(8px);
        padding-top: rem(24px);
        padding-bottom: rem(42px);

        @include respond-above(l) {
            flex-flow: row;
            gap: rem(24px);
            padding-top: rem(32px);
            padding-bottom: rem(30px);
        }
    }

    /* title and text */
    &__wrapper > div:not(:last-child){
        @include respond-above(l) {
            flex-grow: 1;
        }
    }

    /* links */
    &__wrapper > div:last-child {
        display: flex;
        gap: rem(16px);

        @include respond-above(l) {
            align-self: center;
        }
    }
    
    &__icon {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        font-size: rem(42px);
        color: var(--color-white);
        padding: rem(18px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-white);
        padding-bottom: rem(16px);

        @include respond-above(l) {
            line-height: rem(36px);
            padding-bottom: rem(8px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-white);
        padding-bottom: rem(16px);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
            padding-bottom: unset;
            margin-bottom: unset;
        }
    }
    
    &__cta img {
        max-width: rem(146px);
        max-height: rem(48px);
        object-fit: contain;
    }
}
