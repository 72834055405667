@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt207-big-article {
    width: rem(400px);
    min-height: rem(340px);

    @include respond-above(l) {
        width: 31.25%;
        min-height: rem(400px);
    }

    &__media {
        position: relative;
    }
    
    &__image.rt022-picture--cover {
        height: rem(192px);
        border-radius: rem(12px);

        @include respond-above(m) {
            height: rem(247px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        padding-bottom: rem(16px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            padding-bottom: rem(24px);
        }
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        line-height: rem(16px);
        padding-bottom: rem(8px);
        padding-top: rem(16px);

        @include respond-above(l) {
            padding-top: rem(24px);
            padding-bottom: rem(16px);
        }
    }

    &__mdd {
        position: absolute;
        top: rem(16px);
        left: rem(16px);
    }

}
