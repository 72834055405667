@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt251-select-pos-step2 {
    $root: &;
    @include modal(&, true);

    &__address {
        margin-top: rem(16px);
        margin-bottom: rem(8px);
    }

    &__bottomBar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: rem(16px);
    }

    &__wrapper {
        background-color: var(--color-neutral);

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            width: 4px;
            background-color: alpha(--color-black, 0.2);
            margin-bottom: rem(16px);
            margin-right: rem(8px);
            opacity: 0.6;
            border-radius: 2px;
            visibility: hidden;
            pointer-events: none;

            @include respond-above(l) {
                margin-right: rem(16px);
                visibility: visible;
                pointer-events: all;
            }

            & > .ps__thumb-y {
                width: 4px;
                right: 0;
                background-color: var(--color-black);
                border-radius: 2px;
            }
        }
    }

    &__searchEmpty {
        display: none;
    }

    &__searchEmptyImage {
        width: rem(104px);
        height: rem(104px);
        background-image: url('#{$resourcesFolder}/corporate/icons/lens.svg');
        background-repeat: no-repeat;
        background-size: rem(104px);
        margin: rem(28px) auto 0 auto;
    }

    &__searchEmptyText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        max-width: rem(273px);
        text-align: center;
        margin: rem(32px) auto 0 auto;
    }

    &__noRes {
        display: none;
    }

    &__noResImage {
        width: rem(136px);
        height: rem(147px);
        background-image: url('#{$resourcesFolder}/corporate/icons/ops.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: rem(20px);
    }

    &__noResTitle {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }

    &__noResText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(8px);
    }

    /* extra class added to the result cards */
    &__resItem {
        margin-bottom: rem(16px);
    }

    &__resHeading {
        display: flex;
        justify-content: space-between;
        padding-top: rem(40px);
        padding-bottom: rem(20px);
    }

    &__resHeadingLabel {
        font-weight: bold;
        color: var(--color-primaryGray);
    }

    &__resHeadingFound {
        color: var(--color-primary1);
    }

    &__showList {
        display: none;
    }
    &__showMap,
    &__showList {
        @include respond-above(l) {
            display: none;
        }
    }

    /* show/hide mobile map and select content */
    &__mapMob {
        height: 100%;
        display: none;
    }
    @include respond-below(l) {
        /* hide results list */
        &--showMapMob &__wrapper {
            display: none;
        }
        /* show map */
        &--showMapMob &__mapMob {
            display: block;
        }
        /* show button list */
        &--showMapMob &__showList {
            display: block;
        }
        /* hide button map */
        &--showMapMob &__showMap {
            display: none;
        }
    }

    &--noRes {

        #{$root}__searchEmpty {
            display: none;
        }
        #{$root}__noRes {
            display: block;
        }
        #{$root}__res {
            display: none;
        }
    }

    &--searchEmpty {

        #{$root}__searchEmpty {
            display: block;
        }
        #{$root}__noRes {
            display: none;
        }
        #{$root}__res {
            display: none;
        }
    }

    &--extended &__close {
        @include respond-above(l) {
            display: none;
        }
    }
}