@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;

.rt002-cta-link {

    overflow: visible;
    background-color: transparent;
    color: var(--color-semantic4);
    height: fit-content;
    padding: 0;
    text-decoration: underline;

    &:hover {
        color: alpha(--color-semantic4, 0.9);
    }
}
