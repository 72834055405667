@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/functions' as *;

:root,
html {
    @extend %dmSansFontRegular;
    font-size: $text-base-size;
    font-weight: normal;
    font-style: normal;
    letter-spacing: rem(0.2px);
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    background-color: var(--color-bg);
    color: var(--color-txt);
    overflow-y: scroll;

    &.block-scroll {
        overflow-y: hidden;
    }
}

.rs1-page {
    &--minSpaced {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        & > main {
            flex: 1 0 auto;
        }
    }

    &--spacedTop {
        padding-top: 40px;
    }

    &__feedbackContainer {
        position: fixed;
        width: 100%;
        z-index: 2;
        padding-top: 8px;
        display: flex;
        flex-flow: column;
        gap: rem(16px);
    }
}