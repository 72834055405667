@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;
@use '../../../../../../style/modules/commons' as *;

.rt002-cta {
    $root: &;
    $height-large: rem(80px);
    $height-medium: rem(56px);
    $height-small: rem(40px);

    @extend %dmSansFontBold;
    display: inline-block;
    width: fit-content;
    cursor: pointer;
    overflow: hidden;

    &:focus-visible {
        /* important needed to override the border style of the other classes */
        border: 1px solid var(--color-black) !important;
    }

    &__icon {
        vertical-align: bottom;
    }

    /* SIZES */
    &--small {
        padding: rem(12px) rem(24px);
        font-size: rem(14px);
        line-height: rem(16px);
        
        #{$root}__icon {
            font-size: rem(16px);
        }
    }

    &--medium {
        padding: rem(18px) rem(30px);
        font-size: rem(16px);
        line-height: rem(16px);

        #{$root}__icon {
            font-size: rem(16px);
        }
    }

    &--large {
        padding: rem(28px) rem(48px);
        font-size: rem(24px);
        line-height: rem(24px);
        
        #{$root}__icon {
            font-size: rem(24px);
        }
    }

    /* TYPES */
    &--left {
        border-radius: 12px;

        #{$root}__icon {
            margin-right: rem(8px);
        }
    }

    &--right {
        border-radius: 12px;

        #{$root}__icon {
            margin-left: rem(8px);
        }
    }

    &--none {
        border-radius: 12px;

        #{$root}__icon {
            display: none;
        }
    }

    &--circle {
        border-radius: 50%;

        #{$root}__label {
            display: none;
        }
    }
    &--circle#{$root}--small {
        padding-left: rem(12px);
        padding-right: rem(12px);
    }
    &--circle#{$root}--medium {
        padding-left: rem(20px);
        padding-right: rem(20px);
    }
    &--circle#{$root}--large {
        padding-left: rem(28px);
        padding-right: rem(28px);
    }

    &--square {
        border-radius: 12px;

        #{$root}__label {
            display: none;
        }
    }
    &--square#{$root}--small {
        padding-left: rem(12px);
        padding-right: rem(12px);
    }
    &--square#{$root}--medium {
        padding-left: rem(20px);
        padding-right: rem(20px);
    }
    &--square#{$root}--large {
        padding-left: rem(28px);
        padding-right: rem(28px);
    }
}