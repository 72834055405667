@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt023-insignia-card {
    $root: &;
    display: flex;
    justify-content: center;
    align-items: center;
    height: rem(64px);
    max-width: rem(163.2px);
    width: 100%;
    border-radius: rem(8px);
    background-color: var(--color-white);
    box-shadow: 0 1px 1px 0 alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    cursor: pointer;
    position: relative;

    @include respond-above(l) {
        height: rem(40px);
        max-width: rem(144px);
    }

    &__selected {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: rem(8px);
        box-shadow: 0 0 10px -2px #f38933;
    }

    &__input {
        opacity: 0;
        position: absolute;
    }
    &__input:checked ~ &__selected {
        display: block;
    }

    &__image {
        height: rem(52px);
        width: rem(128px);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include respond-above(l) {
            height: rem(28px);
            width: rem(108.2px);
        }
    }

    /* insignia */
    &[data-insignia='MARGHERITA_CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/margherita-conad.png');
    }
    &[data-insignia='CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad.svg');
    }
    &[data-insignia='CONAD_CITY'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-city.svg');
    }
    &[data-insignia='CONAD_SUPERSTORE'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-superstore.svg');
    }
    &[data-insignia='IPER_CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/iper-conad.png');
    }
    &[data-insignia='CONAD_IPERMERCATO'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-ipermercato.svg');
    }
    &[data-insignia='SAPORI_E_DINTORNI_CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/sapori-e-dintorni.png');
    }
    &[data-insignia='SAPORI_E_IDEE_STORE'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/sapori-e-idee.png');
    }
    &[data-insignia='SPAZIO_CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/spazio-conad.png');
    }
    &[data-insignia='TUDAY_CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/tuday-conad.png');
    }
    &[data-insignia='MARKET_CONAD'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad.svg');
    }
    &[data-insignia='SPESA_FACILE'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/conad-spesa-facile.png');
    }
    /* insignia (specialized) */
    &[data-insignia='PARAFARMACIA'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/pharmacy-pos.png');
    }
    &[data-insignia='OTTICO'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/eye.png');
    }
    &[data-insignia='PETSTORE'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/pet-store.png');
    }
    &[data-insignia='DISTRIBUTORE_CARBURANTI'] > &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/fuel.png');
    }
}
