@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner-hmf {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;

    &__name {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-banner-text);
        display: flex;
        justify-content: space-between;

        & > span:last-child {
            cursor: pointer;
        }
    }

    &__address {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        padding: rem(10px) 0;
    }

    &__link {
        flex-grow: 1;
        font-size: rem(14px);
        line-height: rem(18px);
        color: var(--color-banner-text);
        text-decoration: underline;
        white-space: nowrap;
    }
    &__link:hover {
        color: var(--color-banner-text);
    }

    &__opening {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-banner-text);
        padding-top: rem(16px);
        border-top: 1px solid var(--color-white);
        margin-top: rem(16px);
    }

    &__linkModifyStore, &__linkModifyStore:hover {
        text-decoration: none !important;
        color: var(--color-black);
    }
}
