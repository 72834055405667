@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='parafarmacia'],
body.theme-parafarmacia {
    @include defineColorsHexMap(map.merge($default-theme, $parafarmacia-theme));
}
