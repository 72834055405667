@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt153-banner-image {
    margin: 0 rem(16px);
    @extend %container;

    @include respond-above(m) {
        margin: 0 auto;
    } 
    
    display: flex;
    border-radius: rem(20px);
    position: relative;

    &__topText {
        margin-bottom: rem(6px);
        h4 {
            @extend %robotoFontMedium;
            font-size: rem(22px);
            line-height: rem(26px);

            @include respond-above(m) {
                font-size: rem(30px);
                line-height: rem(38px);
            }
        }

        p {
            margin-top: rem(8px);
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(15px);
            width: 80%;

            @include respond-above(m) {
                font-size: rem(14px);
                line-height: rem(22px);
                width: auto;
            }
        }
    }

    &__bottomText {
        p {
            @extend %dmSansFontBold;
            font-size: rem(12px);
            line-height: rem(20px);

            @include respond-above(m) {
                font-size: rem(14px);
                line-height: rem(20px);
            }

            a {
                @extend %dmSansFontMedium;
                font-size: rem(12px);
                line-height: rem(18px);
                color: var(--color-semantic4);
                text-decoration: underline;
                cursor: pointer;

                @include respond-above(m) {
                    font-size: rem(14px);
                    line-height: rem(18px);
                }
            }
        }
    }
}
