@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt111-listing {
    @extend %container;
    width: 100%;

    &__cards {
        display: flex;
        flex-wrap: wrap;
    }

    &__card.rt202-card-elev-recipes {
        width: 100%;
    }

    &__card.rt200-card-elev-editorial {
        width: 100%;
    }

    &__card-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: rem(20px);

        @include respond-below(m) {
            width: 100%;
            padding: 0;
        }

        @include respond-above(m) {
            justify-content: center;
            width: 33.3333%;
            margin-bottom: rem(32px);

            &:nth-child(3n + 1) {
                padding-right: rem(8px);
            }

            &:nth-child(3n + 2) {
                padding-right: rem(8px);
                padding-left: rem(8px);
            }

            &:nth-child(3n + 3) {
                padding-left: rem(8px);
            }
        }
    }
    /* default texts style */

    &__title {
        @extend %robotoFont;
        font-size: rem(32px);
        line-height: rem(40px);
        font-weight: 500;
        margin-bottom: rem(8px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-bottom: rem(16px);
    }

    &__breadcrumb {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(14px);
        font-weight: 500;
        line-height: rem(14px);
        margin-bottom: rem(16px);
    }

    &__filters {
        margin-bottom: rem(24px);
    }

    &__paginator {
        width: 100%;
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(24px);
    }

    &__top {
        display: flex;
        flex-direction: column;
        margin-bottom: rem(40px);

        @include respond-above(l) {
            flex-direction: row;
            margin-bottom: rem(85px);
        }

        &--noImage {
            margin-bottom: rem(24px);
            @include respond-above(l) {
                margin-bottom: rem(40px);
            }
        }
    }

    &__image.rt022-picture--cover {
        height: rem(189px);
        border-radius: rem(12px);

        @include respond-above(m) {
            height: rem(300px);
        }
    }

    &__logo {
        display: flex;
        align-items: center;
        position: absolute;
        right: rem(24px);
        width: fit-content;
        min-height: rem(102px);
        bottom: 0;
        background-color: var(--color-white);
        border-radius: rem(12px);
        border: solid 1px lightness(--color-black, 90%);
    }

    &__left {
        width: 100%;
        flex: 0 0 auto;

        @include respond-above(l) {
            width: 33.9%;
            margin-right: rem(80px);
        }

        &--noImage {
            @include respond-above(l) {
                width: 76%;
                margin: 0;
            }
        }
    }

    &__right {
        position: relative;
        flex: 1 1 auto;
        padding-bottom: rem(51px);

        @include respond-above(l) {
            height: 100%;
            padding-bottom: rem(67px);
        }
    }

    &__logoImg {
        max-height: calc(100%);
        object-fit: contain;
        padding: 0 rem(27px);
    }

    &__ctaLink {
        @include respond-below(l) {
            margin-bottom: rem(24px);
        }
    }

    &[data-variant='small'] &__card-container {
        @include respond-above(m) {
            justify-content: center;
            width: 25%;
            margin-bottom: rem(32px);

            &:nth-child(2n + 1) {
                padding-right: rem(8px);
                padding-left: 0;
            }

            &:nth-child(2n + 2) {
                padding-left: rem(8px);
                padding-right: rem(8px);
            }

            &:nth-child(2n + 3) {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &[data-variant='large'] &__card-container {

        @include respond-above(m) {
            justify-content: center;
            width: 100%;
            margin-bottom: rem(32px);
            padding: 0;
        }
    }

}
