@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use 'sass:math';

.rt008-text-field {
    $root: &;
    position: relative;
    width: 100%;
    display: block;

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        display: block;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out, visibility 0s;
        border-radius: rem(16px);
        border-width: 1px;
        border-style: solid;
        border-color: alpha(--color-black, 0.3);
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
        line-height: rem(39px);
    }

    &__input:focus ~ &__label,
    &__input:not(:placeholder-shown) + &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: var(--color-darkGray);
        background-color: transparent;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out, visibility 0s;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: var(--color-darkGray);
    }

    &__info {
        display: none;
        width: rem(16px);
        height: rem(16px);
        background-image: url('#{$resourcesFolder}/corporate/icons/info.svg');
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: calc(50% - 8px);
        right: rem(16px);
        cursor: pointer;
    }
    &__info--show {
        display: block;
    }
    &__info--show ~ &__input {
        padding-right: rem(32px);
    }
    &__info--show ~ &__input:not(:focus) ~ &__label {
        padding-right: rem(16px);
    }

    /* fix autocomplete hover on webkit browsers */
    input:-webkit-autofill:hover ~ &__label {
        color: lightness(--color-black, 20%);
    }

    &--errorState &__input {
        border-color: var(--color-semantic2);
    }

    &--validState#{$root}--validEnabled &__input {
        border-color: var(--color-semantic1);
    }

    &--warningState#{$root}--validEnabled &__input {
        border-color: var(--color-semantic3);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-20px); // 1rem + 4px
        display: none;
        user-select: none;
    }

    &__iconLocked {
        display: none;
        $size: rem(16px);
        font-size: $size;
        position: absolute;
        right: rem(16px);
        top: calc(50% - #{math.div($size, 2)});
    }

    &__icon {
        display: block;
        position: absolute;
        left: rem(3px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
        color: var(--color-darkGray);
        border-radius: 14px;
        width: rem(50px);
        height: rem(50px);
        text-align: center;

        & ~ #{$root}__input {
            padding-left: rem(60px);
        }

        & ~ #{$root}__label {
            margin-left: rem(45px);
        }
    }

    &--disabled {
        $disabledColor: alpha(--color-darkCharcoal, 0.1);

        & > #{$root}__input {
            border-color: $disabledColor;
            color: $disabledColor;
        }

        &#{$root} > #{$root}__label {
            color: $disabledColor;
        }
    }

    &--locked {
        & > #{$root}__input {
            border-color: alpha(--color-darkCharcoal, 0.05);
        }

        & > #{$root}__iconLocked {
            display: inline;
        }
    }

    &--errorState &__error {
        display: inline;
    }

    &--light {
        #{$root}__input {
            border-width: 0;
            border-bottom-width: 1px;
            border-radius: 0;
        }

        #{$root}__icon {
            left: 0;
            width: rem(24px);
        }

        #{$root}__icon ~ #{$root}__input {
            padding-left: rem(40px);
        }

        #{$root}__icon ~ #{$root}__label {
            margin-left: rem(24px);
        }
    }
}
