@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt156-excel-to-map-search-stores {
    $regions-list-height: 430px;
    @extend %container;

    &__wrapper {
        @include respond-above(l) {
            display: flex;
            align-items: flex-start;
            gap: 95px;
        }
    }

    &__map {
        flex: 1 1 0;
        max-width: rem(424px);

        @include respond-above(l) {
            margin-top: rem(50px);
        }

        path.hovered,
        path.selected {
            fill: var(--color-primary1);
        }
    }

    &__regions {
        flex: 1 1 0;

        & > div:first-child {
            
            @include respond-above(l) {
                min-width: 383px;
                width: fit-content;
                margin-left: auto;
            }
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(32px);
        line-height: rem(40px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(28px);
    }
    &--separator &__title {
        @include addSeparator();
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-bottom: rem(28px);
    }

    & > &__title,
    & > &__text {
        @include respond-above(l) {
            display: none;
        }
    }
    &__regions &__title,
    &__regions &__text {
        @include respond-below(l) {
            display: none;
        }
    }

    &__btnRegions {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(40px);
        margin-bottom: rem(24px);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-above(l) {
            display: none;
        }

        & > span:last-child {
            font-size: rem(24px);
            transition: transform .2s ease-in-out;
        }
    }

    &__listWrapper {
        overflow: hidden;
        max-height: 0;
        transition: max-height .2s ease-in;

        @include respond-above(l) {
            max-height: $regions-list-height;
        }
    }

    &__list {
        display: flex;
        flex-flow: column wrap;
        gap: rem(16px);

        li {
            width: calc(50% - 8px);
        }
    }

    @include respond-below(l) {
        &__btnRegions.open > span:first-child {
            transform: rotateX(180deg);
        }
        &__btnRegions.open ~ &__listWrapper {
            max-height: $regions-list-height;
        }
    }

    &__region {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        cursor: pointer;

        & ~ hr {
            background-color: var(--color-secondary2);
            width: rem(32px);
            height: rem(2px);
            border: none;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            margin-top: rem(2px);
        }
    }
    &__region:hover,
    &__region--mapHovered,
    &__region.selected {
        color: var(--color-primary1);

        & ~ hr {
            opacity: 1;
        }
    }

    &__searchSection {
        margin-top: rem(24px);
        letter-spacing: normal;
    }

    &__noResults {
        display: flex;
        flex-flow: column;
        gap: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            gap: rem(62px);
            margin-top: rem(50px);
        }
    }

    &__noResultsImage {
        width: rem(120px);
        height: rem(124px);
        background-image: url('#{$resourcesFolder}/corporate/icons/lens-alert.svg');
        background-repeat: no-repeat;
        background-size: rem(124px);
    }

    &__noResults > div:last-child {
        margin-top: rem(13px);
    }

    &__noResultsTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);
    }

    &__noResultsText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }

    /* svg map regions */
    .regionMap {
        &:hover {
            fill: var(--color-primary1);
            cursor: pointer;
        }
    }
}
