@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner {
    $root: &;
    --color-banner-text: var(--color-darkCharcoal);
    background-color: #f58f01;
    position: relative;


    &__container {
        @extend %container;
        display: flex;
        flex-flow: column;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        min-height: rem(233px);

        @include respond-above(l) {
            flex-flow: row;
        }

        &--initialized {
            opacity: 1;
        }
    }

    &__main {
        padding: rem(24px) rem(16px);
        margin-left: rem(-16px);
        width: calc(100% + #{rem(32px)});
        flex: 0 0 auto;

        @include respond-above(l) {
            width: poc(356px, 1024px);
            padding: rem(32px) 0;
            margin-left: 0;
        }

        & > div {
            width: rem(278px);

            @include respond-above(m) {
                width: rem(500px);
            }

            @include respond-above(l) {
                width: rem(272px);
            }
        }
    }

    @include respond-above(l) {
        &[data-flyer-variant='3'] &__main {
            padding: rem(40px) 0;
        }
    }

    &__mainDefault {
        @include respond-above(l) {
            padding-top: rem(19px);
            padding-bottom: rem(19px);
        }
    }

    &__mainFilled {
        height: 100%;
        flex-flow: column;
    }

    &__findStoreTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(28px);
            line-height: rem(30px);
            margin-bottom: rem(32px);
        }
    }

    &__findStoreText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-banner-text);

        a {
            @extend %dmSansFontBold;
            text-decoration: underline;
        }
        a:hover {
            color: var(--color-banner-text);
        }
    }

    &__side {
        background-color: #f69a02;
        padding-top: rem(32px);
        padding-left: rem(16px);
        padding-right: rem(16px);
        padding-bottom: rem(48px);
        z-index: 1;

        margin-left: rem(-16px);
        width: calc(100% + #{rem(32px)});
        flex: 0 0 auto;

        @include respond-above(l) {
            width: 65.5%;
            padding-top: rem(26px);
            padding-left: rem(40px);
            padding-bottom: rem(32px);
            padding-right: 0;
            margin-left: 0;
        }

        &--hiddenMob {
            @include respond-below(l) {
                display: none;
            }
        }
    }

    &__side-bg {
        display: none;
        position: absolute;
        background-color: #f69a02;
        width: 50%;
        left: 50%;
        height: 100%;
        top: 0;
        z-index: 0;

        @include respond-above(l) {
            display: block;
        }
    }

    @include respond-below(l) {
        &[data-flyer-variant='1'] &__side,
        &[data-flyer-variant='2'] &__side,
        &[data-flyer-variant='3'] &__side {
            padding-bottom: unset;
        }
        &[data-flyer-variant='1'] &__side {
            padding-top: unset;
        }
        &[data-flyer-variant='3'] &__side {
            padding-top: rem(6px);
        }
    }
    @include respond-above(l) {
        &[data-flyer-variant='1'] &__side,
        &[data-flyer-variant='3'] &__side {
            padding-top: rem(40px);
        }
    }

    &__sideDefault {
        @include respond-above(l) {
            padding-top: rem(29px);
            padding-bottom: rem(23px);
        }
    }

    &__findStoreSideText {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        margin-bottom: rem(32px);
        max-width: rem(470px);
    }

    &__address {
        background-color: var(--color-white);
    }

    &--filled {
        #{$root}__mainDefault,
        #{$root}__sideDefault {
            display: none;
        }
        #{$root}__mainFilled,
        #{$root}__sideFilled {
            display: block;
        }
    }
}
