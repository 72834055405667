@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.rt010-search-field {
    $root: &;
    $search-btn-size: rem(50px);
    $cancel-size: rem(16px);
    position: relative;
    width: 100%;
    display: block;
    border-radius: rem(16px);

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        display: block;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out;
        border-radius: rem(16px);
        border-width: 1px;
        border-style: solid;
        border-color: alpha(--color-black, 0.3);

        /* button left (default) */
        padding-left: rem(60px);
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
        line-height: rem(39px);
    }

    &__input:focus ~ &__label,
    &__input:not(:placeholder-shown) ~ &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: var(--color-primaryGray);
        background-color: transparent;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: var(--color-primaryGray);
    }

    &__cancel {
        width: $cancel-size;
        height: $cancel-size;
        background-image: url('#{$resourcesFolder}/corporate/icons/close-circle.svg');
        background-repeat: no-repeat;
        background-size: $cancel-size;
        position: absolute;
        top: calc(50% - #{math.div($cancel-size, 2)});
        right: rem(16px);
        cursor: pointer;
        visibility: visible;
        pointer-events: all;
        transition: opacity .2s ease-in;

        &--hidden {
            visibility: hidden;
            pointer-events: none;
            cursor: default;
        }
    }
    &__cancel:focus-visible {
        @include outlineFocusVisible(1px);
    }

    &__searchBtn {
        
        position: absolute;
        left: rem(3px);
        top: 50%;
        transform: translateY(-50%);
        font-size: rem(24px);
        color: var(--color-darkCharcoal);
        border-radius: 14px;
        width: $search-btn-size;
        height: $search-btn-size;
        text-align: center;
        transition: background-color 0.2s;

        & > .on {
            display: block;
        }
        & > .off {
            display: none;
        }

        & ~ #{$root}__label {
            margin-left: rem(45px);
        }
    }

    /* button right */
    &--iconRight > &__input {
        padding-left: rem(16px);
        padding-right: calc(2px + #{$search-btn-size} + #{rem(16px)});
    }
    &--iconRight > &__searchBtn {
        left: unset;
        right: rem(3px);
        color: var(--color-white);
        background-color: var(--color-primary1);

        & ~ #{$root}__label {
            margin-left: unset;
            margin-right: rem(45px);
        }

        & ~ #{$root}__cancel {
            right: calc(2px + #{$search-btn-size} + #{rem(16px)});
        }
    }
    &--iconRight > &__searchBtn:hover {
        background-color: alpha(--color-primary1, 0.9);
    }

    &--errorState &__input {
        border-color: var(--color-semantic2);
    }

    &--validState#{$root}--validEnabled &__input {
        border-color: var(--color-semantic1);
    }

    &--warningState#{$root}--validEnabled &__input {
        border-color: var(--color-semantic3);
    }

    /* fix autocomplete hover on webkit browsers */
    input:-webkit-autofill:hover ~ &__label {
        color: alpha(--color-black, 0.8);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }

    &__detectLocationContainer {
        position: absolute;
        top: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        background-color: #333;
        border-radius: 16px;
        margin-top: 4px;
        box-shadow: 0 2px 6px rgb(0 0 0 / 30%);
        display: none;

        &--active {
            display: block;
        }
    }

    &__detectLocationItem {
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        padding-left: rem(18px);
        padding-right: rem(16px);
        background-color: var(--color-darkCharcoal);
        cursor: pointer;
    }
    &__detectLocationItem:hover {
        background-color: #191919;
    }

    &__detectLocationLink {
        display: flex;
        align-items: center;
    }

    &__detectLocationIcon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('#{$resourcesFolder}/corporate/icons/target.svg');
        background-repeat: no-repeat;
        background-size: 20px;
        margin-right: rem(10px);
    }

    &__detectLocationText {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(24px);
        color: var(--color-white);
    }

    &--light {
        #{$root}__input {
            border-width: 0;
            border-bottom-width: 1px;
            border-radius: 0;
        }

        #{$root}__icon {
            left: 0;
            width: rem(24px);
        }

        #{$root}__icon ~ #{$root}__input {
            padding-left: rem(40px);
        }

        #{$root}__icon ~ #{$root}__label {
            margin-left: rem(24px);
        }
    }
}
