@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt149-search-stores {
    @extend %container;

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(36px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);

        &--separator {
            @include addSeparator();
        }
    }

    &__searchText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        margin-bottom: rem(24px);
    }

    &__form {
        display: flex;
        flex-flow: column;
        gap: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            align-items: center;
        }
    }

    &__search {
        @include respond-below(l) {
            width: 100%;
            text-align: center;
        }
    }

    &__numResults {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        margin: rem(24px) 0 rem(16px);
        display: none;

        &--show {
            display: block;
        }
    }

    &__results {
        display: flex;
        flex-flow: column;
        gap: rem(16px);

        @include respond-above(l) {
            flex-flow: row wrap;
            gap: rem(24px) rem(32px);
        }
    }

    &__loadMore {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        display: block;
        margin: rem(40px) auto 0;

        &--hidden {
            display: none;
        }
    }

    /* store card */
    &__store {
        min-height: 193px;
        padding: rem(16px);
        border-radius: 12px;
        box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        border: 1px solid lightness(--color-neutral, 90%);

        @include respond-above(l) {
            width: calc(50% - 16px);
        }

        &--hidden {
            display: none;
        }
    }

    &__name {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        padding-right: rem(24px);
        margin-bottom: rem(4px);
        position: relative;

        & > span:last-child {
            font-size: rem(24px);
            position: absolute;
            right: 0;
        }
    }

    &__address {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);

        @include respond-above(l) {
            max-width: rem(500px);
        }
    }

    &__link {
        display: block;
        margin-left: auto;
        margin-top: rem(55px);
        white-space: nowrap;
    }

    &__noResults {
        display: flex;
        flex-flow: column;
        gap: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            gap: rem(62px);
            margin-top: rem(50px);
        }

        &--hidden {
            display: none;
        }
    }

    &__noResultsImage {
        width: rem(120px);
        height: rem(124px);
        background-image: url('#{$resourcesFolder}/corporate/icons/lens-alert.svg');
        background-repeat: no-repeat;
        background-size: rem(124px);
    }

    &__noResults > div:last-child {
        margin-top: rem(13px);
    }

    &__noResultsTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);
    }

    &__noResultsText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }
}
