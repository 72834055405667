@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt117-breadcrumb {
    position: relative;

    @include respond-below(l) {
        white-space: nowrap;
        overflow-y: hidden;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;           /* Safari and Chrome */
        }
        position: relative;
        
    }

    &__item {
        @extend %dmSansFontMedium;
        color: var(--color-grayscale16);
        font-size: rem(12px);
        line-height: 1em;
        margin-left: rem(14px);

        &:first-child {
            margin-left: 0;
        }

        &--disabled {
            color: var(--color-darkCharcoal);
        }

        &::after {
            content: '/';
            margin-left: rem(13px);
        }

        &:last-child::after {
            display: none;
        }

        &:last-child {
            margin-right: rem(13px);
        }
    }

    &__container {
        overflow: hidden;

        /* hide scrollbar */
        &.ps > .ps__rail-x {
            display: none;
        }
    }

    &__track {
        @extend %container;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;           /* Safari and Chrome */
        }
    }
    &--noPad &__track {
        padding: 0;
    }

    &__fade {
        width: rem(32px);
        height: rem(20px);
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to right, #{alpha(--color-white, 0.2)}, #{alpha(--color-white, 0.9)});
    }
    &--noFade &__fade {
        display: none;
    }
}
