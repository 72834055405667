@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;

.rt002-cta-social {
    $root: &;

    max-width: rem(564px);
    width: 100%;
    background-color: var(--color-white);
    color: var(--color-darkCharcoal);
    border: solid 2px;
    border-color: var(--color-darkCharcoal);
    border-radius: 12px;

    &:focus {
        border: solid 2px;
        border-color: var(--color-darkCharcoal);
    }

    &__icon {
        display: inline-block;
        width: rem(24px);
        height: rem(24px);
        background-size: rem(24px);
        background-repeat: no-repeat;

        &::before {
            display: none;
        }
    }

    &__label {
        display: inline-block;
        text-align: center;
        width: calc(100% - #{rem(28px)});
        line-height: rem(24px);
    }

    &--google #{$root}__icon {
        background-image: url('#{$resourcesFolder}/corporate/icons/google.jpg');
    }
    &--facebook #{$root}__icon {
        background-image: url('#{$resourcesFolder}/corporate/icons/facebook.jpg');
    }
    &--apple #{$root}__icon {
        background-image: url('#{$resourcesFolder}/corporate/icons/apple.jpg');
    }
}
.rt002-cta-social:hover {
    color: lightness(--color-black, 10%);
    border-color: var(--color-grayscale14);
}
