@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.rl1-layout {

    &__meta {
        display: none;
    }

    &__item {
        &--spaced-top-none {
            padding-top: 0;
        }

        &--spaced-top {
            padding-top: rem(30px);
            @include respond-above(l) {
                padding-top: rem(40px);
            }
        }

        &--spaced-bottom {
            padding-bottom: rem(30px);
            @include respond-above(l) {
                padding-bottom: rem(40px);
            }
        }

        &--spaced-bottom-none {
            padding-bottom: 0;
        }

        &--spaced-top-2x {
            padding-top: rem(60px);
            @include respond-above(l) {
                padding-top: rem(80px);
            }
        }

        &--spaced-bottom-2x {
            padding-bottom: rem(60px);
            @include respond-above(l) {
                padding-bottom: rem(80px);
            }
        }

        &--spaced-top-3x {
            padding-top: rem(90px);
            @include respond-above(l) {
                padding-top: rem(120px);
            }
        }

        &--spaced-bottom-3x {
            padding-bottom: rem(90px);
            @include respond-above(l) {
                padding-bottom: rem(120px);
            }
        }

        &--hidden {
            display: none;
        }
    }
}
