@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/functions' as *;

.rt012-switch {
    $padding-lr: rem(24px);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: rem(48px);
    background-color: var(--color-primary1);
    border-radius: rem(24px);
    padding: 0 $padding-lr;
    height: rem(40px);

    &__left,
    &__right {
        position: relative;
        cursor: pointer;

        &::before {
            position: absolute;
            top: rem(-13px);
            content: '';    
            box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
            background-color: var(--color-white);
            border-radius: rem(24px);
            width: calc(100% + 2 * $padding-lr);
            height: rem(48px);
            transition: right .3s ease-in-out, 
                        left .3s ease-in-out;
        }
    }
    &__left::before {
        right: rem(-24px);
    }
    &__right::before {
        left: rem(-24px);
    }
    [data-on="left"] > &__right,
    [data-on="right"] > &__left {
        color: var(--color-white);

        &::before {
            pointer-events: none;
            visibility: hidden;
        }
    }

    [data-on="left"] > &__right {
        color: var(--color-lightOrange);
    }

    [data-on="left"] > &__right::before {
        left: -100%;
    }
    [data-on="right"] > &__left::before {
        right: -100%;
    }

    &__icon {
        position: relative;
        bottom: -2px;
        font-size: rem(16px);
        margin-right: rem(8px);
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        position: relative;
    }

    &__left:focus-visible, &__right:focus-visible {
        @extend %button-focused;
    }

}
