@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt213-card-product-flyer {
    display: flex;
    flex-direction: column;
    grid-column: span 1;
    justify-content: space-between;
    background-color: var(--color-white);
    border-radius: rem(12px);
    padding: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    &--carousel {
        min-width: rem(309px);

        @include respond-above(l) {
            max-width: rem(244px);
            min-width: unset;
        }
    }

    &--evidence {
        &:before {
            content: '';
            position: absolute;
            top: rem(-0.5px);
            right: 0;
            bottom: rem(-0.5px);
            left: 0;
            z-index: -1;
            margin: rem(-1.5px) rem(-2px);
            border-radius: inherit;
            background: linear-gradient(to bottom right, var(--color-primary2), var(--color-primary1), var(--color-redPromotion));

            @include respond-above(l) {
                top: rem(-1px);
                bottom: rem(-1px);
                margin: rem(-1px) rem(-2px);
            }
        }
    }

//     &[data-evidence] {
//         background-color: var(--color-lightYellow);
//     }

    @include respond-between(m, l) {
        //width: 49%;
    }

    @include respond-above(m) {
        //width: 23.7%;
        padding: rem(16px);
    }

    @include respond-above(xl) {
        //width: 24.1%;
    }

    &--hidden {
        display: none;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: flex-end;
        margin-top: rem(8px);
        margin-bottom: rem(8px);

        &--carousel {
            @include respond-below(l) {
                flex-direction: row;
                gap: rem(16px);
                align-items: center;
            }
        }
    }

    &__image {
        width: rem(80px);
        height: rem(80px);
        min-width: rem(80px);
        min-height: rem(80px);
        align-self: center;

        @include respond-above(l) {
            width: rem(104px);
            height: rem(104px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        height: rem(60px);
        margin-top: rem(23px);
        color: var(--color-darkCharcoal);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &--carousel &__title {
        @include respond-below(l) {
            flex: 1 1 auto;
        }
    }

    &__favorite {
        display: none;
        flex-shrink: 0;
        color: var(--color-primary1);
        cursor: pointer;
        padding-top: rem(4px);
        padding-bottom: rem(4px);

        & > span {
            font-size: rem(16px) !important;
        }

        @include respond-above(l) {
            & > span {
                font-size: rem(24px) !important;
            }
        }

        &--carousel {
            & > span {
                font-size: rem(24px) !important;
            }
        }

        &--show {
            display: block;
        }
    }

    &__favorite.rt002-cta--circle.rt002-cta--small {
        padding-right: 0;
    }

    &__favorite:hover {
        color: alpha(--color-primary1, 0.9);
    }
    &--favorite &__favorite:hover {
        color: var(--color-primary1);
        cursor: default;
    }

    &__evidence {
        background-image: linear-gradient(
            to bottom right,
            var(--color-primary2),
            var(--color-primary1),
            var(--color-redPromotion)
        );
        color: var(--color-neutral);
    }

    &__promotion {
        background-color: var(--color-redPromotion);
        color: var(--color-neutral);
    }

    &__points {
        background-color: var(--color-lightYellow);
        color: var(--color-primary1);
    }

    &__evidence,
    &__promotion,
    &__points {
        border-radius: rem(8px);
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        padding: rem(8px);
        width: fit-content;
        height: fit-content;

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(16px);
        }
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    &__topLeft {
        display: flex;
        flex-direction: column;
        gap: rem(8px);
        width: rem(119px);

        @include respond-above(l) {
            width: rem(157px);
        }
    }

    &__carta {
        display: flex;
        gap: rem(8px);
        flex-wrap: wrap;
    }

    &__cartainsieme,
    &__cartainsiemepiu,
    &__bassiefissi,
    &__carteinsieme {
        width: rem(48px);
        height: rem(32px);
        background-size: rem(32px);
        background-repeat: no-repeat;
    }

    &__cartainsieme {
        background-image: url('#{$resourcesFolder}/corporate/icons/cartainsieme.png');
    }

    &__cartainsiemepiu {
        background-image: url('#{$resourcesFolder}/corporate/icons/cartainsiemepiu.png');
    }

    &__carteinsieme {
        background-image: url('#{$resourcesFolder}/corporate/icons/carteinsieme.png');
        background-size: rem(28px);
    }

    &__bassiefissi {
        background-image: url('#{$resourcesFolder}/corporate/icons/bassiefissi.jpg');
        background-size: rem(23px);
    }

    &__validity {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-redPromotion);
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: rem(22px);

        height: rem(24px);
        @include respond-above(l) {
            height: rem(38px);
        }
    }

    &__bottomLeft {
        display: flex;
        flex-direction: column;
    }

    &__confText {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(12px);
    }

    &__priceText {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: alpha(var(--color-black), 0.6);
    }

    &__finalPrice {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);

        @include respond-above(l) {
            font-size: rem(30px);
            line-height: rem(38px);
        }
    }

    &[data-original-price] &__finalPrice {
        color: var(--color-redPromotion);
    }

    &__originalPrice {
        position: absolute;
        right: 0;
        top: rem(-20px);
        text-decoration: line-through;
        text-decoration-thickness: 0.2%;
        text-decoration-color: alpha(var(--color-black), 0.6);
        align-self: flex-end;
        @extend %robotoFontMedium;
        font-size: rem(12px);
        line-height: rem(20px);

        @include respond-above(l) {
            font-size: rem(16px);
        }
    }
}
