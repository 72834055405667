@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt210-card-spec-stores {
    $root: &;
    border-radius: rem(12px);
    background-color: var(--color-white);
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    width: rem(254px);
    min-height: rem(128px);
    padding: rem(16px) 0;

    @include respond-above(m) {
        width: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        width: rem(138px);
        height: rem(48px);
        object-fit: contain;
    }

    &__text {
        @extend %dmSansFontRegular;
        text-align: center;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
    }

    &__conceptHours {
        padding-top: rem(20px);
    }
}
