//general
$text-base-size: 16px;

//breakpoints
$breakpoints: (
    sm: 344px,
    m: 768px,
    l: 1024px,
    xl: 1440px,
);

//container
$container-wmax: 1344px;
$container-wpad: 16px;
$container-wpad-desk: 40px;

//topbar
$topbarHeight: 48px;

// z-index
$z-layers: (
    bottomless-pit: -9999,
    negative: -1,
    default: 1,
    backToTop: 3999,
    dropdown: 3000,
    overlay: 4000,
    header: 5000,
    modal: 5002,
    popup: 5005,
    top: 6000,
) !default;

// resources folder
$resourcesFolder: '~/src/main/webpack/resources';

// icon library
$icomoon-font-family: 'conad-corporate-icons' !default;

// fonts folder
$fontsFolder: '#{$resourcesFolder}/corporate/fonts';

// USED ICON VARIABLES
$ec-icon-close: '\e92f';
$ec-icon-chevron-up: '\e930';
$ec-icon-chevron-right: '\e931';
$ec-icon-chevron-left: '\e932';
$ec-icon-chevron-down: '\e933';
$ec-icon-like-on: '\e922';
$ec-icon-like-off: '\e923';
$ec-icon-search: '\e903';
