@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt063-contact-form {
    @extend %container;

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(32px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }

        @include addSeparator();
    }

    &__personalData {
        margin-bottom: rem(16px);
    }

    &__personalDataTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }
    
    &__personalDataFidelityCodeText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(14px);
        color: lightness(--color-black, 60%);
        margin-top: rem(4px);
    }

    &__fields {
        display: flex;
        gap: rem(24px) rem(16px);
        flex-flow: column;
        padding-bottom: rem(16px);

        @include respond-above(l) {
            flex-flow: row wrap;
        }
    }

    &__field {
        height: fit-content;

        @include respond-above(l) {
            width: calc(50% - 8px);
        }
    }

    &__storeData > div:first-child {
        display: flex;
        align-items: center;
        gap: rem(8px);
        color: lightness(--color-black, 20%);

        & > span:first-child {
            font-size: rem(24px);
        }
    }

    &__storeDataTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__storeDataText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 60%);
        margin-top: rem(16px);
    }

    &__dynamicSectionTitle {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);
    }

    &__ccRequestType {
        margin-bottom: rem(24px);
    }

    &__formSectionCCRT,
    &__formSectionCCRTT {
        display: flex;
        flex-flow: column;
        gap: rem(24px);
    }
    &__formSectionCCRT &__fields,
    &__formSectionCCRTT &__fields {
        padding-bottom: 0;
    }

    &__prizeHolderText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(16px);
    }

    &__storeDataSelectStore {
        margin-top: rem(32px);
        margin-bottom: rem(32px);
    }

    /* card radios */
    &__cardRadios {
        margin-bottom: rem(20px);

        .rt004-radio__radios {
            flex-flow: column;
            row-gap: rem(24px);
        }

        .rt004-radio__radio {
            position: relative;
        }

        .rt004-radio__radio::after {
            content: ' ';
            display: block;
            width: 76px;
            height: 48px;
            background-size: contain;
            background-repeat: no-repeat;
            margin-top: rem(16px);
        }

        .rt004-radio__radio:nth-child(1)::before {
            content: ' ';
            display: block;
            width: 76px;
            height: 48px;
            background-size: contain;
            background-repeat: no-repeat;
            background-image: url('#{$resourcesFolder}/corporate/icons/cc-card-conad-pay.png');
            position: absolute;
            bottom: 0;
            left: calc(76px + 15px);
        }

        .rt004-radio__radio:nth-child(1)::after {
            background-image: url('#{$resourcesFolder}/corporate/icons/cc-card-insieme-piu.png');
        }

        .rt004-radio__radio:nth-child(2)::after {
            background-image: url('#{$resourcesFolder}/corporate/icons/cc-card-insieme.png');
        }

        .rt004-radio__radio:nth-child(3)::after {
            background-image: url('#{$resourcesFolder}/corporate/icons/cc-card-prepagata.png');
        }

        .rt004-radio__radio .rt004-radio__inputLabel {
            @extend %dmSansFontMedium;
            font-size: rem(17px);
            line-height: rem(17px);
            color: var(--color-darkCharcoal);
        }
    }

    /* product complaint radios */
    &__productComplaintText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
    }
    &__productComplaintReason .rt004-radio__radios {
        flex-flow: column;
        row-gap: rem(21px);
    }

    /* card block reason radios */
    /* payment type radios */
    /* operating system radios */
    /* product complaint radios */
    &__blockReasonText,
    &__paymentTypeText,
    &__osText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
    }
    &__blockReasonRadios,
    &__paymentTypeRadios,
    &__osRadios,
    &__productComplaintReason {
        .rt004-radio__radio .rt004-radio__inputLabel {
            @extend %dmSansFontMedium;
            font-size: rem(17px);
            line-height: rem(17px);
            color: var(--color-darkCharcoal);

            @include respond-above(l) {
                font-weight: 700; /* bold */
            }
        }
    }

    /* radios */
    &__productData &__fields &__field:last-child {
        width: 100%;
    }

    &__messageData &__os {
        margin-bottom: rem(8px);
    }

    &__messageData &__fileUpload {
        margin-bottom: rem(16px);
    }

    &__messageData &__messageText {
        margin-bottom: rem(8px);

        textarea {
            min-height: rem(120px);
        }
    }

    &__privacyData {
        display: none;
        padding-top: rem(32px);
        //border-top: 1px solid alpha(--color-black, 10%);
        //margin-top: rem(8px);
    }
    &--showBottom &__privacyData {
        display: block;
    }

    &__privacyDataTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);

        @include respond-above(l) {
            line-height: rem(28px);
        }
    }

    &__privacyDataSubtitle {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-top: rem(12px);

        @include respond-above(l) {
            margin-top: rem(8px);
        }
    }

    &__privacyDataText {
        margin-top: rem(24px);
        position: relative;
    }

    &__shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: linear-gradient(to top, var(--color-white), alpha(--color-white, 50%));
    }
    &__privacyData--readAll &__shadow {
        display: none;
    }

    &__readAll {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        position: absolute;
        bottom: -16px;
        left: 0;

        &:hover {
            color: lightness(--color-black, 20%);
        }
    }
    &__privacyData--readAll &__readAll {
        display: none;
    }

    &__privacyDataText &__richtext {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        height: 80px;
        overflow: hidden;
    }
    &__privacyData--readAll &__richtext {
        height: unset;
        overflow: initial;
    }

    &__ctas {
        display: none;
        gap: rem(24px);
        flex-flow: row-reverse;
        align-items: center;
        margin-top: rem(40px);
    }
    &--showBottom &__ctas {
        display: flex;
    }

    &__send,
    &__cancel {
        @extend %dmSansFontMedium;
    }

    /* EAN code tooltip */
    &__eanTooltip {
        max-width: rem(395px);
        padding-top: rem(16px);
        padding-bottom: rem(16px);
    }
    &__eanTooltipContent {
        display: flex;

        /* image */
        & > div:first-child {
            width: 63px;
            height: 52.5px;
            background-image: url('#{$resourcesFolder}/corporate/icons/ean-code.svg');
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
            margin-right: 10px;
        }
    }
    
    &__statusMsg {
        @extend %srOnly;
    }
}
