@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt305-refresh-prize-choice {
    @include actionPopup();

    max-width: rem(862px);
    margin-left: auto;
    margin-right: auto;

    @include respond-above(l) {
        width: rem(862px);
    }

    &__title {
        text-align: center;
        padding: 0;
    }

    &__content {
        max-height: 97vh;

        @include respond-above(m) {
            max-height: 97vh;
        }
    }

    &__choice {
        max-width: 78%;
        margin: 0 auto;
        margin-top: rem(20px);

        @include respond-above(l) {
            width: rem(619px);
            margin-top: rem(50px);
        }
    }

    p {
        @extend %dmSansFontRegular;
        letter-spacing: normal;
        a {
            @extend %dmSansFontRegular;
            cursor: pointer;
            color: var(--color-info);
            font-weight: 500;
            text-decoration: underline;
        }

        .color-orange {
            color: var(--color-orange);
        }
    }

    &__goNextButton {
        @extend %buttonReset;
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        padding: rem(12px) rem(32px);
        border-radius: 12px;
        text-align: center;
        pointer-events: all;
        cursor: pointer;
        margin-top: rem(41px);
        margin-bottom: rem(50px);
        color: #545454;
        background-color: #d6d6d6;
        width: 78%;
        margin-left: auto;
        margin-right: auto;

        @include respond-above(l) {
            width: rem(140px);
            padding: rem(20px) rem(32px);
            margin-top: rem(50px);
            margin-right: rem(40px);
            font-size: rem(14px);
            line-height: rem(14px);
            align-self: flex-end;
        }

        &--active {
            color: hsl(0, 0%, 100%);
            background-color: hsl(20.347826087, 95.8333333333%, 47.0588235294%);
        }
    }
}
