@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt107-slider-mdd {
    @extend %container;
    width: 100%;

    &__carousel {
        overflow: hidden;
        min-height: rem(136px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(32px);
        margin-top: rem(16px);
    }

    &__text {
        margin-bottom: rem(32px);
    }

    &__left {
        width: 100%;
        flex: 0 0 auto;
        @extend %container;

        @include respond-below(l) {
            padding: 0;
            margin: 0;
        }

        @include respond-above(l) {
            width: poc(350px, 1024px);
            padding: 0;
            margin: 0;
            margin-right: rem(80px);
        }
    }

    /* slider */
    &__right {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        position: relative;
    }

    &__slideContainer {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;

        &:not(:last-of-type){
            margin-right: 16px;
        }
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
    }

    &__pagination {
        font-weight: 500;

        @include respond-below(l) {
            display: none;
        }

        &--hidden {
            @include respond-above(l) {
                display: none;
            }
        }
    }

    &__slide {
        max-width: rem(254px);
        min-height: rem(336px);

        @include respond-above(l) {
            max-width: rem(304px);
        }
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }

    &__prevNext {
        display: flex;
        flex-direction: row;
    }

    &__btn {
        @extend %buttonReset;
        cursor: pointer;
        transform: translateY(-50%);

        &--prev {
            display: none;
            transform: translateY(-50%) rotateZ(180deg);

            @include respond-above(l) {
                display: block;
                margin-right: rem(18px);
                margin-left: rem(18px);
                margin-top: rem(8px);
            }
        }

        &--next {
            display: none;

            @include respond-above(l) {
                display: block;
                margin-top: rem(8px);
            }
        }

        &--hidden {
            display: none;
        }

        &--disabled {
            opacity: 0.5;
        }

        &::after {
            content: '';
            background-image: url('#{$resourcesFolder}/corporate/icons/next.svg');
            display: block;
            background-size: rem(16px);
            background-repeat: no-repeat;
            background-position: center;
            width: rem(16px);
            height: rem(10px);
        }
    }

    &__scrollbar {
        margin-top: rem(24px);
        width: 45.5%;
        height: rem(5px);
        margin-left: 27.5%;
        border-radius: rem(10px);
        background-color: alpha(var(--color-black), 0.2);

        @include respond-below(l) {
            margin: 0;
            margin-top: rem(19px);
            width: 100%;
        }
    }

    &__scrollbarDrag {
        width: 100%;
        height: 100%;
        background-color: var(--color-black);
        border-radius: rem(10px);
    }
}
