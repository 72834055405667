@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt147-two-columns-card-services {
    @extend %container;
    &__track {
        display: grid;
        grid-template-columns: auto [col-start] 50% [col2-start] 50% auto;

        @include respond-below(l) {
            display: flex;
            justify-content: center;
            gap: rem(16px);
            flex-direction: column;
        }


    }

    &__slide {

        @include respond-below(l) {
            display: flex;
            justify-content: center;
        }

        @include respond-above(l) {
            display: flex;
            grid-column: col-start;

            &:nth-child(2n+1) {
                grid-column: col-start;
                margin-bottom: rem(16px);
            }

            &:nth-child(2n) {
                grid-column: col2-start;
                margin-left: rem(16px);
                margin-bottom: rem(16px);
            }
        }

    }

     /* default texts style */

     &__text {
        margin-bottom: rem(16px);
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: alpha(--color-black, 0.5);

        @include respond-above(l) {
            margin-bottom: rem(36px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(32px);
    }

}
