@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt025-search-alert {
    $root: &;
    display: none;
    align-items: center;
    background-color: var(--color-primary1);
    padding: rem(6px) rem(16px);
    min-height: rem(36px);

    &--show {
        display: flex;
    }

    & > p {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-white);
        flex-grow: 1;
        text-align: center;

        &::before {
            @extend %ecIcon;
            content: $ec-icon-search;
            font-size: rem(16px);
            position: relative;
            bottom: -2px;
            margin-right: 8px;
        }

        & > span:last-child {
            @extend %dmSansFontBold;
        }
    }

    &__close {
        flex-shrink: 0;
        font-size: rem(24px);
        color: var(--color-white);
        cursor: pointer;
    }
}
