@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt105-collector {
    $root: &;

    &__top {
        @extend %container;
    }

    &--variant4 {
        #{$root}__content {
            @extend %container;
            display: flex;
            flex-direction: column;

            @include respond-above(l) {
                flex-direction: row;
                justify-content: space-between;
            }
        }

        #{$root}__part1 {
            @include respond-below(l) {
                padding-bottom: rem(24px);
                border-bottom: 1px solid lightness(--color-white, 90%);
                width: 100%;
            }

            @include respond-above(l) {
                width: 49.153%;
            }
        }

        #{$root}__part2 {
            @include respond-below(l) {
                margin-top: rem(24px);
                align-self: center;
                width: 100%;
            }
            @include respond-above(l) {
                width: 40.678%;
            }
        }
    }

    &--variant1,
    &--variant2,
    &--variant6 {
        #{$root}__part1 {
            margin-bottom: rem(24px);
        }
    }

    &--variant7 {
        #{$root}__part1 {
            margin-bottom: rem(24px);

            @include respond-above(l) {
                margin-bottom: rem(32px);
            }
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(32px);
    }

    /* default texts style */

    &__text {
        margin-bottom: rem(16px);
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);

        @include respond-above(l) {
            margin-bottom: rem(36px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__logo {
        height: fit-content;
        max-height: rem(100px);
        object-fit: contain;
        margin-bottom: rem(16px);
    }
}
