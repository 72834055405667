@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.ru002-modal {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: setZ(modal);
    pointer-events: none;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

    /* mobile */
    @include respond-below(m) {
        height: -webkit-fill-available;
        /*! autoprefixer: ignore next */
        height: fill-available;
        min-height: -webkit-fill-available;
        /*! autoprefixer: ignore next */
        min-height: fill-available;
        max-height: -webkit-fill-available;
        /*! autoprefixer: ignore next */
        max-height: fill-available;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: var(--color-white);
        max-width: rem(720px);
        left: -100%;
        transition: left 0.5s ease-in-out;
    }

    &__rightContent {
        width: 100%;
        left: 0;
        position: relative;
        display: none;
        height: 100%;
        position: absolute;
        top: 0;

        @include respond-above(l) {
            width: calc(100vw - #{rem(720px)});
            left: rem(720px);
        }
    }

    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: alpha(--color-black, 0.6);
        display: block;
        z-index: setZ(negative);
        opacity: 0;

        &--unclosable {
            pointer-events: none;
        }
    }

    &--open {
        pointer-events: all;
    }

    &--open &__content {
        left: 0;
    }

    &--open &__rightContent {
        @include respond-above(l) {
            display: block;
        }

        &--forceShow {
            display: block;
        }
    }

    &--open &__backdrop {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }

    &--noTransition &__backdrop {
        transition: none;
    }

    &--noTransition &__content {
        transition: none;
    }
}
