@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;

.rt003-checkbox {
    position: relative;

    /* custom checkbox */
    &__label {
        cursor: pointer;
        pointer-events: all;

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }
    &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &__checkbox {
        position: relative;
        display: block;

        &::before,
        &::after {
            content: '';
            position: absolute;
            vertical-align: middle;
            width: rem(21px);
            height: rem(21px);
            top: 0;
            left: 0;
        }
        &::before {
            display: inline-block;
            border: 2px solid alpha(--color-darkCharcoal, 0.3);
            border-radius: rem(5px);
        }
        &::after {
            display: none;
            width: rem(24px);
            height: rem(24px);
            top: rem(-1.5px);
            left: rem(-1.6px);
            background-image: url('#{$resourcesFolder}/corporate/icons/checkbox.svg');
        }
        &:hover::before {
            border-color: alpha(--color-primary1, 0.6);
        }
    }
    &__input:checked + &__checkbox::after {
        display: block;
    }
    &__input:checked + &__checkbox::before {
        border-color: alpha(--color-primary1, 0.6);
    }
    &--errorState &__checkbox::before {
        border: 1px solid var(--color-semantic2);
    }

    &__labelText {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(21px);
        color: var(--color-txt);
        padding-left: rem(32px);
        
        &--html {
            font-weight: normal;

            a {
                color: var(--color-semantic4);
                font-weight: 500;
                text-decoration: underline;
            }
        }
    }

    &__error {
        @extend %font-body3;
        @extend %dmSansFontRegular;
        color: var(--color-semantic2);
        align-self: flex-start;
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
