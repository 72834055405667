@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt221-card-page {
    position: relative;
    padding-bottom: rem(16px);
    border-bottom: 1px solid lightness(--color-black, 90%);

    @include respond-above(l) {
        padding-bottom: rem(24px);
    }

    &__link {
        display: block;
        width: fit-content;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        margin-top: rem(20px);
        text-decoration: underline;
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        margin-top: rem(8px);
        max-width: 80ch; //80 characters
    }
}
