@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt054-topbar {
    $root: &;
    width: 100%;
    background-color: var(--color-neutral);
    @extend %dmSansFontRegular;
    font-size: rem(12px);
    line-height: 1.43em;

    &__container {
        @extend %container;
        height: rem($topbarHeight);
        display: flex;
        align-items: center;
    }

    &__initIcon {
        font-size: rem(16px);
        vertical-align: text-bottom;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    &__link {
        margin-left: rem(8px);
        margin-right: rem(8px);
        flex: 0 1 auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        max-width: rem(350px);

        @include respond-above(l) {
            margin-left: rem(4px);
        }
    }

    &__dsk {
        display: none;
        margin-left: rem(8px);

        @include respond-above(l) {
            display: block;
        }
    }

    &__next {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        @include respond-above(l) {
            display: none;
        }
    }

    .noshop,
    .prefshop,
    .lastshop {
        display: none;
    }

    .noshop {
        display: block;
    }

    &__manage {
        flex: 1 0 auto;
        text-align: right;
        display: none;
        margin-left: rem(25px);
        font-size: rem(12px);
        line-height: 1.43em;

        @include respond-above(l) {
            text-align: left;
            margin-left: 0;
        }
    }

    &__gotos {
        flex: 1 1 auto;
        display: none;
        justify-content: flex-end;

        @include respond-above(l) {
            display: flex;
        }
    }

    &__gotoLink {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: 1.33em;
        border-right: 1px solid lightness(--color-white, 90%);
        padding-left: rem(8px);
        padding-right: rem(8px);

        &:last-of-type {
            padding-right: 0;
            border-right: 0;
        }
    }

    &__gotoLinkIcon {
        margin-right: rem(8px);
        vertical-align: text-bottom;
        font-size: rem(16px);
    }

    &--loaded {
        #{$root}__link,
        #{$root}__next,
        #{$root}__initIcon {
            opacity: 1;
        }
    }

    &--lastShop {
        .noshop {
            display: none;
        }
        .lastshop {
            display: block;
        }

        #{$root}__link {
            font-weight: bold;
        }
    }

    &--prefShop {
        .noshop {
            display: none;
        }
        .prefshop {
            display: block;
        }
        #{$root}__link {
            flex: 0 1 auto;
            font-weight: bold;
        }
        #{$root}__manage {
            display: block;
        }
    }
}
