@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt027-paginator {
    display: flex;
    justify-content: center;
    align-items: center;

    &__pageItems {
        display: flex;
        justify-content: center;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(16px);
    }

    &__pageItem,
    &__pageBack,
    &__pageNext {
        display: block;
        cursor: pointer;
        @extend %buttonReset;
    }

    &__pageBack {
        margin-right: rem(24px);
        font-weight:  500;
        font-size: rem(24px);
        display: block;
        &::after {
            font-weight:  500;
            font-size: rem(24px);
        }

        & > span {
            display: none;

            &:hover {
            }
        }
        @include respond-above(l) {
            margin-right: rem(8px);
        }
    }

    &__pageNext {
        margin-left: rem(24px);
        font-weight:  500;
        font-size: rem(24px);
        display: block;
        &::after {
            font-weight:  500;
            font-size: rem(24px);
        }

        & > span {
            display: none;

            &:hover {
            }
        }

        @include respond-above(l) {
            margin-left: rem(8px);
        }
    }

    &__pageItem {
        min-width: rem(30px);
        height: rem(30px);
        line-height: rem(30px);
        margin-right: rem(10px);
        text-align: center;

        @include respond-above(m) {
            margin-right: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &--active {
            color: alpha(--color-black, 0.4);
            
            &:hover {
                cursor: default;
            }
        }
    }
}
