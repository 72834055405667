@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='pet-friends'],
body.theme-pet-friends {
    @include defineColorsHexMap(map.merge($default-theme, $pet-friends-theme));
}
