@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt132-login-banner {
    @extend %container;
    position: relative;

    $img-height-dsk: rem(295.5px);

    & > div:first-child {
        @include respond-above(m) {
            width: poc(384px, 944px);
            min-height: $img-height-dsk;
        }
    }
    
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(12px);

        @include respond-above(m) {
            margin-bottom: rem(24px);
            padding-top: rem(20px);
        }

        &::after {
            display: block;
            content: ' ';
            width: 32px;
            height: 4px;
            border-radius: 4px;
            background-color: var(--color-primary2);
            margin-top: rem(10px);
        }
    }

    &__image {
        margin-bottom: rem(23px);

        @include respond-above(m) {
            height: $img-height-dsk;
            margin-bottom: unset;
            position: absolute;
            top: 0;
            $left: calc(#{poc(384px, 944px)} + 29px);
            left: $left;
            max-width: calc(100% - #{$container-wpad-desk} - #{$left});
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-primaryGray);
        margin-bottom: rem(39px);

        @include respond-above(m) {
            margin-bottom: rem(32px);
        }
    }

    &__ctas {
        display: flex;
        gap: rem(16px);

        @include respond-above(m) {
            gap: rem(24px);
        }
    }

    &__link {
        flex-grow: 1;
        text-align: center;

        @include respond-above(m) {
            text-align: unset;
            flex-grow: unset;
        }
    }
}