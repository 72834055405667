@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt303-refresh-c-popup {
    @include actionPopup();

    @include respond-above(m) {
        max-width: rem(720px);
        width: rem(720px);
    }

    @include respond-above(l) {
        max-width: rem(794px);
        width: rem(794px);
    }

    &__topper {
        display: flex;
        flex-flow: column;
        padding-left: rem(40px);
        padding-right: rem(40px);
        padding-top: rem(24px);

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__logo {
        flex-shrink: 0;
        width: rem(192px);
        height: rem(140px);
    }

    &__title {
        font-weight: bold;
        @extend %font-heading3;
        padding-top: rem(24px);
        padding-left: 0;
        padding-right: 0;

        @include respond-above(m) {
            padding-left: rem(30px);
        }
    }

    &__text {
        font-weight: bold;
        padding-left: 0;
        @include respond-above(m) {
            padding-left: rem(30px);
        }
    }

    &__bottom {
        padding-left: rem(40px);
        padding-right: rem(40px);
        padding-bottom: rem(20px);
        margin-top: rem(70px);
        display: flex;
        flex-flow: column;
        gap: rem(20px);
        justify-content: space-between;

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__discoverMore {
        font-weight: 500;
        color: var(--color-semantic4);
        text-decoration: underline;
    }

    &__infotext {
        max-width: rem(365px);
    }
}
