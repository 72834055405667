@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt019-status-dot {
    $size: rem(8px);
    width: $size;
    height: $size;
    border-radius: 50%;
    background-size: $size;

    /* status */
    &[data-status='success'] {
        background-color: var(--color-semantic1);
    }
    &[data-status='error'] {
        background-color: var(--color-semantic2);
    }
    &[data-status='warning'] {
        background-color: var(--color-semantic3);
    }
    &[data-status='info'] {
        background-color: var(--color-semantic4);
    }
}
