@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use '../../../../../style/modules/themes' as *;

.rt001-richtext {

    &__mobLabel {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-primaryGray);
        padding: rem(8px) 0;
        display: block;

        @include respond-above(l) {
            display: none;
        }
    }

    &--styling {
        //standard text
        @extend %dmSansFont;
        font-size: rem(14px);
        line-height: 1.43em;

        //links
        a {
            font-weight: 500;
            color: var(--color-semantic4);
            text-decoration: underline;
        }

        //standard fonts
        .m20d20 {
            font-size: rem(20px);
            line-height: rem(24px);
        }

        .roboto {
            @extend %robotoFont;
        }

        .bold {
            font-weight: 700;
        }

        .regular {
            font-weight: 400;
        }

        .medium {
            font-weight: 500;
        }

        .m12d12-regular {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(18px);
        }

        .m16d16-bold {
            @extend %dmSansFontBold;
            font-size: rem(16px);
            line-height: rem(24px);
        }

        //title fonts
        .title-m32d48 {
            @extend %robotoFont;
            font-size: rem(32px);
            line-height: rem(40px);
            font-weight: 500;

            @include respond-above(l) {
                font-size: rem(48px);
                line-height: rem(56px);
            }
        }

        .title-m28d32 {
            @extend %dmSansFontBold;
            font-size: rem(28px);
            line-height: rem(30px);

            @include respond-above(l) {
                font-size: rem(32px);
                line-height: rem(40px);
            }
        }

        .title-m28d30 {
            @extend %robotoFont;
            font-size: rem(28px);
            line-height: rem(36px);

            @include respond-above(l) {
                font-size: rem(30px);
                line-height: rem(38px);
            }
        }

        .title-m24d24 {
            @extend %dmSansFontBold;
            font-size: rem(24px);
            line-height: rem(32px);
        }

        .title-m20d24 {
            @extend %dmSansFontBold;
            font-size: rem(20px);
            line-height: rem(24px);
            
            @include respond-above(l) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
            
        }

        .title-m20d20 {
            @extend %dmSansFontBold;
            font-size: rem(20px);
            line-height: rem(28px);
        }

        //colors

        @each $name, $color in $default-theme {
            .color-#{$name} {
                color: var(--color-#{$name});
            }
        }

        // lists
        ol, 
        ul {
            list-style: revert;
            margin: revert;
            padding: revert;
        }

        // tables
        thead {

            @include respond-above(l) {
                border-top: 1px solid alpha(--color-black, 10%);
            }
        }
        thead > tr {
            background-color: alpha(--color-neutral, 20%);
        }
        thead > tr > th {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(14px);
            color: var(--color-primaryGray);
            padding: rem(8px) 0;
            white-space: nowrap;
        }
        tbody > tr:nth-child(2n) {
            background-color: alpha(--color-neutral, 20%);
        }
        tbody > tr:nth-child(2n + 1) {
            background-color: var(--color-neutral);
        }
        tbody > tr > td {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-primaryGray);
            padding: rem(8px) rem(16px);

            @include respond-above(l) {
                padding: rem(8px) rem(5px);
            }
        }
        tbody > tr > td:last-child {
            padding-bottom: rem(16px);
        }

        @include respond-below(l) {
            /* Force table to not be like tables anymore */
            table,
            thead,
            tbody,
            th,
            td,
            tr {
                display: block;
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
                position: absolute;
                top: -9999px;
                left: -9999px;
            }

            td {
                /* Behave  like a "row" */
                border: none;
                position: relative;
                padding-left: 50%;
            }

            td:before {
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 0;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
            }
        }
    }

    &--container {
        @extend %container;
    }
}
