@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt032-filter-popup {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    width: rem(304px);
    padding: rem(16px);
    background-color: var(--color-white);
    box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 
                0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    border-radius: 16px;
    border: 1px solid lightness(--color-black, 20%);
    z-index: setZ(default);

    &.open {
        visibility: visible;
        pointer-events: all;
    }

    &__form {
        position: relative;
        overflow: hidden;
        height: 100%;
        max-height: 350px;
        display: flex;
        flex-flow: column;
        gap: rem(16px);
    }

    &__option {
        display: flex;

        &--hidden {
            display: none;
        }
    }

    &__checkbox {
        flex-grow: 1;
    }

    &__checkbox ~ span {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-black);
        display: inline-block;
        width: rem(41px);
        height: fit-content;
        text-align: center;
        padding: rem(5px) 0;
        background-color: alpha(--color-darkCharcoal, 5%);
        border-radius: 12px;
        flex-shrink: 0;
    }

    &__ctas {
        display: flex;
        gap: rem(8px);
        margin-top: rem(16px);

        & > :first-child {
            margin-left: auto;
        }
    }

    &__ctas--disabled {
        
    }

    &__search {
        margin-bottom: rem(24px);
    }
}
