@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt055-menu-dsk {
    $root: &;
    padding: rem(32px) 0 rem(24px) 0;
    display: none;
    @include respond-above(l) {
        display: flex;
    }

    &__column {
        padding-right: rem(18px);
        border-right: 1px solid lightness(--color-black, 90%);
        margin-right: rem(15px);
        flex: 0 0 auto;
    }

    &__columnTitle {
        display: block;
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(24px);
    }

    &__columnTitle ~ &__columnItems > &__menuItem {
        @extend %dmSansFontRegular;
    }

    &__columnItems {
        display: flex;
        flex-direction: column;
    }

    &__menuItem {
        @extend %dmSansFontMedium;
        margin-bottom: rem(27px);
        font-size: rem(14px);
        line-height: rem(20px);
        font-weight: 500;

        &:last-child {
            margin-bottom: 0;
        }

        &.selected {
            color: var(--color-primaryGray);
        }

        &--disabled {
            color: alpha(--color-black, 40%);
            pointer-events: none;
        }
    }

    &__brands {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        flex: 1 1 auto;
    }

    &__brand {
        display: flex;
        flex-direction: column;
        width: rem(144px);
        border-radius: rem(12px);
        box-shadow: 0 1px 1px 0 lightness(--color-darkCharcoal, 85%), 0 0 1px 0 lightness(--color-darkCharcoal, 85%);
        margin-right: rem(16px);
        padding-top: rem(7px);
        margin-bottom: rem(16px);
    }

    &__brandTxt {
        flex-grow: 1;
        font-size: rem(10px);
        line-height: rem(14px);
        text-align: center;
        background-color: var(--color-neutral);
        padding: rem(4px) rem(8px) rem(8px) rem(8px);
        border-bottom-left-radius: rem(12px);
        border-bottom-right-radius: rem(12px);
    }

    &__brandImg {
        width: auto;
        max-width: 100%;
        max-height: 100%;
    }

    &__brandImgContainer {
        padding: 0 rem(7px);
        display: flex;
        height: rem(49px);
        width: 100%;
        margin-bottom: rem(7px);
        align-items: center;
        justify-content: center;
    }

    &__evidenceTitle {
        display: block;
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        margin-bottom: rem(14px);
    }

    &__evidenceImage {
        display: block;
        width: rem(307px);
        height: rem(132px);
    }

    &__evidenceLink {
        position: relative;
        display: block;
        border-radius: rem(12px);
        overflow: hidden;
    }

    &__evidenceLabel {
        position: absolute;
        display: block;
        height: rem(34px);
        width: 100%;
        bottom: 0;
        background: var(--color-neutral);
        font-size: rem(14px);
        line-height: rem(20px);
        padding: rem(5px) rem(8px);
    }

    &__promo {
        display: flex;
    }

    &__promoImage {
        width: rem(144px);
        height: rem(192px);
    }

    &__promoLabel {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        margin-left: rem(15px);
        width: rem(144px);
    }

    &--brands {
        max-width: rem(883px);

        #{$root}__column {
            width: rem(148px);
        }
    }

    &--wcard {
        max-width: rem(883px);

        #{$root}__column {
            width: rem(213px);
        }

        #{$root}__menuItem {
            margin-bottom: rem(24px);
            line-height: rem(14px);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--twocolumns {
        max-width: rem(942px);

        #{$root}__column {
            width: rem(310px);
        }

        #{$root}__columnItems {
            height: rem(160px);
            flex-wrap: wrap;
        }

        #{$root}__menuItem {
            font-size: rem(14px);
            line-height: rem(20px);
            margin-bottom: rem(16px);
            max-width: rem(145px);
            margin-right: rem(30px);

            &:nth-child(4n) {
                margin-bottom: 0;
            }

            &:nth-child(n + 5) {
                margin-right: 0;
            }
        }
    }
}
