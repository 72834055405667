@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt128-cards-icon-text {
    @extend %container;

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(36px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(38px);

        @include respond-above(l) {
            margin-bottom: rem(56px);
        }

        &::after {
            display: block;
            content: ' ';
            width: 32px;
            height: 4px;
            border-radius: 4px;
            background-color: var(--color-primary2);
            margin-top: rem(10px);
        }
    }

    &__cards {
        display: flex;
        gap: rem(50px);
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row;
            gap: rem(20px);
        }
    }

    &__card {
        flex: 1 0 0;
    }
}