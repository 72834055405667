@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt146-ingredients-actions {
    margin-bottom: rem(32px);

    @include respond-above(l) {
        margin-bottom: rem(40px);
    }

    &__accordion {
        padding-left: 0;
        padding-right: 0;
    }

    & > &__accordion &__title {
        @extend %dmSansFontRegular;
        font-size: rem(18px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
    }

    &__ctas {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        margin-top: rem(40px);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            flex-flow: row-reverse;
        }
    }

    &__cta {
        @include respond-below(l) {
            width: 100%;
            text-align: center;
        }
    }
}
