@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt026-category {
    $root: &;
    width: rem(144px);
    cursor: pointer;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__media {
        border-radius: 50%;
        box-shadow: 0 1px 8px -1px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        width: rem(88px);
        height: rem(88px);
        position: relative;
    }

    &__image,
    &__icon {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: var(--color-white);
    }

    &__icon {
        font-size: rem(50px);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-primary1);
    }

    &__input {
        display: none;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        padding-top: rem(20px);
        text-align: center;
        color: var(--color-darkCharcoal);
    }
}
