@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;

.rt002-cta-primary {

    &:hover {
        background-color: alpha(--color-primary1, 0.9);
    }

    /* STATUS */
    background-color: var(--color-primary1);
    color: var(--color-white);

    &--disabled {
        pointer-events: none;
        background-color: alpha(--color-darkCharcoal, 0.05);
        color: alpha(--color-darkCharcoal, 0.2);
    }

    &--success {
        background-color: var(--color-semantic1);
        color: var(--color-white);
    }
    &--success:hover {
        background-color: alpha(--color-semantic1, 0.8);
    }

    &--neutralDark {
        background-color: var(--color-cta2);
        color: var(--color-white);
    }
    &--neutralDark:hover {
        background-color: var(--color-grayscale14);
    }

    &--neutralLight {
        background-color: var(--color-white);
        color: var(--color-darkCharcoal);
    }
    &--neutralLight:hover {
        background-color: alpha(--color-black, 0.1);
    }
}