@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt220-card-nearby {
    min-height: rem(89px);
    & > a {
        display: flex;
        flex-direction: row;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-darkCharcoal);
    }

    &__km {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        border-radius: rem(12px);
        min-width: rem(62px);
        height: rem(24px);
        background-color: var(--color-grayscale18);
        text-align: center;
        padding: rem(5px) rem(16px);
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: rem(16px);
    }

    &__right {
        display: flex;
        flex-direction: column;
    }

    &__iconCont {
        border-radius: rem(12px);
        background-color: var(--color-grayscale18);
        width: rem(80px);
        height: rem(80px);
        margin-right: rem(16px);
        display: flex;
        justify-content: center;
    }

    &__icon {
        align-self: center;
        font-size: rem(46px);
        color: var(--color-grayscale17);
    }

    &__car {
        margin-right: rem(8px);
    }

    &__minutes {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        display: flex;
        align-items: center;
    }
}
