@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt301-books-reservation {
    max-width: rem(648px);

    &__content {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: rem(24px);
        background-color: var(--color-white);
        border-radius: rem(12px);
        overflow: hidden;
        margin: 0 rem(5px);
        padding: rem(32px) rem(68px) rem(40px);

        @include respond-above(l) {
            margin: unset;
        }
    }

    &__image {
        width: rem(224px);
        height: rem(134px);
    }
    
    &__title {
        @extend %robotoFontMedium;
        font-size: rem(30px);
        line-height: rem(38px);
        color: lightness(--color-black, 20%);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(18px);
        color: lightness(--color-black, 20%);
        max-width: rem(335px);
        text-align: center;
    }

    &__feedback {
        max-width: unset;
        padding: 0;
    }

    &__loader {
        display: inline-block;
        position: relative;
        width: rem(80px);
        height: rem(80px);
        background-image: url('#{$resourcesFolder}/corporate/icons/loader.gif');
        background-repeat: no-repeat;
        background-size: rem(80px);
    }
}