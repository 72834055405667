@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt202-card-elev-recipes {
    $root: &;
    border-radius: rem(12px);
    background-color: var(--color-white);
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    /* standard variant */
    width: rem(254px);
    min-height: rem(336px);
    display: flex;
    flex-direction: column;

    @include respond-above(l) {
        width: 100%;
    }

    &__info {
        padding: rem(16px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: auto;
    }

    &--largeSx,
    &--largeDx {
        display: flex;
        width: 100%;
        min-height: rem(503px);
        flex-direction: column;

        @include respond-above(l) {
            width: 100%;
            min-height: rem(336px);
        }

        #{$root}__title {
            font-size: rem(24px);
            line-height: rem(32px);
            margin-bottom: rem(8px);
        }

        #{$root}__info {
            @include respond-above(l) {
                padding: rem(24px);
            }
        }

        #{$root}__bottom {
            bottom: rem(24px);
        }

        #{$root}__image.rt022-picture--cover {
            @include respond-above(l) {
                width: rem(468px);
                height: rem(336px);
            }
        }
    }

    /* large variant, with image on right */
    &--largeSx {
        @include respond-above(l) {
            flex-direction: row-reverse;
        }

        #{$root}__image.rt022-picture--cover {
            @include respond-above(l) {
                border-top-right-radius: rem(12px);
                border-bottom-right-radius: rem(12px);
                border-top-left-radius: 0;
            }
        }

        #{$root}__info {
            @include respond-above(l) {
                border-top-left-radius: rem(12px);
                border-bottom-left-radius: rem(12px);
            }
        }
    }

    /* large variant, with image on left */
    &--largeDx {
        @include respond-above(l) {
            flex-direction: row;
        }

        #{$root}__image.rt022-picture--cover {
            @include respond-above(l) {
                border-top-left-radius: rem(12px);
                border-bottom-left-radius: rem(12px);
                border-top-right-radius: 0;
            }
        }

        #{$root}__info {
            @include respond-above(l) {
                border-top-right-radius: rem(12px);
                border-bottom-right-radius: rem(12px);
            }
        }
    }

    /* carousel variant */
    &--carousel {
        display: flex;
        flex-direction: column;
        width: rem(254px);
        height: rem(336px);

        @include respond-above(l) {
            width: 100%;
        }

        #{$root}__image.rt022-picture--cover {
                height: rem(152px);
        }
    }

    &__author {
        @extend %dmSansFont;
        font-size: rem(12px);
        line-height: rem(16px);
        color: alpha(--color-black, 0.2);
    }

    &__authorName {
        font-weight: 600;
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        font-weight: 500;
        line-height: rem(16px);
        margin-bottom: rem(8px);
    }

    &__title {
        @extend %dmSansFont;
        font-size: rem(16px);
        font-weight: 500;
        line-height: rem(24px);
        margin-bottom: rem(16px);
        color: var(--color-darkCharcoal);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        font-weight: 400;
        color: var(--color-primaryGray);
        line-height: rem(20px);
        margin-bottom: rem(16px);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__media {
        position: relative;
    }

    &__image.rt022-picture--cover {
        border-top-left-radius: rem(12px);
        border-top-right-radius: rem(12px);
        height: rem(184px);
    }

    &__summary {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__preparationTime {
        margin-right: rem(24px);
    }

    &__preparationTime, &__level {
        display: flex;
        flex-direction: column;
    }

    &__preparationTimeLabel, &__levelLabel {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        margin-top: rem(1px);
    }

    &__ctaBookmark {
        width: rem(40px);
        height: rem(40px);
        position: absolute;
        top: rem(16px);
        right: rem(16px);
        color: var(--color-bookmark);

        &:hover {
            background-color: var(--color-white);
        }
    }

    &__mdd {
        position: absolute;
        top: rem(16px);
        left: rem(16px);
    }

    &__bottom {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__icon, &__iconChef {
        color: var(--color-black);
        margin-bottom: rem(4px);

        &--desel {
            color: var(--color-grayscale10);
        }
    }

    &--fixed {
        width: 100%;
        margin-bottom: rem(28px);

        @include respond-above(l) {
            margin-bottom: rem(40px);
        }

        #{$root}__info {
            background-color: lightness(--color-semantic3, 94.9%);

            @include respond-above(l) {
                padding: rem(24px);
                border-radius: 0 12px 12px 0;
            }
        }
    }
}
