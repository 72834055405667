@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt123-container-card-services {
    @extend %container;

    &__track {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: rem(16px);

        @include respond-above(l) {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &__slide {
        display: flex;
        align-items: stretch;
        justify-content: center;
    }
}
