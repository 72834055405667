@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt051-header {
    $root: &;
    left: 0;
    right: 0;
    box-shadow: 0 4px 8px -2px alpha(--color-headerShadow, 0.15), 0 0 1px 0 alpha(--color-headerShadow, 0.2);
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: var(--color-white);
    z-index: setZ(header);

    &__container {
        @extend %container;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background-color: var(--color-white);
        order: 1;
        height: rem(64px);
        padding-top: rem(12px);
        padding-bottom: rem(12px);

        @include respond-above(l) {
            height: rem(96px);
            padding-top: rem(16px);
            padding-bottom: rem(24px);
            order: 2;
        }
    }

    &__topbar {
        transition: height 0.3s ease-in-out;
        overflow: hidden;
        height: rem($topbarHeight);
        position: relative;
        order: 2;

        @include respond-above(l) {
            order: 1;
        }
    }
    &--menuMobOpen &__topbar {
        position: unset;
    }

    &__menuBtn {
        font-size: rem(24px);

        @include respond-above(l) {
            display: none;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &--visible {
            opacity: 1;
        }
    }

    &__logo {
        @include respond-below(l) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__anonymousLoginBtn {
        display: block;
    }

    &__anonymousRegisterBtn {
        display: none;
        @include respond-above(l) {
            margin-left: rem(16px);
            display: flex;
        }
    }

    &__loggedBtn {
        display: none;
        font-size: rem(24px);

        &.rt002-cta--large {
            padding: 0;
        }
    }

    &__searchBtn {
        display: none;
        font-size: rem(24px);
        line-height: rem(24px);
        margin-right: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            display: block;
        }

        & > span {
            vertical-align: bottom;
        }
    }
    &__searchBtn:focus-visible {
        @include outlineFocusVisible();
    }

    &__logoScrolled {
        display: none;
    }

    &__menu {
        @extend %container;
        display: none;
        order: 99;
        height: rem(34px);
        position: relative;

        @include respond-above(l) {
            display: flex;
            background-color: var(--color-white);
        }
    }

    &__menuItem {
        @extend %dmSansFontMedium;
        margin-right: rem(24px);
        font-size: rem(16px);
        line-height: 1em;
        padding-bottom: rem(14px);
        display: flex;
        align-items: center;
        color: var(--color-darkCharcoal);

        &:last-of-type {
            margin-right: 0;
        }

        &--submenu::after {
            display: inline-block;
            @extend %ecIcon;
            content: $ec-icon-chevron-up;
            vertical-align: text-up;
            margin-left: rem(8px);
            font-weight: bold;
            transform: rotateZ(180deg);
            transition: transform 0.3s ease-in-out;
        }

        &--submenu.active::after {
            transform: rotateZ(0deg);
        }

        &.selected ~ hr {
            opacity: 1;
            display: block;
        }
    }

    &__menuItemContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: rem(24px);

        hr {
            background-color: var(--color-primary2);
            margin-bottom: 0;
            width: rem(24px);
            height: rem(4px);
            border: none;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            border-top-left-radius: rem(12px);
            border-top-right-radius: rem(12px);
            display: none;
        }
    }

    &__submenu {
        position: relative;
        overflow: hidden;
        visibility: hidden;
        height: 0;
        order: 99;
        transition: height 0.3s ease-in-out;
        background-color: var(--color-white);
    }

    &__submenuItem {
        flex: 0 0 auto;
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease-in;
        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    &--scrolled {
        @include respond-below(l) {
            #{$root}__logoStandard {
                display: none;
            }

            #{$root}__logoScrolled {
                display: block;
            }
        }
    }

    &--topbarHidden &__topbar {
        height: 0;
    }

    &--user {
        #{$root}__anonymousLoginBtn {
            display: none;
        }
        #{$root}__anonymousRegisterBtn {
            display: none;
        }
        #{$root}__loggedBtn {
            display: block;
        }
    }

    & ~ .rl1-layout,
    & ~ .rl2-anchors-layout,
    & ~ #feedback-container {
        margin-top: rem(64px + 48px);

        @include respond-above(l) {
            margin-top: rem($topbarHeight + 34px + 96px);
        }
    }
    &--scrolled ~ .rl1-layout,
    &--scrolled ~ .rl2-anchors-layout,
    &--scrolled ~ #feedback-container {
        @include respond-below(l) {
            margin-top: rem(64px);
        }
    }

    & ~ #feedback-container {
        transition: margin-top 0.3s ease-in-out;
    }

    &__searchBar {
        visibility: hidden;
        pointer-events: none;
    }
    &--searchOpen &__searchBar {
        @include respond-above(l) {
            visibility: visible;
            pointer-events: all;
            margin-top: rem($topbarHeight + 34px + 96px);
        }
    }

    &__backdrop {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--color-primaryGray);
        height: 0;
        pointer-events: none;
    }
    &--searchOpen &__backdrop {
        @include respond-above(l) {
            height: calc(100vh - 100%);
            pointer-events: all;
        }
    }
}
