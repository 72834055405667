@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt219-fuel-card {
    $root: &;
    border-radius: rem(12px);
    width: rem(224px);
    min-height: rem(136px);
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 rem(16px);
    padding-top: rem(16px);
    padding-bottom: rem(24px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    margin-bottom: rem(8px);

    @include respond-above(l) {
        padding-top: rem(40px);
        min-height: rem(221px);
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(28px);
        line-height: rem(36px);
        color: var(--color-grayscale16);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(8px);
        margin-bottom: rem(18px);
    }

    &__selfOrServ {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        @include respond-above(l) {
            line-height: rem(14px);
        }
    }

    &__selfh24 {
        @extend %dmSansFontMedium;
        color: var(--color-primaryGray);
        font-size: rem(12px);
        line-height: rem(12px);
        margin-top: rem(4px);
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include respond-above(l) {
            align-items: flex-end;
        }
    }

    &__price {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
    }
}
