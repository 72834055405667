@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.rt068-search-results {
    $root: &;
    @extend %container;

    &__search {
        @include respond-above(l) {
            max-width: poc(703px, 944px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-darkCharcoal);
        margin-top: rem(24px);

        @include respond-above(l) {
            margin-top: rem(40px);
        }
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        margin-top: rem(4px);

        @include respond-above(l) {
            margin-top: rem(8px);
        }
    }

    &__noResults {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        margin-top: rem(32px);

        @include respond-above(l) {
            flex-flow: row;
            align-items: center;
            gap: rem(57px);
            margin-top: rem(16px);
        }
    }

    &__noResultsImage {
        max-width: rem(165px);
        max-height: rem(174px);

        @include respond-above(l) {
            max-width: rem(266px);
            max-height: rem(281px);
            flex-shrink: 0;
        }
    }

    &__noResults > div:last-child {
        @include respond-above(l) {
            flex-grow: 1;
        }
    }

    &__noResultsText {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__noResultsSupportText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: rem(14px);
        }
    }

    &__filters {
        position: relative;
    }
    &__filtersTrack {
        position: relative;
        display: flex;
        gap: rem(16px);
        overflow: hidden;
        padding: rem(8px) rem(4px);
        margin: rem(16px) rem(-4px);

        /* hide scrollbar */
        &.ps > .ps__rail-x {
            display: none;
        }
    }
    &__fade {
        width: rem(32px);
        height: rem(32px);
        position: absolute;
        top: rem(8px);
        right: rem(-4px);
        background: linear-gradient(to right, #{alpha(--color-white, 0.2)}, #{alpha(--color-white, 0.9)});
    }
    &--noFade &__fade {
        display: none;
    }

    /* sections */
    &__section {
        display: none;

        &--show {
            display: block;
        }

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(24px);
            line-height: rem(32px);
            color: lightness(--color-black, 20%);
            margin-bottom: rem(32px);
        }

        #{$root}__loadMore {
            @extend %dmSansFontMedium;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-semantic4);
            text-decoration: underline;
            cursor: pointer;
            display: block;
            width: fit-content;
            margin: rem(32px) auto 0;

            &--hidden {
                display: none;
            }
        }
    }

    &__section[data-article-type='Pagine'] {
        margin-top: rem(48px);

        @include respond-above(l) {
            margin-top: rem(60px);
        }
    }
    &__section[data-article-type='Pagine'] &__cards {
        display: flex;
        flex-flow: column;
        gap: rem(16px);

        @include respond-above(l) {
            gap: rem(24px);
        }
    }
    &__section[data-article-type='Pagine'] &__card {
        @include respond-above(l) {
            max-width: poc(693px, 944px);
        }
    }

    &__section[data-article-type='Consigli'] {
        margin-top: rem(40px);

        @include respond-above(l) {
            margin-top: rem(60px);
        }
    }
    &__section[data-article-type='Consigli'] &__cards {
        display: flex;
        flex-flow: column;
        gap: rem(16px);

        @include respond-above(m) {
            flex-flow: row wrap;
        }
    }
    &__section[data-article-type='Consigli'] &__card {
        @include respond-below(m) {
            width: 100%;
        }
        @include respond-between(m,l) {
            width: calc(50% - 8px);
        }
        @include respond-above(l) {
            width: calc(25% - 12px);
        }
    }

    &__section[data-article-type='Ricette'] {
        margin-top: rem(62px);

        @include respond-above(l) {
            margin-top: rem(60px);
        }
    }
    &__section[data-article-type='Ricette'] &__cards {
        display: flex;
        flex-flow: column;
        gap: rem(16px);

        @include respond-above(m) {
            flex-flow: row wrap;
        }
    }
    &__section[data-article-type='Ricette'] &__card {
        @include respond-below(m) {
            width: 100%;
        }
        @include respond-between(m,l) {
            width: calc(50% - 8px);
        }
        @include respond-above(l) {
            width: calc(#{math.div(100,3)} * 1% - #{math.div(32,3)} * 1px);
        }
    }

    &__section[data-article-type='News'] {
        margin-top: rem(60px);
    }
    &__section[data-article-type='News'] &__cards {
        display: flex;
        flex-flow: column;
        gap: rem(16px);

        @include respond-above(m) {
            flex-flow: row wrap;
        }
    }
    &__section[data-article-type='News'] &__card {
        @include respond-below(m) {
            width: 100%;
        }
        @include respond-between(m,l) {
            width: calc(50% - 8px);
        }
        @include respond-above(l) {
            width: calc(25% - 12px);
        }
    }
}
