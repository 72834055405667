@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt216-card-icon-text {
    display: flex;
    gap: rem(16px);

    &__icon {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        font-size: rem(48px);
        color: var(--color-primaryGray);
        padding: rem(20px);
        border-radius: 50%;
        background-color: alpha(--color-neutral, 10%);
        box-shadow: 0 1px 1px 0 alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }

    &__text {
        flex-grow: 1;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        padding-bottom: rem(8px);
    }

    &__textContent {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
    }
}