@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt121-slider-card-elev-contents {
    @extend %container;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include respond-above(l) {
        width: 100%;
    }

    &__slideContainer {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
        @include respond-above(l) {
            width: 32.8%;
        }
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        @include respond-above(l) {
            margin: 0 auto;
        }

        &--center {
            @include respond-above(l) {
                justify-content: center;
            }
        }
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(32px);

        @include respond-above(l) {
            display: flex;
        }

        &--hidden {
            @include respond-above(l) {
            display: none;
            }
        }
    }

    &__bullet {
        flex: 0 0 auto;
        height: rem(4px);
        width: rem(4px);
        border-radius: rem(9px);
        background-color: alpha(--color-darkCharcoal, 0.7);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem(14px);
        }

        &--active {
            height: rem(16px);
            width: rem(16px);
            background-color: var(--color-primary2);
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(32px);
    }

    /* default texts style */
    
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }
}
