@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt155-thankyou-simple-image-text {
    margin: 0 rem(16px);
    @extend %container;

    @include respond-above(l) {
        margin: 0 auto;
    }

    &__content {
        min-height: 344px;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(32px);
        line-height: rem(40px);
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(24px);
    }

    &__additionalText {
        margin-bottom: rem(30px);

        &--hidden {
            display: none;
        }
    }

    &__additionalText, &__text {
        font-size: rem(16px);
        line-height: rem(24px);
        color: #707070;
    }

    &__informativeContent {
        font-size: rem(14px);
        line-height: rem(18px);

        a {
            font-weight: 500;
            color: var(--color-semantic4);
            text-decoration: underline;
        }
    }

    &__discoverMoreText {
        @extend %dmSansFontBold;
    }
}
