@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt073-assicurazioni-form {
    @extend %container;

    &__submit[disabled] {
        @apply bg-[rgba(51,51,51,0.05)];
        @apply text-[rgba(51,51,51,0.2)];
    }
}
