@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt005-toggle {

    &__label {
        position: relative;
        cursor: pointer;
        width: 40px;
        height: 24px;
        border: 2px solid alpha(--color-darkCharcoal, 0.3);
        border-radius: 12px;
        transition: background-color .2s ease-in-out;
    }

    /* hover */
    &__label:hover {
        border: 2px solid var(--color-primary1);
    }
    &__label:hover &__checkbox::before {
        background-color: var(--color-primary1);
        background-image: none;
    }

    &__input {
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }

    &__checkbox {
        vertical-align: middle;

        &::before,
        &::after {
            display: inline-block;
            position: relative;
            left: 0;
            content: ' ';
            width: 16px;
            height: 16px;
            margin: 1px 2px 0.6px;
            transition: opacity .2s ease-in-out, left .2s ease-in-out;
        }
        &::before {
            opacity: 1;
            background-image: url('#{$resourcesFolder}/corporate/icons/untoggle.svg');
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
        &::after {
            opacity: 0;
            left: -18px;
            background-color: var(--color-neutral);
            background-image: url('#{$resourcesFolder}/corporate/icons/toggle-checked.svg');
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 50%;
        }
    }
    &__input:checked + &__checkbox::after {
        left: 0;
        opacity: 1;
    }
    &__input:checked + &__checkbox::before {
        left: 18px;
        opacity: 0;
    }

    /* disabled */
    &--disabled &__label {
        opacity: 0.5;
        pointer-events: none;
    }
    &--disabled &__checkbox::before,
    &--disabled &__checkbox::after {
        visibility: hidden;
    }

    /* checked */
    &--checked &__label {
        background-color: var(--color-primary1);
        border: 2px solid var(--color-primary1);
    }

    /* small */
    &--small &__label {
        display: inline-block;
        width: 28px;
        height: 16px;
    }
    &--small &__checkbox {
        display: block;
    }
    &--small &__checkbox::before,
    &--small &__checkbox::after {
        position: relative;
        top: -2.5px;
        width: 10px;
        height: 10px;
        margin: 0 1px 0;
        background-image: none;
    }
    &--small &__checkbox::before {
        background-color: alpha(--color-darkCharcoal, 0.2);
    }
    &--small &__checkbox::after {
        background-color: var(--color-neutral);
    }
}
