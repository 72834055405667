@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt033-video {
    position: relative;
    display: block;
    overflow: hidden;
    background-color: var(--color-black);
    display: flex;
    align-items: center;

    &--container {
        @extend %container;
    }

    &--roundEdges {
        background-color: unset;
    }
    &--roundEdges &__video {
        overflow: hidden;
        border-radius: rem(12px);
    }

    &__placeholder {
        width: 100%;
    }

    &__video {
        max-height: 100%;
    }

    &--embedded {
        padding: 0;
        margin: 0;
        max-width: 100%;
    }

    &--embedded::before {
        width: 100%;
        margin-left: 0;
    }
}
