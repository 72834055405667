@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt129-strillo-booking {
    --color-strillo-text: var(--color-darkCharcoal);
    background-color: var(--color-secondary1);
    opacity: 0;

    &--show {
        opacity: 1;
    }

    &__wrapper {
        @extend %container;
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        padding-top: rem(24px);
        padding-bottom: rem(24px);
        min-height: rem(220px);

        @include respond-above(l) {
            flex-flow: row;
            gap: unset;
            justify-content: space-between;
            align-items: center;
            padding-top: rem(36px);
            padding-top: rem(30px);
            min-height: rem(140px);
        }
    }

    &__wrapper > div:first-child {
        display: flex;
        align-items: center;
        gap: rem(11px);

        @include respond-above(l) {
            gap: rem(22px);
        }
    }
    
    &__icon {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        font-size: rem(40px);
        color: var(--color-strillo-text);
        padding: rem(20px);
        border-radius: 50%;
        background-color: var(--color-white);
    }

    &__subtitle {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-strillo-text);
        padding-bottom: rem(16px);

        @include respond-above(l) {
            padding-bottom: rem(4px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-strillo-text);
        padding-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-strillo-text);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &__downloadLink {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        margin-top: unset;
        color: var(--color-strillo-text);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
            margin-top: rem(16px);
        }
    }
    &__downloadLink:hover {
        color: var(--color-strillo-text);
    }

    &__link {
        width: 100%;
        text-align: center;
        color: #333333;
        margin-top: rem(14px);

        @include respond-above(l) {
            width: unset;
            text-align: unset;
            margin-top: unset;
        }
    }

    &__mobileOnly {
        @include respond-above(l) {
            display: none;
        }
    }

    &__mobileFullWidth {
       .rt129-strillo-booking__text {
            display: none;

            @include respond-above(l) {
                display: block;
            }

            &.rt129-strillo-booking__mobileOnly {
                display: block;
                margin-top: rem(8px);
                 @include respond-above(l) {
                     display: none;
                 }
            }
       }

       .rt129-strillo-booking__downloadLink {
           display: none;

           @include respond-above(l) {
               display: block;
           }

           &.rt129-strillo-booking__mobileOnly {
               display: block;
                @include respond-above(l) {
                    display: none;
                }
           }
       }
    }
}