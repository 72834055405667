@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt139-hero-collection {
    position: relative;

    &__bgImage {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0 0 0 0;
        max-width: map-get($map: $breakpoints, $key: xl);
        margin: 0 auto;
    }

    &__wrapper {
        position: relative;
        padding-top: rem(34px);
        padding-bottom: rem(43px);
        @extend %container;
    }

    &__text {
        max-width: rem(240px);

        @include respond-above(m) {
            max-width: rem(340px);
        }

        @include respond-above(l) {
            max-width: rem(440px);
        }
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-darkCharcoal);
        margin-top: rem(36px);

        @include respond-above(l) {
            font-size: rem(40px);
            line-height: rem(48px);
            margin-top: rem(46px);
        }
    }

    &__description {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        margin-top: rem(8px);

        @include respond-above(l) {
            margin-top: rem(16px);
        }
    }

    &__bottomText {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        margin-top: rem(28px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
    }

    &__logos {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: rem(22px);

        @include respond-above(l) {
            position: absolute;
            right: rem($container-wpad-desk);
            top: 0;
            height: 100%;
            flex-flow: column;
            gap: rem(200px);
        }
    }

    &__logos > div:first-child {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        gap: rem(22px);

        @include respond-above(l) {
            justify-content: space-between;
        }
    }

    &__logo1,
    &__logo1dsk,
    &__logo2,
    &__logo3 {
        max-width: rem(116px);
        height: auto;
    }

    &__logo1 {
        @include respond-above(l) {
            display: none;
        }
    }

    &__logo1dsk {
        display: none;
        position: absolute;
        top: 0;
        left: calc(50% - 58px);
        
        @include respond-above(l) {
            display: block;
        }
    }
}
