@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.ru004-loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: setZ(popup) + 5;
    width: 100%;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    &--show {
        display: flex;
    }

    &__backdrop {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: alpha(--color-black, 0.6);
    }

    &__loader {
        display: inline-block;
        position: relative;
        width: rem(80px);
        height: rem(80px);
        background-image: url('#{$resourcesFolder}/corporate/icons/loader.gif');
        background-repeat: no-repeat;
        background-size: rem(80px);
    }
}
