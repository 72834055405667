@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt061-anchors {
    display: flex;
    flex-flow: column;
    gap: rem(16px);
    
    &__anchor {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(16px);
        display: inline;
    }
}
