@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt031-filter {
    
    &__btn {
        @extend %dmSansFontMedium;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 56px;
        font-size: rem(17px);
        line-height: rem(17px);
        color: lightness(--color-black, 20%);
        padding: rem(18.5px) rem(16px);
        background-color: var(--color-white);
        border-radius: 16px;
        border-width: 1px;
        border: 1px solid alpha(--color-black, 30%);
    }
    &__btn:focus {
        border: 1px solid alpha(--color-black, 30%);
    }
    &.open &__btn,
    &.open &__btn:focus {
        border: 1px solid lightness(--color-black, 20%);
    }

    &__btn > &__icon {
        font-size: rem(24px);
        margin-left: rem(10px);
        transition: transform .2s ease-in-out;
    }
    &.open &__btn > &__icon {
        transform: rotateX(180deg);
    }

    &__count {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-white);
        display: none;
        width: rem(24px);
        height: rem(24px);
        background-color: var(--color-primary1);
        border-radius: 50%;
        padding: rem(6px) 0;
        text-align: center;
        margin-left: rem(10px);
    }

    &--showCount &__btn &__icon {
        display: none;
    }
    &--showCount &__count {
        display: block;
    }
}
