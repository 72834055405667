@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt113-italy-nav {
    width: 100%;

    &--container {
        @extend %container;
    }
    
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(24px);

        @include respond-above(l) {
            margin-bottom: rem(16px);
        }
    }

    &__map.leaflet-container {
        background-color: var(--color-white);
        width: 100%;
        height: 500px;

        @include respond-above(l) {
            height: 600px;
        }
    }

    /* custom class for leaflet popups */
    &__popup {

        .leaflet-popup-content-wrapper {
            box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 
                        0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        }
        
        .leaflet-popup-tip-container {
            display: none;
        }
    }

    &__popupTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }

    &__popupText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        margin-top: rem(4px);
    }
}
