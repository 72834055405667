@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner-hsf {
    $root: &;
    width: 100%;
    
    &__title {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        display: flex;
        align-items: center;
        margin-bottom: rem(24px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }

        & > div:first-child {
            flex-shrink: 0;
        }
    }

    &__prev,
    &__next {
        display: none;
        font-size: rem(16px);
        cursor: pointer;

        @include respond-above(l) {
            display: inline;
        }

        &--disabled {
            cursor: default;
            pointer-events: none;
            opacity: 0.5;
        }
    }
    &__prev {
        margin-left: auto;
    }
    &__next {
        margin-left: rem(16px);
    }

    &__flyers {
        @include respond-below(l) {
            display: flex;
            flex-flow: column;
        }

        @include respond-above(l) {
            overflow: hidden;
        }
    }

    &__flyer {
        @include respond-below(l) {
            /* !important needed to override style attribute put by Swiper */
            margin-right: 0 !important;
        }
    }
    
    #{$root}__flyer--hidden {
        @include respond-below(l) {
            display: none;
        }
    }

    &__carousel {
        width: 100%;
    }

    &__track {
        display: flex;
        flex-flow: row;
        width: 100%;
        
        @include respond-below(l) {
            gap: rem(16px);
            flex-flow: column;
        }
    }

    &__scrollbarContainer {
        display: none;
        pointer-events: none;

        @include respond-above(l) {
            display: flex;
            pointer-events: all;
            margin-top: rem(30px);
        }
    }

    &__scrollbar {
        width: 100%;
        height: rem(4px);
        border-radius: 10px;
        background: alpha(--color-white, 0.2);

        @include respond-above(l) {
            margin-left: rem(145px);
        }
    }

    &__scrollbarDrag {
        height: 100%;
        width: 100%;
        position: relative;
        background: var(--color-white);
        border-radius: 10px;
        left: 0;
        top: 0;
        cursor: pointer;

        /*&:hover,
        &:active {
            top: rem(-1.5px);
            height: calc(100% + #{rem(3px)});
            bottom: rem(-1.5px);
        }*/
    }

    &__loadMore {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        margin-top: rem(30px);
        align-self: center;

        @include respond-above(l) {
            display: none;
        }
    }
    &__loadMore:hover {
        color: var(--color-banner-text);
    }
    &__loadMore--hidden {
        display: none;
    }

    &--noSwipe {
        @include respond-above(l) {
            #{$root}__prev,
            #{$root}__next,
            #{$root}__scrollbarContainer {
                display: none;
            }

            #{$root}__track {
                gap: rem(16px);
            }
        }
    }
}
