@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt122-slider-card-text {
    @extend %container;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include respond-above(l) {
        width: 100%;
    }

    &__slideContainer {
        display: flex;
        flex-shrink: 0;
        transition-property: transform;
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        @include respond-above(l) {
            margin: 0 auto;
        }

    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(24px);

        @include respond-above(l) {
            display: none;
        }

        &--hidden {
            @include respond-above(l) {
            display: none;
            }
        }
    }

    &__bullet {
        position: relative;
        flex: 0 0 auto;
        height: rem(16px);
        width: rem(16px);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem(14px);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: rem(4px);
            width: rem(4px);
            background-color: var(--color-black);
            border-radius: 50%;
        }

        &--active::after {
            height: rem(16px);
            width: rem(16px);
            background-color: var(--color-primary2);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(28px);
            line-height: rem(36px);
        }
    }

    &__read {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: rem(32px);
        cursor: pointer;
    }

    &__readLabel {
        @extend %dmSansFontMedium;
        text-decoration: none;
        margin-right: rem(8px);
        text-decoration: underline;
    }

    &__readIcon {
        font-weight: 600;
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(32px);
    }

    &__cta {
        align-self: center;
        margin-top: rem(16px);
    }
}
