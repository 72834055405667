@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt013-step {
    width: rem(24px);
    height: rem(8px);
    border-radius: rem(100px);
    outline-width: 2px;
    outline-offset: -2px;
    outline-style: solid;
    outline-color: var(--color-primary2);
    background-color: transparent;

    &--selected,
    &--filled {
        outline: none;
        background-color: var(--color-primary2);
    }

    &--filled {
        opacity: 0.2;
    }
}
