@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt118-slider-card-elev-editorial {
    @extend %container;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include respond-above(l) {
        width: 100%;
    }

    &__slideContainer {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;

        @include respond-above(l) {
            width: 32.2%;
        }
    }

    &__slideContainer[data-variant='small'] {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
        @include respond-above(l) {
            width: 23.8%;
        }
    }

    &__slideContainer[data-variant='large'] {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
        width: 100%;
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        @include respond-above(l) {
            margin: 0 auto;
        }
    }

    &__pagination {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include respond-above(l) {
            display: flex;
        }

        &--hidden {
            display: none;
        }
    }
        /* default texts style */
    
        &__title {
            @extend %dmSansFontBold;
            font-size: rem(24px);
            line-height: rem(32px);
            color: var(--color-darkCharcoal);
    
            @include respond-above(l) {
                font-size: rem(32px);
                line-height: rem(40px);
            }
        }
    
        &__separator {
            @extend %separator;
            margin-top: rem(24px);
            margin-bottom: rem(24px);
        }

}
