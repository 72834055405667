@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt035-tooltip {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    max-width: rem(279px);
    width: 100%;
    padding-top: rem(6px);
    padding-bottom: rem(6px);
    padding-left: rem(12px);
    padding-right: rem(12px);
    background-color: var(--color-semantic4);
    box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 
                0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    border-radius: 6px;
    z-index: setZ(default);

    &--show {
        visibility: visible;
        pointer-events: all;
    }

    &__text {
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-white);
    }
}
