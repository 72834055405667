@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt203-card-elev-contents {
    border-radius: rem(12px);
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    width: rem(254px);
    min-height: rem(368px);

    @include respond-above(l) {
        width:100%;
        min-height: rem(376px);
    }

    &__image.rt022-picture--cover {
        border-top-left-radius: rem(12px);
        border-top-right-radius: rem(12px);
        height: rem(160px);
    }

    &__info {
        padding: rem(16px);
        display: flex;
        flex-direction: column;
        flex: auto;
        justify-content: space-between;

        @include respond-above(l) {
            padding: rem(24px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);
        margin-bottom: rem(8px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        font-weight: 400;
        color: var(--color-primaryGray);
        line-height: rem(20px);
        margin-bottom: rem(20px);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__bottom {
        display: flex;
        justify-content: flex-end;
    }
}
