@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt209-card-product-mdd {
    $root: &;
    border-radius: rem(12px);
    width: rem(309px);
    background-color: var(--color-white);
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    @include respond-above(l) {
        width: rem(224px);
        min-height: rem(288px);
    }

    &__container {
        padding: rem(16px);
        display: flex;
        flex-direction: column;
    }

    &__mdd {
        margin-bottom: rem(28px);
    }

    &__bottom {
        @include respond-below(l) {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &__imageCont {
        display: flex;
        justify-content: center;
        margin-bottom: rem(34px);
    }

    &__image {
        max-width: rem(113px);
        max-height: rem(90px);
        justify-self: center;

        @include respond-above(l) {
            max-width: rem(192px);
            max-height: rem(119px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
    }

    &--background {
        border-radius: rem(12px);
        width: rem(164px);
        min-height: rem(269px);
        flex-direction: column;

        @include respond-above(l) {
            width: rem(224px);
            min-height: rem(390px);
        }

        #{$root}__container {
            padding: 0;
        }

        #{$root}__bottom {
            flex-direction: column;
        }

        #{$root}__imageCont {
            display: flex;
            align-items: center;
            border-top-left-radius: rem(12px);
            border-top-right-radius: rem(12px);
            max-width: 100%;
            max-height: 100%;
            height: rem(177px);
            margin-bottom: 0;
            width: 100%;

            @include respond-above(m) {
                height: rem(285px);
            }
        }

        #{$root}__image {
            position: static;
            max-width: 100%;
            max-height: 100%;
        }

        #{$root}__title {
            position: static;
            padding: rem(16px);

            @include respond-above(l) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }
    }
}
