@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt159-strillo-icon-text-link {
    background-color: var(--color-secondary1);

    &__wrapper {
        @extend %container;
        display: flex;
        flex-flow: column;
        gap: rem(24px);
        padding-top: rem(24px);
        padding-bottom: rem(24px);

        @include respond-above(l) {
            flex-flow: row;
            gap: unset;
            justify-content: space-between;
            padding-top: rem(30px);
            padding-bottom: rem(30px);
            padding-right: rem(99.5px);
            padding-left: rem(40px);
        }
    }

    &__wrapper > div:first-child {
        display: flex;
        gap: rem(11px);

        @include respond-above(l) {
            gap: rem(22px);
        }
    }

    &__iconGuest, &__iconLight, &__iconLogged, &__iconPolicyAlreadyActive {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        align-self: center;
        font-size: rem(40px);
        color: var(--color-darkCharcoal);
        padding: rem(20px);
        border-radius: 50%;
        background-color: var(--color-white);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-white);
        padding-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-white);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &__link {
        align-self: flex-start;
        width: 100%;
        text-align: center;

        @include respond-above(l) {
            width: unset;
            text-align: unset;
            align-self: center;
        }
    }
}
