@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner-hmd {
    @include respond-above(l) {
        padding-top: rem(19px);
        padding-bottom: rem(19px);
    }
    
    &__findStoreTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(28px);
            line-height: rem(30px);
            margin-bottom: rem(32px);
        }
    }

    &__findStoreText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-banner-text);

        a {
            @extend %dmSansFontBold;
            text-decoration: underline;
        }
        a:hover {
            color: var(--color-banner-text);
        }
    }
}
