@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt144-slider-label-mdd {
    @extend %container;

    &__slider {
        display: flex;
        flex-wrap: nowrap;
        gap: rem(16px);
        overflow-x: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
        }
    }

    &__labelMdd {
        flex-shrink: 0;
    }
}
