@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='conad'],
body.theme-conad {
    @include defineColorsHexMap(map.merge($default-theme, $conad-theme));
}
