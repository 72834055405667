@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt253-store-locator-filters {
    @include modal(&, true);

    h3#{&}__title {
        font-size: rem(28px);
        line-height: rem(30px);
    }

    &__wrapper {
        margin-bottom: rem(31px);

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            width: 4px;
            background-color: alpha(--color-black, 0.2);
            margin-top: 0;
            margin-bottom: 0;
            margin-right: rem(52px);
            opacity: 0.6;
            border-radius: 2px;
            visibility: hidden;
            pointer-events: none;

            @include respond-above(l) {
                visibility: visible;
                pointer-events: all;
            }

            & > .ps__thumb-y {
                width: 4px;
                right: 0;
                background-color: var(--color-black);
                border-radius: 2px;
            }
        }
    }

    &__form {
        flex-grow: 1;
        position: relative;
        overflow: hidden;

        .f-hidden {
            display: none;
        }
    }
    &__form > div:first-child {
        margin-top: -8px;
    }

    &__filtersAccordionInsignia,
    &__filtersAccordionDepartment,
    &__filtersAccordionService {
        padding-left: unset;
        padding-right: unset;

        &.acc-hidden {
            display: none;
        }
    }

    &__filtersInsignia {
        display: flex;
        flex-flow: row wrap;
        gap: rem(16px);
        padding-left: 3px;
        padding-right: 1px;

        /* children */
        & > label {
            width: calc(50% - 8px);
        }
    }

    &__filtersDepartment,
    &__filtersService {
        display: flex;
        flex-direction: column;
        gap: rem(16px);

        @include respond-above(l) {
            flex-flow: column;
        }
    }

    &__filterCheckbox {
        @include respond-below(l) {
            width: 100%;
        }
    }

    &__insigniaSpecializedTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-top: rem(40px);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }

        &--noMarginTop {
            margin-top: 0;
        }
    }

    &__bottomBar {
        flex-shrink: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: rem(16px);
    }

    .rt002-cta-secondary--disabled {
        border: 2px solid alpha(--color-darkCharcoal, 0.2);
    } 
}
