@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt142-container-card-premi {
    @extend %container;
    &__track {
        display: grid;
        grid-template-columns: auto [col-start] 50% [col2-start] 50% auto;

        @include respond-below(l) {
            display: flex;
            justify-content: center;
            gap: rem(16px);
            flex-direction: column;
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(24px);
        margin-bottom: rem(24px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            line-height: rem(38px);
        }
    }

    &__slide {
        display: flex;
        grid-column: col-start;
        margin-bottom: rem(16px);

        @include respond-below(l) {
            display: flex;
            justify-content: center;
            margin-bottom: 0;
        }

        &:nth-child(2n + 1) {
            grid-column: col-start;
        }

        &:nth-child(2n + 2) {
            grid-column: col2-start;

            @include respond-above(l) {
                margin-left: rem(16px);
            }
        }
    }
}
