@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt100-hero {
    position: relative;
    $root: &;

    /* default texts style */
    &__title {
        @extend %robotoFont;
        font-size: rem(32px);
        line-height: rem(40px);
        font-weight: 500;
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(48px);
            line-height: rem(56px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        color: var(--color-primaryGray);
        font-size: rem(16px);
        line-height: rem(24px);
        margin-bottom: rem(54px);
        
        @include respond-above(l) {
            margin-bottom: 0;
        }
    }

    // Left section
    &__left {
        width: 100%;
        @extend %container;
        flex: 1 0 auto;

        @include respond-above(l) {
            flex: 0 0 auto;
            width: 42.374%;
            padding: 0;
            margin: 0;
            margin-right: rem(80px);
        }
    }

    &__title {
        margin-bottom: rem(16px);
        max-width: 73%;

        @include respond-above(l) {
            max-width: 100%;
        }
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(24px);
        border-radius: rem(4px);
    }

    &__cta {
        margin-bottom: rem(46px);
    }

    &__logoVb {
        display: none;
    }

    &__logoVc {
        display: none;
        align-items: center;
        position: absolute;
        right: rem(24px);
        width: fit-content;
        min-height: rem(102px);
        bottom: 0;
        background-color: var(--color-white);
        border-radius: rem(12px);
        border: solid 1px lightness(--color-black, 90%);
    }

    &__icon {
        display: none;
    }

    // right section
    &__right {
        position: relative;
        flex: 0 1 auto;

        @include respond-above(l) {
            flex: 1 1 auto;
            height: 100%;
        }
    }

    &__flower {
        display: none;
    }

    /* article related classes */
    &__tip,
    &__articleTitle,
    &__articleAbstract,
    &__mdd {
        display: none;
    }
    
    &__ctaBookmark {
        display: none;
        position: absolute;
        right: rem(16px);
        top: rem(16px);
        color: var(--color-bookmark);
    }

    &__image.rt022-picture--cover {
        height: rem(208px);

        @include respond-above(m) {
            height: rem(352px);
            border-radius: rem(12px);
        }
    }

    &__summary {
        display: none;
    }

    // Slider

    &__carousel {
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
    }

    &__slideContainer {
        width: 100%;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
    }

    &__slide {
        @extend %container;
        display: flex;
        align-items: stretch;
        height: 100%;

        @include respond-below(l) {
            flex-direction: column;
            padding: 0;
        }
    }

    &__track {
        width: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box; 

        &--invisible {
            visibility: hidden; 
        }
    }

    &__pagination {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: rem(24px);
        margin-left: rem(36px);

        @include respond-above(l) {
            margin-left: 0;
        }

        &--hidden {
            display: none;
        }
    }

    &__bullet {
        position: relative;
        flex: 0 0 auto;
        height: rem(16px);
        width: rem(16px);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem(14px);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: rem(4px);
            width: rem(4px);
            background-color: var(--color-black);
            border-radius: 50%;
        }

        &--active::after {
            height: rem(16px);
            width: rem(16px);
            background-color: var(--color-primary2);
        }
    }

    ////////////// A variant
    // standard
    &__slide--a {
        padding-bottom: rem(40px);
        @include respond-above(l) {
            padding-bottom: 0;
        }

        #{$root}__left {
            display: flex;
            flex-direction: column;
        }
        #{$root}__text {
            flex: 1 1 auto;
        }
    }

    ////////////// B variant
    &__slide--b {
        @include respond-above(l) {
            align-items: center;
            justify-content: space-between;
        }

        #{$root}__left {
            @include respond-above(l) {
                flex: 1 1 auto;
            }
        }

        #{$root}__rCont {
            position: relative;
        }

        #{$root}__right {
            padding-left: $container-wpad;
            padding-right: $container-wpad;

            @include respond-above(l) {
                padding: 0;
                margin-right: rem(79px);
                flex: 0 1 auto;
            }
        }

        #{$root}__image.rt022-picture--cover {
            width: rem(280px);
            height: rem(280px);
            border-radius: 50%;

            @include respond-above(m) {
                width: rem(384px);
                height: rem(384px);
            }
        }

        #{$root}__flower {
            display: block;
            position: absolute;
            left: rem(212px);
            top: 0;
            width: rem(139px);
            height: rem(151px);

            @include respond-above(m) {
                width: rem(193px);
                height: rem(209px);
                left: rem(271px);
            }
        }

        #{$root}__logoVb {
            display: block;
            object-fit: contain;
        }

        #{$root}__logoVc {
            display: none;
        }

        #{$root}__logoImg {
            max-width: rem(124px);
        }
    }

    ////////////// C variant
    &__slide--c {

        #{$root}__left {
            @include respond-above(l) {
                width: 42.374%;
            }
        }

        #{$root}__flower {
            display: block;
            position: absolute;
            right: rem(7px);
            top: rem(-45px);
            width: rem(82px);
            height: rem(91px);

            @include respond-above(l) {
                top: rem(100px);
                left: rem(-68px);
                width: rem(137px);
                height: rem(152px);
            }
        }

        #{$root}__right {
            padding-bottom: rem(51px);

            @include respond-above(l) {
                padding-bottom: rem(67px);
            }
        }

        #{$root}__logoVb {
            display: none;
        }

        #{$root}__logoVc {
            display: flex;
        }

        #{$root}__logoImg {
            max-height: calc(100%);
            object-fit: contain;
            padding: 0 rem(27px);
        }
    }

    ////////////// D variant
    &__slide--d {
        #{$root}__right {
            padding-bottom: rem(90px);

            @include respond-above(l) {
                padding-bottom: rem(80px);
            }
        }

        #{$root}__icon {
            display: block;
            color: var(--color-primary1);
            margin-top: rem(38px);
            font-size: rem(35px);
            width: rem(40px);
            height: rem(40px);
        }

        #{$root}__mdd {
            display: block;
            position: absolute;
            left: rem(12px);
            top: rem(11px);

            @include respond-above(l) {
                left: rem(24px);
                top: rem(16px);
            }
        }

        #{$root}__ctaBookmark {
            display: block;
        }

        #{$root}__logoVc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: rem(304px);
            padding: rem(16px);

            @include respond-above(l) {
                right: rem(80px);
            }
        }

        #{$root}__logoImg {
            display: none;
        }

        #{$root}__tip {
            display: block;
            @extend %dmSansFontBold;
            color: var(--color-bookmark);
            font-size: rem(12px);
            font-weight: 500;
            line-height: rem(12px);
            padding-bottom: rem(16px);
        }

        #{$root}__articleTitle {
            display: block;
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            padding-bottom: rem(8px);
        }

        #{$root}__articleAbstract {
            display: block;
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
            padding-bottom: rem(16px);
            font-weight: 500;
            color: var(--color-primaryGray);
        }
    }

    ////////////// E variant
    &__slide--e {

        #{$root}__left {
            @include respond-above(l) {
                width: 33.9%;
            }
        }

        #{$root}__right {
            padding-bottom: rem(90px);

            @include respond-above(l) {
                padding-bottom: rem(80px);
            }
        }

        #{$root}__icon {
            display: block;
            color: var(--color-primary2);
            margin-top: rem(34px);
            font-size: rem(35px);
            width: rem(40px);
            height: rem(40px);
            margin-bottom: rem(70px);

            @include respond-below(l) {
                margin-bottom: rem(16px);
            }
        }

        #{$root}__mdd {
            display: block;
            position: absolute;
            left: rem(12px);
            top: rem(11px);

            @include respond-above(l) {
                left: rem(24px);
                top: rem(16px);
            }
        }

        #{$root}__ctaBookmark {
            display: block;
        }

        #{$root}__logoVc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: rem(304px);
            padding: rem(16px);

            @include respond-above(l) {
                right: rem(80px);
            }
        }

        #{$root}__logoImg {
            display: none;
        }

        #{$root}__tip {
            display: block;
            @extend %dmSansFontBold;
            color: var(--color-bookmark);
            font-size: rem(12px);
            font-weight: 500;
            line-height: rem(12px);
            padding-bottom: rem(16px);
        }

        #{$root}__articleTitle {
            display: block;
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            padding-bottom: rem(8px);
        }

        #{$root}__summary {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        #{$root}__sumIcon, #{$root}__sumIconChef {
            font-size: rem(16px);
            margin-right: rem(2px);
            color: var(--color-primaryGray);
            &:first-child {
                margin-right: 0;
            }

            &--desel {
                color: var(--color-grayscale10);
            }
        }

        #{$root}__sumLabel, #{$root}__levelLabel {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            margin-left: rem(6px);
            color: var(--color-primaryGray);
        }

        #{$root}__preparationTime {
            margin-right: rem(23px);
        }
        #{$root}__articleTitle {
            margin-top: rem(16px);
        }

        #{$root}__articleAbstract {
            display: none;
        }
    }
}
