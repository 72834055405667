@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt108-select-store {
    &__wrapper {
        display: flex;
        cursor: pointer;
    }
    &__storeWrapper {
        display: none;
    }
    &__wrapper,
    &__storeWrapper {
        background-color: var(--color-white);
        border-radius: rem(12px);
        border-width: 1px;
        border-style: dotted;
        border-color: alpha(--color-darkCharcoal, 0.3);
        min-height: rem(88px);

        @include respond-above(l) {
            min-height: rem(96px);
        }
    }
    &__wrapper:hover {
        background-color: alpha(--color-white, 90%);
    }

    &--store &__wrapper {
        display: none;
    }
    &--store &__storeWrapper {
        display: block;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-darkGray);
        margin: auto;
        display: flex;
        align-items: center;

        & > span:first-of-type {
            font-size: rem(16px);
            line-height: rem(24px);
            margin-left: rem(4px);
        }
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        padding-top: 3px;
        display: none;
        user-select: none;
        padding-left: rem(16px);
    }

    &--errored &__wrapper {
        border-color: var(--color-semantic2);
    }

    &--errored &__error {
        display: block;
    }
}
