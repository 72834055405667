@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt052-store-locator {
    $root: &;
    $pad-top: rem(24px);
    $pad-right: rem(16px);
    $pad-bottom: rem(32px);
    $pad-left: rem(40px);
    $pad-results: 7px;
    position: relative;

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: rem(396px);
        background-color: var(--color-neutral);

        @include respond-above(l) {
            height: rem(336px);
        }
    }

    &__bg#{$root}__bg--high {
        height: rem(468px);

        @include respond-above(l) {
            height: rem(408px);
        }
    }
    &__bg#{$root}__bg--filters {
        height: rem(72px);
        box-shadow: 0 -2px 6px 0 alpha(--color-darkCharcoal, 0.15);
        background-color: var(--white);
    }

    &__container {
        @extend %leftMarginContainer;
        @include respond-below(l) {
            padding-left: 0;
        }
    }

    &__searchAlert {
        display: none;
    }

    &__wrapper {
        background-color: var(--color-white);
        width: 100%;
        height: rem(600px);
        display: flex;
        overflow-x: hidden;

        @include respond-below(l) {
            height: rem(650px);
        }
    }

    &__sidePane {
        height: 100%;
        flex: 440 0 0;
        display: flex;
        flex-flow: column;
        position: relative;
        overflow: hidden;

        @include respond-above(l) {
            min-width: rem(375px);
            max-width: rem(440px);
            overflow: visible;
        }
    }

    &__inputs {
        padding-top: $pad-top;
        padding-right: $pad-right;
        padding-bottom: rem(16px);
        padding-left: rem($container-wpad);

        @include respond-above(l) {
            padding-left: rem(24px); //calc(#{$pad-left} - #{rem(16px)});
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin: rem(8px) 0 rem(24px);
    }

    &__filters {
        display: flex;
        align-items: center;
        position: relative;
        margin: rem(16px) 0 rem(12px);
        &--hidden {
            display: none;
        }
    }

    &__openFiltersPane {
        width: rem(30px);
        display: flex;
        flex-flow: column;
        gap: rem(8px);
        margin-right: rem(24px);
        cursor: pointer;

        & > span:first-child {
            font-size: rem(24px);
            margin-left: 5px;
        }

        & > span:last-child {
            @extend %dmSansFontBold;
            font-size: rem(12px);
            line-height: rem(12px);
            color: lightness(--color-black, 20%);
            white-space: nowrap;
            width: rem(60px);
        }
    }
    &__openFiltersPane:focus-visible {
        @include outlineFocusVisible();
    }

    &__filtersOpening {
        flex-grow: 1;
        display: flex;
        gap: rem(16px);
        align-items: center;
        overflow: hidden;
        position: relative;
        padding: rem(5px);

        /* hide scrollbar */
        &.ps > .ps__rail-x {
            display: none;
        }
    }

    &__filterOpening:nth-last-child(3) {
        margin-right: rem(28px);
    }

    &__results {
        flex-grow: 1;
        padding-top: $pad-top;
        padding-right: rem(9px); //calc(#{$pad-right} - #{$pad-results});
        padding-bottom: $pad-bottom;
        background-color: var(--color-neutral);
        overflow-y: hidden;
        overflow-x: visible;
        position: relative;
        padding-left: rem($container-wpad);

        @include respond-above(l) {
            padding-left: rem(24px); //calc(#{$pad-left} - #{$pad-results});
            padding-right: rem(16px);
        }
    }

    &__searchEmpty {
        display: none;
    }

    &__searchEmptyImage {
        width: rem(104px);
        height: rem(104px);
        background-image: url('#{$resourcesFolder}/corporate/icons/lens.svg');
        background-repeat: no-repeat;
        background-size: rem(104px);
        margin: rem(28px) auto 0 auto;
    }

    &__searchEmptyText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        max-width: rem(273px);
        text-align: center;
        margin: rem(32px) auto 0 auto;
    }

    &__noRes {
        display: none;
    }

    &__noResImage {
        width: rem(136px);
        height: rem(147px);
        background-image: url('#{$resourcesFolder}/corporate/icons/lens-alert.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-bottom: rem(20px);
    }

    &__noResTitle {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }

    &__noResText {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(8px);
    }

    &__res {
        height: calc(100% + #{$pad-bottom});
    }

    &__resHeading {
        display: flex;
        justify-content: space-between;
        padding-bottom: calc(#{rem(20px)} - #{$pad-results} - 5px);
    }

    &__resHeadingLabel {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
    }

    &__resHeadingFound {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primary1);
    }

    &__resList {
        position: relative;
        height: calc(100% - #{$pad-bottom} - #{rem(12px)});
        padding-left: $pad-results;
        padding-right: $pad-results;
        padding-top: $pad-results;

        @include respond-above(l) {
            padding-right: calc(#{rem(26px)} - #{$pad-results});
        }

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            width: 4px;
            background-color: alpha(--color-black, 0.2);
            margin-top: rem(8px);
            margin-bottom: rem(16px);
            opacity: 0.6;
            border-radius: 2px;

            & > .ps__thumb-y {
                width: 4px;
                right: 0;
                background-color: var(--color-black);
                border-radius: 2px;
            }
        }
    }

    /* extra class added to the result cards */
    &__resItem {
        margin-bottom: rem(16px);
    }
    &__resItem:first-child {
        margin-top: rem(8px);
    }

    &__mapDsk {
        flex: 584 0 0;
        height: 100%;

        @include respond-below(l) {
            display: none;
        }
    }

    &__mapMob {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
    }

    &--showMapMob {
        @include respond-below(l) {
            #{$root}__mapMob {
                display: block;
            }
            #{$root}__res {
                display: none;
            }
            #{$root}__showList {
                display: flex;
            }
            #{$root}__showMap {
                display: none;
            }

            #{$root}__paneFade {
                display: none;
            }
        }
    }

    &__map {
        width: 100%;
        height: 100%;
    }
    & > &__map {
        display: none;
    }

    &__showMap,
    &__showList {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: var(--color-white);
        align-items: center;
        position: absolute;
        bottom: rem(21px);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        height: rem(56px);
        padding-left: rem(32px);
        padding-right: rem(32px);
        background-color: lightness(--color-black, 20%);
        border-radius: rem(12px);
        box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        cursor: pointer;

        & > span:first-child {
            margin-right: rem(8px);
        }
    }
    &__showMap {
        display: flex;

        @include respond-above(l) {
            display: none;
        }
    }
    &__showMap--hidden {
        @include respond-below(l) {
            display: none;
        }
    }
    &__showList {
        display: none;

        @include respond-above(l) {
            display: none;
        }
    }

    &__filtersPane {
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        pointer-events: none;
        visibility: hidden;
        background-color: var(--color-white);
        display: flex;
        flex-flow: column;
        transition: left 0.2s ease-in-out, visibility 0.2s ease-in-out;
    }
    &--filtersOpen &__filtersPane {
        left: 0;
        pointer-events: all;
        visibility: visible;
    }

    &__closeFiltersPane {
        @extend %ecIcon;
        font-size: rem(24px);
        position: absolute;
        top: rem(21px);
        right: rem(21px);
        cursor: pointer;

        @include respond-above(l) {
            top: rem(40px);
            right: rem(16px);
        }

        &::before {
            content: $ec-icon-close;
        }
    }
    &__closeFiltersPane:focus-visible {
        @include outlineFocusVisible();
    }

    &__filtersTitle {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: lightness(--color-black, 20%);
        padding-top: rem(52px);
        padding-left: rem(16px);
        padding-right: rem(16px);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
            padding-top: rem(32px);
            padding-left: rem(40px);
            padding-right: rem(57px);
        }
    }

    &__filtersAccordionInsignia,
    &__filtersAccordionDepartment,
    &__filtersAccordionService {
        &.acc-hidden {
            display: none;
        }
    }

    &__form {
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        margin-top: rem(24px);

        @include respond-above(l) {
            padding-left: rem(24px);
            padding-right: rem(24px);
        }

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            display: none;
        }
        /* scrollbar visible */
        /*&.ps > .ps__rail-y {
            right: rem(16px) !important;
            width: 4px;
            background-color: alpha(--color-black, 0.2);
            margin-top: rem(8px);
            margin-bottom: rem(16px);
            opacity: 0.6;
            border-radius: 2px;

            & > .ps__thumb-y {
                width: 4px;
                right: 0;
                background-color: var(--color-black);
                border-radius: 2px;
            }
        }*/

        .f-hidden {
            display: none;
        }
    }

    &__filtersInsignia {
        display: flex;
        flex-flow: row wrap;
        gap: rem(16px);
        padding-left: 3px;
        padding-right: 1px;
    }

    &__filtersDepartment,
    &__filtersService {
        display: flex;
        flex-direction: column;
        gap: rem(16px);

        @include respond-above(l) {
            flex-flow: column;
        }
    }

    &__filterCheckbox {
        @include respond-below(l) {
            width: 100%;
        }
    }

    &__insigniaSpecializedTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-top: rem(40px);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }

        &--noMarginTop {
            margin-top: 0;
        }
    }

    &__ctas {
        flex-shrink: 0;
        z-index: setZ(default);
        height: rem(72px);
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        gap: rem(16px);
        padding-left: rem(16px);
        padding-right: rem(16px);
        box-shadow: 0 -2px 6px 0 alpha(--color-darkCharcoal, 0.15);
    }

    &--noRes {

        #{$root}__searchEmpty {
            display: none;
        }
        #{$root}__noRes {
            display: block;
        }
        #{$root}__res {
            display: none;
        }
    }

    &--searchEmpty {

        #{$root}__searchEmpty {
            display: block;
        }
        #{$root}__noRes {
            display: none;
        }
        #{$root}__res {
            display: none;
        }
    }

    &--preFiltered {

        #{$root}__searchAlert {
            display: flex;
        }
    }

    .rt002-cta-secondary--disabled {
        border: 2px solid alpha(--color-darkCharcoal, 0.2);
    }

    &__fade {
        width: rem(32px);
        height: rem(32px);
        position: absolute;
        top: 5px;
        right: 0;
        background: linear-gradient(to right, #{alpha(--color-white, 0.2)}, #{alpha(--color-white, 0.8)});
    }

    &__paneFade {
        display: block;
        width: 100%;
        max-width: calc(#{rem($container-wmax)} + #{rem($container-wpad-desk)} * 2);
        height: rem(32px);
        position: absolute;
        bottom: 0;
        background: linear-gradient(to bottom, #{alpha(--color-white, 0.1)}, #{alpha(--color-white, 0.9)});

        &--hidden {
            display: none;
        }

        @include respond-below(l) {
            height: rem(23px);
        }
    }
}
