@use '../modules/variables' as *;
@use '../modules/functions' as *;

//
// GOOGLE AUTOCOMPLETE
//----------------------------------------------------
[class*='pac-container'] {
    z-index: setZ(default);
}

[class*='pac-container'] {
    background-color: #333;
    border-radius: 16px;
    margin-top: 4px;
}

[class*='pac-container'] .pac-item,
[class*='pac-container'] .pac-item-query {
    font-size: 17px;
}

[class*='pac-container'] .pac-item {
    border: none;
    padding: 16px;
    cursor: pointer;
}

[class*='pac-container'] .pac-item .pac-item-query {
    color: #fff;
}

[class*='pac-container'] .pac-item .pac-icon {
    display: none;
    font-size: 24px;
    vertical-align: -4px;
    margin-right: 5px;
}

[class*='pac-container'] .pac-item-selected,
[class*='pac-container'] .pac-item:hover,
[class*='pac-container'] .pac-item:first-child {
    background-color: #191919;
}

[class*='pac-container'] .pac-item-selected .pac-icon {
    display: inline-block;
    float: right;
}

[class*='pac-container'].pac-logo:after {
    background-image: url('#{$resourcesFolder}/corporate/icons/powered_by_google_on_non_white_hdpi.png') ;
    height: 24px ;
    position: relative;
    top: -2px;
    right: 2px;
}

[class*='pac-container'].custom {
    width: 100%;
    display: none;
    z-index: 1000;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

[class*='pac-container'].custom i {
    color: #fff;
    font-size: 24px;
    vertical-align: -4px;
    margin-right: 5px;
}

[class*='pac-container'].custom .pac-item {
    -webkit-border-radius: 16px 16px 0 0;
    -moz-border-radius: 16px 16px 0 0;
    -ms-border-radius: 16px 16px 0 0;
    -o-border-radius: 16px 16px 0 0;
    border-radius: 16px 16px 0 0;
}

.hdpi.pac-logo:after {
    margin: 8px 16px;
    background-image: url('#{$resourcesFolder}/corporate/icons/powered_by_google_on_non_white_hdpi.png');
}
