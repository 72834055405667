@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt201-card-elev-news {
    $root: &;
    border-radius: rem(12px);
    background-color: var(--color-white);
    position: relative;
    display: flex;
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    /* standard variant, no image*/
    width: rem(344px);
    min-height: rem(240px);

    @include respond-above(l) {
        width: 100%;
        min-height: rem(297px);
    }

    &--richiamoProdotto {
        background-color: var(--color-secondary3);

        #{$root}__category {
            color: var(--color-semantic3);
        }
    }

    /* large variant, no image*/
    &--large {
        width: 100%;
        min-height: rem(296px);

        @include respond-above(l) {
            width: rem(624px);
        }

        #{$root}__bottom {
            padding-left: rem(16px);
            padding-right: rem(16px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    /* large variant, image */ 
    &--largeImg { 
        width: 100%;
        min-height: rem(296px);
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
        }

        #{$root}__image.rt022-picture--cover {
            width: 100%;
            height: rem(145px);
            border-top-left-radius: rem(12px);
            border-top-right-radius: rem(12px);
    
            @include respond-above(l) {
                width: rem(312px);
                height: 100%;
                border-top-left-radius: rem(12px);
                border-bottom-left-radius: rem(12px);
                border-top-right-radius: 0;
            }
        }
        
        #{$root}__abstract {
            @include respond-below(l) {
                display: none;
            }
        }

        #{$root}__cta {
            @include respond-below(l) {
                display: none;
            }
        }

        #{$root}__bottom {
            padding-left: rem(24px);
            padding-right: rem(16px);
            max-width: rem(623px);
            padding-bottom: rem(16px);
        }
    }

    /* small variant */
    &--small {
        width: rem(224px);
        min-height: rem(304px);
        padding-left: rem(16px);
        padding-right: rem(16px);
        
        @include respond-above(l) {
            width: 100%;
        }

        #{$root}__bottom {
            padding-left: 0;
            padding-right: 0;
        }
    }

    /* standard variant, image*/
    &--standardImg {
        width: rem(344px);
        min-height: rem(297px);
        flex-direction: column;

        @include respond-above(l) {
            width: 100%;
            min-height: rem(217px);
        }
        
        #{$root}__image.rt022-picture--cover {
            height: rem(145px);
            border-top-left-radius: rem(12px);
            border-top-right-radius: rem(12px);
        }

        #{$root}__abstract {
            display: none;
        }

        #{$root}__cta {
            display: none;
        }
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        font-weight: 500;
        line-height: rem(16px);
        margin-bottom: rem(8px);
        padding-top: rem(16px);
    }

    &__breadcrumb {
        display: flex;
        flex-direction: row;
        color: var(--color-primaryGray);
        margin-bottom: rem(8px);
    }

    &__readingTime {
        font-size: rem(12px);
    }

    &__date {
        margin-right: rem(24px);
        font-size: rem(12px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        font-weight: 500;
        line-height: rem(24px);
        margin-bottom: rem(8px);
        color: var(--color-darkCharcoal);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__abstract {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        font-weight: 400;
        color: var(--color-primaryGray);
        line-height: rem(20px);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__cta {
        margin-top: rem(20px);
    }

    &__bottom {
        padding-left: rem(16px);
        padding-right: rem(16px);
        padding-bottom: rem(16px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--fixed {
        margin-bottom: rem(28px);

        @include respond-above(l) {
            margin-bottom: rem(40px);
        }
    }
}
