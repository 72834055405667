@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt222-card-promotional {
    $root: &;
    grid-column: span 2;

    display: flex;
    justify-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    height: rem(296px);
    background-color: var(--color-white);
    border-radius: rem(12px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    @include respond-above(m) {
        grid-column: span 2;
    }

    @include respond-above(l) {
        height: rem(334px);
    }

    &--hidden {
        display: none;
    }

    &__left {
        padding: rem(16px);
        flex: 1;
        display: flex;
        flex-direction: column;
        max-width: 50%;
    }

    &__right {
        flex: 1;
        display: flex;
        align-items: center;
        max-width: rem(450px);
    }

    &__topLeft {
        flex: 1 1 auto;
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
        margin-bottom: rem(8px);

        &--agg {
            font-size: rem(30px);
            line-height: rem(38px);
            margin-bottom: rem(20px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        margin-bottom: rem(6px);
        word-break: break-word;

        &--agg {
            font-size: rem(14px);
            line-height: rem(20px);
        }
    }

    &__priceText {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: alpha(var(--color-black), 0.4);
        align-self: flex-end;
    }

    &__finalPrice {
        @extend %robotoFontMedium;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-redPromotion);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__originalPrice {
        @extend %robotoFontMedium;
        font-size: rem(16px);
        line-height: rem(20px);
        text-decoration: line-through;
        text-decoration-thickness: 0.2%;
        text-decoration-color: alpha(var(--color-black), 0.6);
        margin-top: rem(2px);
        margin-right: rem(10px);
    }

    &__breadcrumb {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        color: alpha(var(--color-black), 0.3);
        margin-bottom: rem(8px);
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(16px);

        &--margin {
            margin-top: rem(23px);
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        border-top-right-radius: rem(12px);
        border-bottom-right-radius: rem(12px);
    }

    &__prices {
        display: flex;
        flex-direction: row;
        margin-bottom: rem(16px);
    }

    &__rightPrices {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &--evidence {
        &:before {
            content: '';
            position: absolute;
            top: rem(-0.5px);
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: rem(-1.5px) rem(-2px);
            border-radius: inherit;
            background: linear-gradient(to bottom right, var(--color-primary2), var(--color-primary1), var(--color-redPromotion));

            @include respond-above(l) {
                top: rem(-1px);
                margin: rem(-1px) rem(-2px);
            }
        }
    }

    &__evidence {
        background-image: linear-gradient(
            to bottom right,
            var(--color-primary2),
            var(--color-primary1),
            var(--color-redPromotion)
        );
        color: var(--color-neutral);
    }
}
