@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt204-card-text {
    $root: &;
    width: rem(344px);
    min-height: rem(232px);
    border-radius: rem(12px);
    background-color: var(--color-white);
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    @include respond-above(l) {
        width: 100%;
        min-height: rem(260px);
    }

    &__upLeft {
        width: rem(54px);
        height: rem(56px);
        margin-right: rem(16px);
    }

    &__content {
        padding: rem(24px);
        display: flex;
        flex-direction: column;
    }

    &__icon {
        font-size: rem(48px);
        line-height: rem(56px);
    }

    &__titleNum {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-primary1);

        @include respond-above(l) {
            font-size: rem(48px);
            line-height: rem(56px);
        }
    }

    &__title {
        color: var(--color-darkCharcoal);

        &--styling {
            @extend %dmSansFontRegular;
            font-size: rem(16px);
            line-height: rem(24px);
        }
    }

    &__text {
        color: var(--color-primaryGray);

        &--styling {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
        }
    }

    &__up {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: rem(16px);
        height: rem(80px);
    }

    /* variant small */
    &--small {
        width: rem(304px);
        min-height: rem(100px);

        @include respond-below(l) {
            width: 100%;
        }

        #{$root}__icon {
            font-size: rem(30px);
            color: var(--color-bookmark);
        }

        #{$root}__title {
            font-weight: 700;
        }

        #{$root}__up {
            margin-bottom: rem(14px);
            height: rem(30px);
        }

        #{$root}__upLeft {
            width: rem(30px);
            height: rem(30px);
            display: flex;
            align-items: center;
        }
    }

    /* variant iconCircle */
    &--iconCircle {
        min-height: unset;

        #{$root}__icon {
            font-size: rem(30px);
            line-height: unset;
            color: var(--color-bookmark);
            margin: auto;
        }

        #{$root}__upLeft {
            width: rem(48px);
            height: rem(48px);
            background-color: var(--color-neutral);
            border-radius: 50%;
            display: flex;
            align-items: center;
        }
    }
}
