@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;

.rt006-tag {
    $root: &;
    height: rem(32px);
    display: flex;
    align-items: center;

    &__input {
        position: absolute;
        opacity: 0;
        z-index: 0 !important;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        width: fit-content;
        padding-top: rem(4px);
        padding-right: rem(22px);
        padding-bottom: rem(4px);
        padding-left: rem(22px);
        height: rem(32px);
        background-color: alpha(--color-darkCharcoal, 0.05);
        border: 1px solid transparent;
        border-radius: rem(16px);
        cursor: pointer;
        flex-shrink: 0;
    }

    &__labelText {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        color: lightness(--color-black, 20%);
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    &--icon {
        
        #{$root}__wrapper {
            padding-top: rem(4px);
            padding-right: rem(4px);
            padding-bottom: rem(4px);
            padding-left: rem(24px);
        }

        /* X icon */
        #{$root}__labelText > span {
            display: inline-block;
            background-image: url('#{$resourcesFolder}/corporate/icons/x-close.svg');
            background-size: rem(24px);
            background-repeat: no-repeat;
            width: rem(24px);
            height: rem(24px);
            border-radius: 50%;
            margin-left: rem(10px);
            pointer-events: all;
        }
    }

    &--disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &__input:checked ~ &__wrapper {
        box-shadow: 0 0 10px -2px alpha(--color-primary1, 0.9);
        border: 4px solid alpha(--color-primary1, 0.05);
        background-color: var(--color-neutral);
    }
    &__input:checked ~ &__wrapper > &__labelText > span {
        background-image: url('#{$resourcesFolder}/corporate/icons/x-close-selected.svg');
        margin-right: 0;
    }
}
