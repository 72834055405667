@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;

.rt002-cta-semantic {
    $root: &;

    /* STATUS */
    background-color: transparent;
    color: var(--color-darkCharcoal);
    border: solid 2px;
    border-color: var(--color-primary1);

    &:hover {
        color: alpha(--color-primary1, 0.9);
        border-color: alpha(--color-primary1, 0.9);
    }
    &:focus {
        border: solid 2px;
        border-color: var(--color-primary1);
    }

    &1 {
        color: var(--color-semantic1);
        border: solid 2px;
        border-color: var(--color-semantic1);
    }
    &1:hover {
        color: alpha(--color-semantic1, 0.8);
        border-color: alpha(--color-semantic1, 0.8);
    }
    &1:focus {
        border-color: var(--color-semantic1);
    }

    &2 {
        color: var(--color-semantic2);
        border: solid 2px;
        border-color: var(--color-semantic2);
    }
    &2:hover {
        color: alpha(--color-semantic2, 0.8);
        border-color: alpha(--color-semantic2, 0.8);
    }
    &2:focus {
        border-color: var(--color-semantic2);
    }

    &3 {
        color: lightness(--color-semantic3, 27.5%);
        border: solid 2px;
        border-color: lightness(--color-semantic3, 45%);
    }
    &3:hover {
        color: lightness(--color-semantic3, 30%);
        border-color: lightness(--color-semantic3, 47.5%);
    }
    &3:focus {
        border-color: lightness(--color-semantic3, 45%);
    }

    &4 {
        color: var(--color-semantic4);
        border: solid 2px;
        border-color: var(--color-semantic4);
    }
    &4:hover {
        color: alpha(--color-semantic4, 0.8);
        border-color: alpha(--color-semantic4, 0.8);
    }
    &4:focus {
        border-color: var(--color-semantic4);
    }
}