@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt138-richtext-section {

    &--container {
        @extend %container;
    }

    &--separator-title &__title,
    &--separator-subtitle &__subtitle {
        @include addSeparator();
    }

    &--shrinkText &__title,
    &--shrinkText &__text {
        @include respond-above(l) {
            max-width: rem(624px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-darkCharcoal);
        padding-bottom: rem(16px);

        @include respond-above(l) {
            line-height: rem(36px);
        }
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(25px);
        color: lightness(--color-black, 20%);
        padding-bottom: rem(16px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        padding-bottom: rem(16px);
        margin-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(16px);
            line-height: rem(25px);
            padding-bottom: unset;
            margin-bottom: unset;
        }
    }
}
