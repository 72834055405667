@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt255-flyer-filters {
    @include modal(&, true);

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        padding-bottom: rem(13px);
    }

    &__carousel {
        overflow: hidden;
        width: 100%;
        padding-bottom: rem(24px);
        margin-top: rem(-10px);
    }

    &__track {
        display: flex;
        width: 100%;
        padding-top: rem(10px);
    }

    &__slideContainer {
        display: flex;
        width: fit-content;
        padding-left: rem(5px);
        padding-right: rem(5px);
    }

    &__labelCategory {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(16px);
        padding-bottom: rem(24px);
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: rem(19px);
        width: 100%;
    }

    &__cta {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        display: flex;
        align-items: center;
        cursor: pointer;

        & > span:first-child {
            margin-right: rem(5px);
        }

        & > span:last-child {
            text-decoration: underline;
        }
    }

    &__filters {
        display: flex;
        flex-direction: column;
    }

    &__filter {
        width: 100%;
        margin-bottom: rem(16px);
    }

    &__productsTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__productsNumber {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        margin-top: rem(8px);

        @include respond-above(l) {
            opacity: 0;
            width: 0;
            height: 0;
        }
    }

    &__mob {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__accordion {
        border: 1px solid alpha(--color-darkCharcoal, 30%);
        border-radius: rem(16px);
        background-color: var(--color-white);
        overflow: hidden;
        margin-bottom: rem(8px);
        transition: all .2s ease-in-out;
    }
    &__accordion--open {
        border-color: var(--color-black);
        display: flex;
        flex-flow: column;
        margin-bottom: rem(8px);
    }

    &__heading {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: lightness(--color-black, 20%);
        padding: rem(16px);
        display: flex;
        align-items: center;
        cursor: pointer;

        & > span:first-child {
            flex-grow: 1;
        }
        & > span + span:not(:last-child) {
            flex-shrink: 0;
            font-size: rem(12px);
            line-height: rem(12px);
            color: var(--color-white);
            display: none;
            width: rem(24px);
            height: rem(24px);
            background-color: var(--color-primary1);
            border-radius: 50%;
            padding: rem(6px) 0;
            text-align: center;
            margin-right: rem(10px);
        }
        & > span:last-child {
            flex-shrink: 0;
            font-size: rem(24px);
            transition: transform .2s ease-in-out;
        }
    }

    &__accordion--showCount &__heading > span + span:not(:last-child) {
        display: block;
    }

    &__accordion--open &__heading > span:last-child {
        transform: rotateX(180deg);
    }

    &__content {
        max-height: 0;
        pointer-events: none;
        display: flex;
        flex-flow: column;
        transition: all .2s ease-in-out;
    }

    &__accordion--open &__content {
        pointer-events: all;
        max-height: 100%;
        flex-grow: 1;
    }

    &__form {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        padding: 1px rem(16px) rem(16px);
        transition: all .2s ease-in-out;
        position: relative;
        overflow: hidden;

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            display: none;
        }
    }

    &__accordion--open &__form {
        flex-grow: 1;
    }

    &__ctas {
        flex-shrink: 0;
        display: none;
        justify-content: flex-end;
        align-items: center;
        gap: rem(16px);
        padding: rem(20px) rem(16px) rem(16px);
        background-color: var(--color-white);
        box-shadow: 0 -4px 8px -2px alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }
    &__accordion--open &__ctas {
        display: flex;
    }

    &__search{
        width: auto;
        margin: rem(0px) rem(24px) rem(24px) rem(24px);
    }

    &__option {
        display: flex;
        justify-content: space-between;

        &--hidden {
            display: none;
        }
    }

    &__checkbox ~ span {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-black);
        display: inline-block;
        width: rem(41px);
        height: fit-content;
        text-align: center;
        padding: rem(5px) 0;
        background-color: alpha(--color-darkCharcoal, 5%);
        border-radius: 12px;
        flex-shrink: 0;
    }

    &__checkbox{

        &--hidden{
            display: none;
        }
    }
}