@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt116-pub-banner {
    &__image.rt022-picture--cover {
        @extend %container;
        width: 100%;
        height: rem(423px);

        @include respond-below(l) {
            height: rem(386px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        @extend %container;
        font-size: rem(12px);
        line-height: rem(16px);
        margin-top: rem(10px);
    }
}
