@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='pet-store'],
body.theme-pet-store {
    @include defineColorsHexMap(map.merge($default-theme, $pet-store-theme));
}
