@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt101-thumb-slider {
    @extend %container;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: opacity 0.3s ease-out;

    @include respond-above(l) {
        flex-direction: row;
    }

    &--hidden {
        opacity: 0;
    }

    &__logo {
        width: rem(100px);
        height: rem(77px);
        margin-top: rem(36px);
        object-fit: contain;
    }

    &__title {
        @extend %dmSansFontBold;
        margin-bottom: rem(16px);
        padding-top: rem(16px);
        max-width: 73%;
        font-size: rem(28px);
        line-height: rem(30px);

        @include respond-above(m) {
            max-width: 100%;
            font-size: rem(32px);
            line-height: rem(40px);
        }

        &--hide {
            visibility: hidden;
        }
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(40px);
        border-radius: rem(4px);

        @include respond-above(l) {
            margin-bottom: rem(24px);
        }

    }

    &__text {
        margin-bottom: rem(32px);
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);

        &--hide {
            visibility: hidden;
        }
    }

    &__cta.rt002-cta {
        margin-bottom: rem(24px);

        @include respond-below(l) {
            display: none;
        }

        &--hide {
            visibility: hidden;
        }
    }

    &__cta {
        &--hide {
            visibility: hidden;
        }
    }

    &__ctaMob.rt002-cta {
        width: 100%;
        text-align: center;
        margin-top: rem(40px);

        @include respond-above(l) {
            display: none;
        }
    }

    &__ctaMob {
        &--hide {
            visibility: hidden;
        }
    }

    &__left {
        width: 100%;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @extend %container;

        @include respond-below(l) {
            padding: 0;
            margin: 0;
        }

        @include respond-above(l) {
            width: poc(266px, 1024px);
            padding: 0;
            margin: 0;
            margin-right: rem(54px);
        }
    }

    /* slider */
    &__right {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: calc(100% + #{2 * rem($container-wpad)});
        margin-left: -1 * rem($container-wpad);
        position: relative;

        @include respond-above(l) {
            width: 100%;
            margin-left: 0;
        }
    }

    &__slideContainer {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
        margin-right: rem(16px);
        margin-top: rem(5px);

        @include respond-above(l) {
            margin-top: 0;
        }
    }

    &__track {
        width: 100%;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;
        margin-left: rem(2px);
    }

    &__pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(24px);

        @include respond-above(l) {
            display: flex;
        }

        &--hidden {
            @include respond-above(l) {
                display: none;
            }
        }
    }

    &__slide {
        width: rem(254px);
        max-width: rem(254px);

        @include respond-above(m) {
            width: rem(304px);
            max-width: rem(304px);
        }
    }

    &__prevNext {
        @extend %container;
        flex-direction: row;
        justify-content: flex-end;
        margin-bottom: rem(10px);
        padding-top: rem(16px);
        display: none;

        @include respond-above(l) {
            display: flex;
        }
    }

    &__btn {
        @extend %buttonReset;
        cursor: pointer;
        transform: translateY(-50%);

        &--prev {
            transform: translateY(-50%) rotateZ(180deg);
            display: block;
            margin-right: rem(18px);
        }

        &--next {
            display: block;
        }

        &--hidden {
            display: none;
        }

        &--disabled {
            opacity: 0.5;
        }

        &::after {
            content: '';
            background-image: url('#{$resourcesFolder}/corporate/icons/next.svg');
            display: block;
            background-size: rem(16px);
            background-repeat: no-repeat;
            background-position: center;
            width: rem(16px);
            height: rem(10px);
        }
    }

    &__scrollbar {
        margin-top: rem(21px);
        width: 66%;
        height: rem(5px);
        margin-left: rem(87px);
        border-radius: rem(10px);
        background-color: alpha(var(--color-black), 0.2);

        @include respond-below(l) {
            margin-left: rem(16px);
            margin-top: rem(19px);
            width: 90%;
        }
    }

    &__scrollbarDrag {
        width: 100%;
        height: 100%;
        background-color: var(--color-black);
        border-radius: rem(10px);
    }
}
