@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt064-filters {
    &--container {
        @extend %container;
    }
    
    &__dsk {
        display: none;
        flex-flow: row wrap;
        gap: rem(16px);
        
        @include respond-above(l) {
            display: flex;
        }
    }

    &__mob {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-above(l) {
            display: none;
        }
    }

    &__mob &__results {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }
    &__dsk &__results {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        width: 100%;
        margin-top: rem(4px);
    }

    &__cta {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        display: flex;
        align-items: center;
        cursor: pointer;

        & > span:first-child {
            margin-right: rem(5px);
        }

        & > span:last-child {
            text-decoration: underline;
        }
    }

    &__count {

    }
}