@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.rt034-pin-code-field {
    $root: &;
    position: relative;
    width: 100%;
    display: block;

    &__input {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        display: block;
        width: 100%;
        height: rem(56px);
        outline: none;
        padding: rem(16px);
        background-color: transparent;
        transition: all 0.2s ease-in-out, visibility 0s;
        border-radius: rem(16px);
        border-width: 1px;
        border-style: solid;
        border-color: alpha(--color-black, 0.3);
    }

    &__input:focus {
        border-color: var(--color-primary2);
        background-color: alpha(--color-primary2, 0.2);
    }

    &__input:focus,
    &__input:not(:placeholder-shown) {
        opacity: 1;
        padding-bottom: 0;
        line-height: rem(39px);
    }

    &__input:focus ~ &__label,
    &__input:not(:placeholder-shown) + &__label {
        font-size: rem(13px);
        top: rem(14px);
        color: alpha(--color-black, 0.8);
        background-color: transparent;
    }
    &__input:not(:focus)::placeholder {
        color: transparent;
    }

    &__label {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        transition: all 0.2s ease-in-out;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: rem(16px);
        right: rem(16px);
        pointer-events: none;
        color: alpha(--color-black, 0.8);
    }

    /* fix autocomplete hover on webkit browsers */
    input:-webkit-autofill:hover ~ &__label {
        color: lightness(--color-black, 20%);
    }

    &--errorState &__input {
        border-color: var(--color-semantic2);
    }

    &--validState#{$root}--validEnabled &__input {
        border-color: var(--color-semantic1);
    }

    &--warningState#{$root}--validEnabled &__input {
        border-color: var(--color-semantic3);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        padding-left: rem(16px);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
