@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;

.rt022-picture {
    position: relative;
    display: block;
    overflow: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;

    &--init,
    &--loaded {
        opacity: 1;
    }

    &--container {
        @extend %container;
    }

    &--roundEdges,
    &--roundEdges > img {
        border-radius: rem(12px);
    }

    &--cover,
    &--cover > img {
        object-fit: cover;
    }

    &--contain,
    &--contain > img {
        object-fit: contain;
    }

    /* For picture */
    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-position: center center;
    }
}
