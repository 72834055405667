@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt065-form-premio {
    @extend %container;

    &__form {
        display: flex;
        flex-flow: column;
        gap: rem(40px);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;

        &--visible {
            opacity: 1;
        }
    }

    &__fields {
        display: flex;
        gap: rem(16px);
        flex-flow: column;

        @include respond-above(l) {
            flex-flow: row wrap;
        }
    }

    &__field {
        margin-bottom: rem(8px);

        @include respond-above(l) {
            width: calc(50% - 8px);
        }
    }
    &__field:last-child {
        margin-bottom: unset;

        @include respond-above(l) {
            width: 100%;
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-darkCharcoal);
        margin-bottom: rem(24px);
    }

    &__title-container {
        display: flex;
    }

    &__privacyData &__title {
        margin-bottom: rem(16px);

        @include respond-above(l) {
            margin-bottom: rem(12px);
        }
    }

    &__privacyDataText {
        position: relative;
        margin-bottom: rem(56px);
    }
    &__privacyData--readAll &__privacyDataText {
        margin-bottom: rem(16px);
    }

    &__shadow {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background: linear-gradient(to top, var(--color-white), alpha(--color-white, 50%));
    }
    &__privacyData--readAll &__shadow {
        display: none;
    }

    &__readAll {
        position: absolute;
        bottom: -32px;
        left: 0;
    }
    &__privacyData--readAll &__readAll {
        display: none;
    }

    &__privacyDataText &__richtext {
        height: 80px;
        overflow: hidden;
    }
    &__privacyData--readAll &__richtext {
        height: unset;
        overflow: initial;
    }

    &__privacyData &__radios {
        margin-top: rem(24px);
    }

    &__pinCode {
        margin-bottom: rem(24px);
    }

    &__sendCode {
        display: block;
        width: 100%;
        text-align: center;

        @include respond-above(l) {
            margin-left: auto;
            width: fit-content;
            text-align: unset;
        }
    }

    &__errorSendCode {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(20px);
        color: var(--color-semantic2);
        padding-left: rem(16px);
        padding-top: rem(10px);
        user-select: none;
        text-align: right;
    }

    &__contact {
        padding-top: rem(24px);
        border-top: 1px solid alpha(--color-black, 10%);

        & > div:last-of-type {
            display: flex;
            flex-flow: column;
            gap: rem(24px);

            @include respond-above(l) {
                flex-flow: row;
                gap: rem(32px);
            }
        }
    }

    &__contact &__title {
        font-size: rem(16px);
        line-height: rem(24px);
    }

    &__phone,
    &__mail {
        display: flex;
        gap: rem(16px);
        align-items: center;

        & > span:first-child {
            font-size: rem(24px);
            color: var(--color-semantic4);
        }
    }

    &__link {
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(18px);
        white-space: nowrap;
    }


    &__tooltipIcon {
        width: rem(24px);
        height: rem(24px);
        background-image: url('#{$resourcesFolder}/corporate/icons/info-24.svg');
        background-size: rem(24px);
        background-repeat: no-repeat;
        margin-left: rem(10px);
        pointer-events: all;
    }
}
