@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt067-plays-premio {

    &--container {
        @extend %container;
    }
    

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-darkCharcoal);
        padding-top: rem(40px);
        border-top: 1px solid lightness(--color-black, 90%);
        margin-bottom: rem(16px);

        @include respond-above(l) {
            margin-bottom: rem(24px);
        }
    }
    
    /* table styles */
    table {
        table-layout: fixed;
        width: 100%;

        thead tr:first-child {
            background-color: alpha(--color-neutral, 20%);
        }
        
        tr {
            font-size: rem(14px);
            line-height: rem(20px);
            color: lightness(--color-black, 40%);

            &:nth-child(odd) {
                background-color: var(--color-neutral);
            }
            &:nth-child(even) {
                background-color: alpha(--color-neutral, 20%);
            }

            th,
            td {
                &:first-child {
                    padding-left: rem(8px);

                    @include respond-above(l) {
                        padding-left: rem(24px);
                    }
                }
            }

            th {
                @extend %dmSansFontBold;
                text-align: start;
                padding-top: rem(16px);
                padding-bottom: rem(16px);
            }
            td {
                @extend %dmSansFontRegular;
                padding-top: rem(20px);
                padding-bottom: rem(20px);
            }
        }
    }
}
