@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='piacersi'],
body.theme-piacersi {
    @include defineColorsHexMap(map.merge($default-theme, $piacersi-theme));
}
