@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt015-badge {
    $root: &;

    @extend %dmSansFontMedium;
    font-size: rem(12px);
    line-height: rem(12px);
    height: rem(22px);

    padding: 0 rem(16px);
    overflow: hidden;
    border-radius: rem(12px);
    display: flex;
    align-items: center;
   
    &__icon {
        display: none;
        margin-right: rem(5px);
        width: rem(15px);
        height: rem(14px);
        background-size: rem(14px);
        background-repeat: no-repeat;
    }

    /* BADGE TYPES */
    &,
    &--sponsorizzato {
        color: lightness(--color-black, 20%);
        background-color: var(--color-neutral);
    }
    &--discount {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-semantic2);
        background-color: lightness(--color-semantic2, 90%);
    }
    &--benessere {
        color: var(--color-extra2);
        background-color: lightness(--color-extra2, 90%);
    }
    &--territorio {
        color: white;
        background-color: var(--color-secondary4);
    }
    &--sostenibilita {
        color: var(--color-darkCharcoal);
        background-color: var(--color-secondary5);
    }
    &--popolare {
        color: var(--color-primary1);
        background-color: lightness(--color-primary1, 90%);

        #{$root}__icon {
            display: inline-block;
            background-image: url('#{$resourcesFolder}/corporate/icons/star-full.svg');
            position: relative;
            bottom: 1px;
        }
    }
    &--italiano {
        color: lightness(--color-black, 20%);
        background-color: lightness(--color-darkCharcoal, 90%);

        #{$root}__icon {
            display: inline-block;
            background-image: url('#{$resourcesFolder}/corporate/icons/flag-it.svg');
        }
    }
}
