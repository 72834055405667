@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt143-container-card-nearby {
    @extend %container;

    &__track {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
        }
    }

    &__slide {
        width: 100%;
        margin-bottom: rem(24px);

        @include respond-above(l) {
            width: 33%;
            margin-bottom: 0;
            margin-right: rem(30px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        margin-bottom: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }
}
