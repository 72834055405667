@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt110-italy-map {
    $root: &;
    @extend %container;
    width: 100%;
    float: none;
    display: flex;
    flex-direction: column;

    &__top {
        display: grid;
        grid-template-columns: auto;
        grid-template-areas:
            'title'
            'sep'
            'map'
            'heading'
            'regions';

        @include respond-above(l) {
            grid-template-columns: auto 41%;
            grid-template-rows: auto;
            grid-template-areas:
                'map title'
                'map sep'
                'map text'
                'map regions'
                'map .';
        }
    }

    &__map {
        grid-area: map;
        margin-top: rem(32px);

        @include respond-above(l) {
            margin-top: rem(57px);
        }
    }

    &__regionList {
        grid-area: regions;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        @include respond-below(l) {
            border-bottom: 1px solid lightness(--color-white, 90%);
        }

        &--close {
            @include respond-below(l) {
                display: none;
            }
        }
    }

    &__region {
        @extend %dmSansFontBold;
        cursor: pointer;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        width: 50%;
        margin-bottom: rem(16px);

        hr {
            background-color: var(--color-secondary2);
            width: rem(32px);
            height: rem(2px);
            border: none;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            margin-top: rem(2px);
        }

        &:hover,
        &--selected {
            color: var(--color-primary1);

            hr {
                opacity: 1;
            }
        }
    }

    &__separator {
        grid-area: sep;
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(32px);
    }

    &__title {
        grid-area: title;
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__text {
        grid-area: text;
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-bottom: rem(24px);

        @include respond-below(l) {
            display: none;
        }
    }

    &__lancio {
        display: none;
        padding: 0;
        margin: 0;
        &--selected {
            display: block;
        }

        #{$root}__cta {
            @include respond-below(l) {
                display: none;
            }
        }
    }
    &__heading {
        grid-area: heading;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(40px);
        margin-bottom: rem(24px);
        cursor: pointer;

        @include respond-above(l) {
            display: none;
        }
    }

    &__arrow {
        font-size: rem(24px);
        transition: transform 0.3s ease-in-out;

        &--close {
            transform: rotateX(180deg);
        }
    }

    &__accTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
    }

    &__bottom {

        @include respond-below(l) {
            margin-top: rem(32px);

            &--close {
                margin-top: 0;
            }
        }
    }

    /* svg map */
    .regionMap {
        &:hover,
        &--selected {
            fill: var(--color-primary1);
            cursor: pointer;
        }
    }
    .mapSvg {
        width: 95%;
        height: 90%;
        margin-left: rem(26px);
    
        @include respond-above(l) {
            width: 86%;
            height: 84%;
        }
    }
}
