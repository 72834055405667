@import 'tailwindcss/base';

button {
    text-align: inherit;
}

[x-cloak] {
    display: none !important;
}

@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
    :target {
        @apply scroll-mt-32 lg:scroll-mt-48;
    }
}
