@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt306-excel-to-map-card-details-popup {
    @include actionPopup();

    @include respond-above(m) {
        max-width: rem(621px);
        width: rem(621px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: rem(16px);
        overflow-y: auto;
        max-height: calc(100vh - 40px);

        @include respond-above(m) {
            max-height: unset;
            padding: rem(32px);
        }
    }

    &__textContent {
        display: flex;
        flex-direction: column;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        align-self: stretch;
        text-align: left;
        padding: 0;
        max-width: 80%;

        @include respond-above(m) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__tagsContainer {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        display: flex;
        flex-direction: column;
    }

    &__tagsChipsContainer {
        display: flex;
        flex-direction: row;
        gap: rem(6px) rem(8px);
        margin: rem(10px) 0;
        flex-wrap: wrap;

        @include respond-above(m) {
            gap: rem(6px) rem(12px);
        }
    }

    &__tagSingle {
        @extend %dmSansFontBold;
        padding: 0 rem(12px);
        outline: 1px solid #3d4983;
        border-radius: rem(12px);
        font-size: rem(12px);
        line-height: rem(24px);
        color: #3d4983;
        background-color: white;
    }

    &__infoContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 0;
    }

    &__infoSingle {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
    }

    &__infoSingleSizedDown {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(18px);
    }

    &__infoSingleBold {
        @extend %dmSansFontBold;
    }

    &__listContainer {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include respond-above(m) {
            flex-direction: row;
        }
    }

    &__listElements {
        @extend %dmSansFontRegular;
        display: flex;
        flex-direction: column;
        ul {
            @extend %dmSansFontBold;
            list-style: disc;
            padding-left: rem(20px);
        }
    }

    &__serviceSingle {
        @extend %dmSansFontBold;
        display: flex;
        justify-content: center;
        flex-grow: 0;
        padding: 0 rem(12px);
        border-radius: rem(12px);
        font-size: rem(12px);
        line-height: rem(24px);
        color: #ffffff;
        background-color: #3d4983;
    }

    &__link {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(24px);
        text-decoration: underline;
        color: #ec6a06;
    }

    &__ctaContainer {
        display: flex;

        @include respond-above(m) {
            justify-content: flex-end;
        }
    }

    &__cta {
        @extend %dmSansFontBold;
        width: 100%;
        font-size: rem(16px);
        line-height: rem(16px);
        text-align: center;
        padding: rem(20px) rem(32px);
        border-radius: 12px;


        @include respond-above(m) {
            max-width: rem(164px);
        }
    }
}
