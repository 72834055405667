@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt112-associate-card-banner {
    $wrapper-margin-top: rem(48px);
    @extend %container;

    &__box {
        position: relative;

        @include respond-above(l) {
            flex-grow: 1;
        }
    }
    
    &__image {
        height: rem(78px);
        width: rem(120px);
        background-image: url('#{$resourcesFolder}/corporate/icons/insieme.png');
        background-size: contain;
        background-repeat: no-repeat;
        object-fit: contain;
        position: absolute;
        left: rem(16px);
        top: rem(-32px);

        & > div:first-child {
            height: rem(78px);
            width: rem(120px);
            background-image: url('#{$resourcesFolder}/corporate/icons/insiemepiu.png');
            background-size: contain;
            background-repeat: no-repeat;
            object-fit: contain;
            position: absolute;
            left: rem(40px);
            top: rem(-16px);
        }
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        padding: rem(59px) rem(16px) rem(16px);
        margin-top: $wrapper-margin-top;
        border-radius: rem(12px);
        box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-bottom: rem(8px);
    }

    &__link {
        align-self: flex-end;
    }
}
