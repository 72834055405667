@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt206-card-services {
    width: rem(344px);
    min-height: rem(200px);
    border-radius: rem(12px);
    background-color: var(--color-white);
    display: flex;
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    
    @include respond-above(l) {
        width: 100%;
    }

    &__container {
        width: 100%;
        padding: rem(24px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        padding-bottom: rem(16px);
    }

    &__cta {
        width: 100%;
        .rt002-cta__label {
            display: flex;
            justify-content: center;
        }
    }

    &__icon {
        width: rem(48px);
        height: rem(48px);
        color: var(--color-bookmark);
        font-size: rem(30px);
        background-color: var(--color-seashell);
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: rem(8px);
        align-items: center;
        flex-shrink: 0;

        @include respond-below(l) {
            color: var(--color-darkCharcoal);
            background-color: var(--color-white);
            font-size: rem(24px);
            border-radius: 0%;
            width: rem(24px);
            height: rem(24px);
        }
    }

    &__titleSec {
        display: flex;
        flex-direction: row;
        align-items: center;        
        padding-bottom: rem(16px);
    }

}
