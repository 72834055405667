@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt200-card-elev-editorial {
    $root: &;
    background-color: var(--color-white);
    position: relative;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    width: rem(254px);
    min-height: rem(320px);
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 
                0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    @include respond-above(l) {
        width: 100%;
        min-height: rem(336px);
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        font-weight: 500;
        line-height: rem(16px);
        margin-bottom: rem(8px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        font-weight: 500;
        line-height: rem(24px);
        margin-bottom: rem(8px);
        color: var(--color-darkCharcoal);


        //ellipsis
        max-width: rem(400px);
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include respond-between(m,l) {
            -webkit-line-clamp: 1;
        }
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        font-weight: 400;
        color: var(--color-primaryGray);
        line-height: rem(20px);

        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        @include respond-between(m,l) {
            -webkit-line-clamp: 1;
        }
    }

    &__image.rt022-picture--cover {
        height: rem(152px);
        border-top-left-radius: rem(12px);
        border-top-right-radius: rem(12px);
    }

    &__mdd {
        position: absolute;
        top: rem(21px);
        left: rem(16px);
    }

    &__cta {
        width: rem(40px);
        height: rem(40px);
        position: absolute;
        top: rem(19px);
        right: rem(16px);
    }

    &__bottom {
        padding: rem(16px);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
    }

    /* small carousel variant */
    &--small {
        @include respond-above(l) {
            min-height: rem(320px);
        }

        #{$root}__mdd {
            @include respond-above(l) {
                font-size: rem(12px);
            }
        }

        #{$root}__ctaLink {
            margin-top: rem(16px);
        }

        #{$root}__bottom {
            justify-content: space-between;
        }

       
    }

    /* large */
    &--large {
        background-color: transparent;
        box-shadow: none;
        flex-direction: column;
        margin-bottom: 0;
        width: 100%;
        min-height: rem(259px);

        @include respond-above(l) {
            flex-direction: row;
            justify-content: space-between;
        }

        #{$root}__title {
            width: 86%;
            font-size: rem(20px);
            line-height: rem(24px);

            @include respond-above(l) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }

        #{$root}__image.rt022-picture--cover {
            border-radius: rem(12px);
            height: rem(200px);

            @include respond-above(l) {
                height: rem(259px);
                width: rem(464px);
            }
        }

        #{$root}__abstract {
            padding-bottom: rem(16px);
        }

        #{$root}__bottom {
            min-height: rem(200px);
            justify-content: space-between;
            @include respond-above(l) {
                min-height: rem(259px);
                width: 57%;
                padding-left: rem(96px);
                padding-bottom: rem(36px);
            }

            @include respond-below(l) {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    &--fixed {
        margin-bottom: rem(28px);

        @include respond-above(l) {
            margin-bottom: rem(40px);
        }

        #{$root}__image.rt022-picture--cover {
            border-radius: 12px 12px 0 0;

            @include respond-above(l) {
                border-radius: 12px 0 0 12px;
            }
        }

        #{$root}__bottom {
            background-color: lightness(--color-semantic3, 94.9%);
            border-radius: 0 0 12px 12px;
            padding: rem(16px);

            @include respond-above(l) {
                border-radius: 0 12px 12px 0;
                padding: rem(24px);
            }
        }
    }
}
