@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt037-bookmark {
    $root: &;

    &__cta.rt002-cta { 
        color: var(--color-bookmark);

        &:hover {
            background-color: var(--color-white);
        }
    }
}
