@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='essentiae'],
body.theme-essentiae {
    @include defineColorsHexMap(map.merge($default-theme, $essentiae-theme));
}
