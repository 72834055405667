@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt104-service-banner {
    width: 100%;
    $root: &;
    @extend %container;
    display: grid;
    min-height: rem(500px);

    grid-template-columns: auto;
    grid-template-areas:
        'badge'
        'title'
        'separator'
        'left'
        'text'
        'cta';

    @include respond-above(l) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        grid-template-areas:
            'left .'
            'left badge'
            'left title'
            'left separator'
            'left text'
            'left cta'
            'left .';
    }

    &__left {
        width: 100%;
        position: relative;
        grid-area: left;
    }

    &__leftContainer {
        height: rem(400px);
        width: 100%;

        @include respond-above(l) {
            height: rem(500px);
        }
    }

    &__badge {
        margin-bottom: rem(16px);
        width: fit-content;
        grid-area: badge;
    }

    &__separator {
        @extend %separator;
        margin-top: rem(24px);
        margin-bottom: rem(24px);
        grid-area: separator;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: var(--color-darkCharcoal);
        grid-area: title;
        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        grid-area: text;
        color: var(--color-primaryGray);

        @include respond-above(l) {
            margin-right: rem(40px);
        }
    }

    &__cta.rt002-cta {
        grid-area: cta;

        @include respond-below(l) {
            width: 100%;
            margin-top: rem(24px);
            text-align: center;
        }

        @include respond-above(l) {
            margin-top: rem(35px);
        }
    }

    &__circle {
        display: none;
        position: absolute;
        top: rem(70px);
        border-radius: 57% 54% 76% 55%;
        background-color: var(--color-white);
        width: 90%;
        left: 5%;
        height: rem(356px);

        @include respond-above(l) {
            width: 70%;
            left: 15%;
            height: rem(344px);
        }
    }

    &__center {
        position: relative;
        align-self: center;
    }

    &__centerImage {
        display: block;
        width: rem(199px);
        height: rem(127px);
        align-self: center;

        &:hover {
            margin-bottom: rem(4px);
        }

        @include respond-below(l) {
            width: rem(135px);
            height: rem(86px);
        }
    }

    &__label1,
    &__label2,
    &__label3 {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        text-align: center;
        width: rem(144px);
        @include respond-above(l) {
            font-size: rem(14px);
            line-height: rem(20px);
        }
    }

    &__group1,
    &__group2,
    &__group3 {
        position: absolute;
    }
    &__arrow1,
    &__arrow2,
    &__arrow3 {
        width: rem(72px);
        height: rem(90px);
        background-size: rem(40px);
        background-image: url('#{$resourcesFolder}/corporate/icons/arrow.png');
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;

        @include respond-above(l) {
            width: rem(72px);
            height: rem(90px);
            background-size: rem(55px);
        }
    }

    &__group1 {
        top: rem(-122px);
        left: rem(89px);
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            top: rem(-138px);
            left: rem(137px);
        }
    }

    &__label1 {
        width: rem(111px);
        @include respond-below(l) {
            transform: translateY(38px) translateX(10px);
        }
    }

    &__arrow1 {
        transform: translateX(-63%) rotateZ(-7deg);
        animation: 1s shake1 1s infinite both;
    }

    &__group2 {
        top: rem(-58px);
        left: rem(-123px);

        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;

        @include respond-below(l) {
            left: rem(-114px);
        }
    }

    &__label2 {
        width: rem(111px);
        @include respond-below(l) {
            transform: translateY(20px);
        }
    }

    &__arrow2 {
        transform: rotateZ(270deg);
        animation: 1s shake2 1s infinite both;
    }

    &__group3 {
        bottom: rem(-121px);
        left: rem(54px);
        display: flex;
    }

    &__label3 {
        width: rem(143px);
        transform: translateY(-21px);
        @include respond-below(l) {
            width: rem(111px);
            transform: translateX(-29px) translateY(-13px);
        }
    }

    &__arrow3 {
        transform: translateY(-39%) scaleX(-1) rotateZ(207deg);
        animation: 1s shake3 1s infinite both;
    }

    &--variant2 {
        @include respond-above(l) {
            grid-template-rows: auto;
            grid-template-areas:
                'left .'
                'left title'
                'left separator'
                'left text'
                'left cta'
                'left .';
        }

        #{$root}__circle {
            display: block;
        }

        #{$root}__leftContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            height: rem(500px);
        }

        #{$root}__image {
            display: none;
        }

        #{$root}__badge {
            display: none;
        }
    }

    &--variant3 {
        @include respond-above(l) {
            grid-template-rows: auto;
            grid-template-areas:
                '. left'
                'title left'
                'separator left'
                'text left'
                'cta left'
                '. left';
        }

        #{$root}__leftContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            height: rem(500px);
        }

        #{$root}__image {
            display: none;
        }

        #{$root}__badge {
            display: none;
        }

        #{$root}__circle {
            display: block;
        }

        #{$root}__group1 {
            @include respond-above(l) {
                left: rem(-135px);
                top: rem(-135px);
                flex-direction: row;
            }
        }

        #{$root}__arrow1 {
            @include respond-above(l) {
                transform: translateY(50%) scaleX(-1) rotateZ(-1deg);
                animation: 1s shake1-3 1s infinite both;
            }
        }

        #{$root}__group2 {
            @include respond-above(l) {
                top: rem(-40px);
                left: rem(171px);
            }
        }

        #{$root}__label2 {
            @include respond-above(l) {
                transform: translateX(50%);
            }
        }
        #{$root}__label3 {
            @include respond-above(l) {
                transform: translateY(-36px) translateX(29px);
            }
        }

        #{$root}__arrow2 {
            @include respond-above(l) {
                transform: scaleX(-1) rotateZ(270deg);
                animation: 1s shake2-3 1s infinite both;
            }
        }

        #{$root}__group3 {
            @include respond-above(l) {
                bottom: rem(-121px);
                left: rem(-153px);
                flex-direction: row-reverse;
            }
        }

        #{$root}__arrow3 {
            @include respond-above(l) {
                transform: translateY(-39%) rotateZ(217deg);
                animation: 1s shake3-3 1s infinite both;
            }
        }
    }

    &--variant4 {
        @include respond-above(l) {
            grid-template-rows: auto;
            grid-template-areas:
                'left .'
                'left title'
                'left separator'
                'left text'
                'left cta'
                'left .';
        }

        #{$root}__leftContainer {
            display: flex;
            justify-content: center;
            width: 100%;
            height: rem(500px);
        }

        #{$root}__image {
            display: none;
        }

        #{$root}__badge {
            display: none;
        }

        #{$root}__circle {
            display: block;
        }

        #{$root}__centerImage {
            width: rem(105px);
            height: rem(186px);
            @include respond-above(l) {
                height: rem(280px);
                width: rem(172px);
            }
        }

        #{$root}__label1 {
            @include respond-above(l) {
                width: rem(143px);
            }
        }

        #{$root}__group1 {
            left: rem(105px);
            @include respond-above(l) {
                left: rem(137px);
                top: rem(-87px);
                flex-direction: row-reverse;
            }
        }

        #{$root}__arrow1 {
            @include respond-above(l) {
                transform: rotateZ(-1deg) translateY(21px);
                animation: 1s shake1-4 1s infinite both;
            }
        }

        #{$root}__group3 {
            bottom: rem(-153px);
            @include respond-above(l) {
                bottom: rem(-105px);
                left: rem(-162px);
                flex-direction: row-reverse;
            }
        }

        #{$root}__label3 {
            transform: translateX(-3px) translateY(-13px);
            @include respond-above(l) {
                transform: translateY(23px);
            }
        }

        #{$root}__arrow3 {
            @include respond-above(l) {
                transform: rotateZ(207deg) translateX(-21px);
                animation: 1s shake3-4 1s infinite both;
            }
        }
    }

    @keyframes shake1 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) translateX(-63%) rotateZ(-7deg);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) translateX(-63%) rotateZ(-7deg);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) translateX(-63%) rotateZ(-7deg);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) translateX(-63%) rotateZ(-7deg);
        }
    }

    @keyframes shake1-3 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) translateY(50%) scaleX(-1) rotateZ(-1deg);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) translateY(50%) scaleX(-1) rotateZ(-1deg);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) translateY(50%) scaleX(-1) rotateZ(-1deg);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) translateY(50%) scaleX(-1) rotateZ(-1deg);
        }
    }

    @keyframes shake1-4 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) rotateZ(-1deg) translateY(21px);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) rotateZ(-1deg) translateY(21px);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) rotateZ(-1deg) translateY(21px);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) rotateZ(-1deg) translateY(21px);
        }
    }

    @keyframes shake2 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) rotateZ(270deg);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) rotateZ(270deg);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) rotateZ(270deg);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) rotateZ(270deg);
        }
    }

    @keyframes shake2-3 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) scaleX(-1) rotateZ(270deg);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) scaleX(-1) rotateZ(270deg);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) scaleX(-1) rotateZ(270deg);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) scaleX(-1) rotateZ(270deg);
        }
    }

    @keyframes shake3 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) translateY(-39%) scaleX(-1) rotateZ(207deg);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) translateY(-39%) scaleX(-1) rotateZ(207deg);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) translateY(-39%) scaleX(-1) rotateZ(207deg);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) translateY(-39%) scaleX(-1) rotateZ(207deg);
        }
    }

    @keyframes shake3-3 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) translateY(-39%) rotateZ(217deg);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) translateY(-39%) rotateZ(217deg);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) translateY(-39%) rotateZ(217deg);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) translateY(-39%) rotateZ(217deg);
        }
    }
    @keyframes shake3-4 {
        10%,
        90% {
            transform: translate3d(-1px, 0, 0) rotateZ(207deg) translateX(-21px);
        }

        20%,
        80% {
            transform: translate3d(2px, 0, 0) rotateZ(207deg) translateX(-21px);
        }

        30%,
        50%,
        70% {
            transform: translate3d(-4px, 0, 0) rotateZ(207deg) translateX(-21px);
        }

        40%,
        60% {
            transform: translate3d(4px, 0, 0) rotateZ(207deg) translateX(-21px);
        }
    }
}
