@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;
@use 'sass:math';

.rt069-search-bar {
    $root: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-white);
    padding: rem(40px) 0;
    transition: margin-top .3s ease-in-out, visibility .3s ease-in-out;
    
    &__searchWrapper {
        @extend %container;
        display: flex;
        justify-content: space-between;
        
    }

    &__search {
        max-width: poc(703px, 944px);
    }

    &__close {
        align-self: flex-start;
        font-size: rem(24px);
        margin-top: rem(-4px);
        cursor: pointer;
    }
    &__close:focus-visible {
        @include outlineFocusVisible();
    }
}
