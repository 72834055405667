@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt212-card-lancio {
    $root: &;
    height: 100%;
    position: relative;
    width: 100%;
    flex: 0 0 auto;

    display: grid;
    grid-template-columns: auto;
    grid-template-areas:
        'title'
        'image'
        'text'
        'cta';

    @include respond-above(l) {
        margin-right: rem(80px);
        grid-template-columns: 55% 45%;
        grid-template-rows: auto;
        grid-template-areas:
            'title image'
            'text image'
            'cta image'
            '. image';
    }

    /* left */

    &__logo {
        height: rem(77px);
        object-fit: contain;
    }

    /* default texts style */

    &__title {
        grid-area: title;
    }

    &__titleText {
        max-width: 73%;
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
            max-width: 100%;
            padding-top: rem(13px);
        }
    }

    &__text {
        grid-area: text;
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-top: rem(16px);
        margin-bottom: rem(32px);

        @include respond-above(l) {
            margin-bottom: rem(36px);
            margin-top: 0;
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
        margin-bottom: rem(24px);
    }

    &__cta {
        grid-area: cta;
        margin-bottom: rem(24px);

        @include respond-below(l) {
            width: 100%;
            text-align: center;
            margin-bottom: 0;
        }
    }

    /* rigth */

    &__image.rt022-picture--cover {
        grid-area: image;
        height: rem(208px);
        border-radius: rem(12px);

        @include respond-above(m) {
            height: rem(280px);
        }
    }

    /* variant 2 */

    &--variant2 {
        margin-right: 0;
        width: 100%;
        display: grid;
        grid-template-columns: auto;
        grid-template-areas:
            'title'
            'text'
            'image'
            'cta';

        @include respond-above(l) {
            grid-template-columns: 55% 45%;
            grid-template-rows: auto;
            grid-template-areas:
                'image title'
                'image text'
                'image cta'
                'image .';
        }

        #{$root}__cta {
            @include respond-below(l) {
                margin-top: rem(32px);
            }
            margin-bottom: 0;
        }

        #{$root}__text {
            margin-top: rem(16px);
            margin-bottom: rem(32px);
        }

        #{$root}__image {
            @include respond-above(l) {
                width: 90%;
            }
        }
    }
}
