@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt208-small-articles {
    width: rem(344px);
    background-color: var(--color-white);
    border-radius: rem(12px);
    
    @include respond-above(l) {
        min-height: rem(400px);
    }

    &__container {
        padding-left: rem(16px);
        padding-top: rem(32px);
        padding-right: rem(16px);

        @include respond-above(l) {
            padding-left: rem(23.5px);
            padding-right: rem(24.5px);
        }
    }

    &__image {
        height: rem(192px);
        border-radius: rem(12px);

        @include respond-above(l) {
            width: rem(472px);
            height: rem(247px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);
    }

    &__articleTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: 1.5;
        font-weight: 500;
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        line-height: rem(16px);
        padding-bottom: rem(4px);
        padding-top: rem(16px);

        @include respond-above(l) {
            padding-top: rem(24px);
            padding-bottom: rem(16px);
        }
    }

    &__article {
        border-bottom: 1px solid lightness(--color-white, 90%);

        &:last-child {
            border-bottom: none;
        }
    }

    &__item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: rem(16px);

        @include respond-above(l) {
            padding-bottom: rem(24px);
        }

    }

    &__icon {
        font-size: rem(24px);
    }

}
