@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt135-insieme-cards-banner-cta {
    $wrapper-margin-top: rem(36px);
    @extend %container;

    @include respond-above(l) {
        display: flex;
        flex-flow: row-reverse;
        gap: rem(16px);
    }

    &__boxInsieme,
    &__boxInsiemePlus {
        position: relative;

        @include respond-above(l) {
            flex-grow: 1;
            width: 50%;
        }
    }
    &__boxInsieme + &__boxInsiemePlus,
    &__boxInsiemePlus + &__boxInsieme {
        margin-top: calc(#{$wrapper-margin-top} + 22px);

        @include respond-above(l) {
            margin-top: unset;
        }
    }
    
    &__image {
        height: rem(78px);
        width: rem(120px);
        background-size: contain;
        background-repeat: no-repeat;
        object-fit: contain;
        position: absolute;
        left: rem(16px);
        top: rem(-32px);

        @include respond-above(l) {
            top: 0;
        }
    }
    &__boxInsieme &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/insieme.png');
    }
    &__boxInsiemePlus &__image {
        background-image: url('#{$resourcesFolder}/corporate/icons/insiemepiu.png');
    }

    &__wrapperTop {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
    }

    &__wrapper {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        min-height: rem(257px);
        height: calc(100% - $wrapper-margin-top);
        gap: rem(16px);
        padding: rem(59px) rem(16px) rem(16px);
        margin-top: $wrapper-margin-top;
        border-radius: rem(12px);
        box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-bottom: rem(8px);
    }

    &__link {
        align-self: flex-end;
    }
}
