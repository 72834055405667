@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt072-disaggregated-block {
    padding-top: rem(29px);
    padding-bottom: rem(24px);

    &__top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: rem(24px);

        @include respond-above(l) { 
            margin-bottom: rem(16px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(18px);
        line-height: rem(24px);
        text-align: center;

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__image {
        width: rem(164px);
        align-self: center;
        margin-bottom: rem(20px);

        @include respond-above(l) {
            width: rem(144px);
            margin-bottom: rem(25px);
        }
    }

    &__wrapper {
        @extend %container;
        display: grid;
        grid-template-columns: repeat(2, 48%);
        grid-auto-flow: row dense;
        gap: rem(14px) rem(14px);

        @include respond-between(m, l) {
            grid-template-columns: repeat(2, 49%);
            grid-auto-flow: row dense;
            gap: rem(16px);
        }
        
        @include respond-above(l) {
            grid-template-columns: repeat(4, 24%);
            gap: rem(16px);
        }
    }

    &__loadMore {
        text-decoration: underline;
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        display: flex;
        justify-content: center;
        color: var(--color-blue);
        margin-top: rem(24px);
        cursor: pointer;
        text-underline-offset: rem(1.5px);

        &--hidden {
            display: none;
        }
    }

    &__loader {
        width: 100%;
        height: rem(60px);
        background-image: url('#{$resourcesFolder}/corporate/icons/loader.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(30px);

        &--hidden {
            visibility: hidden;
        }
    }

    &__card {
        min-width: rem(164px);
        min-height: rem(293px);

        @include respond-above(l) {
            min-width: rem(224px);
            min-height: rem(344px);
        }
    }
    
}
