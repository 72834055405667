@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt158-after-policy-activation {
    @extend %container;
    &__titleSuccess, &__titleGenericError, &__titlePolicyAlreadyActiveError, &__titleLightUserError {
        font-size: rem(28px);
        line-height: rem(30px);
        font-weight: 700;
    }

    &__textSuccess, &__textGenericError, &__textPolicyAlreadyActiveError, &__textLightUserError {
        margin-top: rem(25px);
        font-size: rem(16px);
        line-height: rem(25px);
        @apply [&_a]:underline;
        @apply [&_a]:text-[--color-semantic4];
    }

    &__ctaSuccess, &__ctaGenericError, &__ctaPolicyAlreadyActiveError, &__ctaLightUserError {
        margin-top: rem(40px);
        margin-bottom: rem(40px);
        display: none;
        text-align: center;
        margin-left: auto;
        &--show {
            display: block;
        }
    }

    &__textSuccessBox {
        background-color: var(--color-secondary3);
        border-radius: rem(12px);
        padding: rem(16px);
        margin-top: rem(30px);
        width: 100%;
    }
}
