@use '../modules/themes' as *;
@use '../modules/mixins' as *;
@use '../modules/functions' as *;
@use 'sass:map';


[data-theme='alimentum'],
body.theme-alimentum {
    @include defineColorsHexMap(map.merge($default-theme, $alimentum-theme));
}
