@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt119-slider-card-elev-news {
    @extend %container;

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(28px);
        line-height: rem(30px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(34px);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
            margin-bottom: rem(30px);
        }
    }
    &--separator &__title {
        @include addSeparator();
    }

    &__carousel {
        overflow: hidden;

        @include respond-above(l) {
            padding-left: 2px;
            margin-left: -2px;
            padding-right: 2px;
            margin-right: -2px;
        }
    }

    &__slideContainer[data-variant='small'] {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;

        @include respond-below(l) {
            margin-bottom: rem(16px);
        }

        @include respond-above(l) {
            width: 23.8%;
        }
    }

    &__slideContainer[data-variant='large'] {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;

        @include respond-below(l) {
            margin-bottom: rem(16px);
        }

        @include respond-above(l) {
            width: 100%;
        }
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 1px;

        @include respond-below(m) {
            padding-left: 1px;
            padding-right: 1px;
        }
        
        @include respond-above(m) {
            flex-direction: row;
        }
        
        @include respond-above(l) {
            margin: 0 auto;
            transition-property: transform;
            box-sizing: content-box;
        }
    }

    &__slide.rt201-card-elev-news {
        width: 100%;
    }

    &__slideContainer {
        display: flex;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;

        @include respond-below(m) {
            /* !important needed to override style attribute put by Swiper */
            margin-right: 0 !important;
        }

        @include respond-below(l) {
            margin-bottom: rem(16px);
        }

        @include respond-above(l) {
            width: 32.2%;
        }
    }
}
