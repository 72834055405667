@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt152-switch-component {
    $root: &;
    &__container {
        @extend %container;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(28px);

        @include respond-above(l) {
            height: rem(48px);
        }
        @include respond-below(l) {
            flex-direction: column-reverse;
        }
    }

    &__tabs {
        @include respond-below(l) {
            justify-content: center;
            margin-bottom: rem(25px);
        }
    }

    &__productsTitle {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__productsNumberContainer {
        display: flex;
        gap: rem(5px);
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        margin-top: rem(8px);
    }

    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include respond-above(l) {
            opacity: 0;
            width: 0;
            height: 0;
            pointer-events: none;
        }

        &--hidden {
            display: none;
        }
    }

    &__panel {
        &--hidden {
            display: none;
        }
    }

    &__ctaIcon {
        font-size: rem(24px);
        margin-bottom: rem(8px);
    }

    &__mob {
        @include respond-below(l) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }

    &__filterLabel {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        white-space: nowrap;
    }

    &__productsTitle {
        @extend %dmSansFontRegular; 
        
        &--invisible {
            visibility: hidden;  
        }

        &--hidden {
            display: none;
        }
    }

    &__pdfTitle {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);

        &--hidden {
            display: none;
        }
    }
}
