@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt103-categories-map {
    width: 100%;
    @extend %container;

    &__separator {
        @extend %separator;
        margin-top: rem(24px);
        margin-bottom: rem(24px);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__columnTitle {
        @extend %dmSansFontBold;
        font-size: rem(18px);
        line-height: rem(24px);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__columnItem {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: alpha(--color-black, 0.8);

        @include respond-above(l) {
            font-size: rem(14px);
            line-height: rem(20px);
        }
    }

    &__columnItems {
        display: flex;
        flex-direction: column;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        gap: rem(20px) rem(15px);

        @include respond-above(l) {
            gap: rem(20px) rem(16px);
        }
    }

    &__column {
        width: 47.78555%;
        @include respond-above(l) {
            margin-bottom: rem(16px);
            width: 23.73%;
        }
    }
}
