@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt136-strillo-icon-richtext {
    --color-banner-text: var(--color-darkCharcoal);
    background-color: var(--color-secondary1);

    &__wrapper {
        @extend %container;
        display: flex;
        flex-flow: column;
        gap: rem(24px);
        padding-top: rem(24px);
        padding-bottom: rem(24px);

        @include respond-above(l) {
            padding-top: rem(32px);
            padding-bottom: rem(30px);
        }
    }

    &__wrapper > div:first-child {
        display: flex;
        align-items: center;
        gap: rem(12px);

        @include respond-above(l) {
            gap: rem(24px);
            align-items: unset;
        }
    }
    
    &__icon {
        flex-shrink: 0;
        align-self: flex-start;
        display: inline-block;
        font-size: rem(40px);
        color: var(--color-banner-text);
        padding: rem(20px);
        border-radius: 50%;
        background-color: var(--color-white);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        padding-bottom: rem(8px);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-banner-text);
    }
    /* mobile */
    &__wrapper > &__text {
        @include respond-above(l) {
            display: none;
        }
    }
    /* desktop */
    &__wrapper > div:first-child &__text {
        @include respond-below(l) {
            display: none;
        }
    }
}
