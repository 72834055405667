@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt214-card-mdd {
    border-radius: rem(12px);
    background-color: var(--color-white);
    padding: rem(16px);
    width: rem(204px);
    min-height: rem(128px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    @include respond-above(l) {
        width: rem(224px);
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__logo {
        width: rem(129px);
        height: rem(48px);
        object-fit: contain;
        align-self: center;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        padding-top: rem(16px);
        text-align: center;
        color: var(--color-darkCharcoal);
    }
}
