@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner-hsd {
    @include respond-above(l) {
        padding-top: rem(29px);
        padding-bottom: rem(23px);
    }
    
    &__findStoreSideText {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-banner-text);
        margin-bottom: rem(32px);
        max-width: rem(470px);
    }

    &__address {
        background-color: var(--color-white);
    }
}
