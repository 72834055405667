@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt028-dynamic-image {
    background-color: transparent;
    position: relative;
    width: 100%;
    height: 950px;

    &--flex {
        display: flex;
        background-color: unset;
        position: unset;
        width: unset;
        height: unset;
    }

    &__wrapper {
        background-color: transparent;
        position: relative;
        width: 560px;
        height: 950px;
    }

    &__container {
        width: 100%;
        height: 100%;
    }

    &__hotspotCard {
        display: none;

        &.show {
            display: block;
        }
    }

    .s7interactiveimage canvas {
        cursor: default;
    }

    // zoom view style (override)
    .s7interactiveimage .s7zoomview {
        background-color: transparent;
    }

    // hotspot style (override)
    .s7interactiveimage .s7imagemapeffect .s7icon {
        width: rem(32px);
        height: rem(32px);
        opacity: 1;
        background-image: none;
        background-color: alpha(--color-darkCharcoal, 80%);
        border-radius: 50%;
        
        &::after {
            content: ' ';
            display: block;
            width: rem(8px);
            height: rem(8px);
            margin: rem(12px);
            background-color: white;
            border-radius: 50%;
        }

        &.open::after {
            background-color: var(--color-primary2);
        }
    }
    .s7interactiveimage .s7imagemapeffect .s7icon:hover {
        background-image: none;

        &::after {
            background-color: var(--color-primary2);
        }
    }
}
