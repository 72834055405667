@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt036-feedback {
    $root: &;
    @extend %container;

    &__wrapper {
        position: relative;
        min-height: rem(56px);
        display: flex;
        gap: rem(16px);
        border-radius: rem(12px);
        padding: rem(16px);

        @include respond-above(l) {
            padding: rem(8px) rem(16px);
            align-items: center;
        }
    }

    &__icon {
        width: rem(16px);
        height: rem(16px);
        background-repeat: no-repeat;
        background-size: rem(16px);
        flex-shrink: 0;
        order: 1;
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        flex-grow: 1;
        order: 2;
    }
    &__cta ~ &__text {
        @include respond-below(l) {
            margin-bottom: rem(56px);
        }
    }

    &__cta {
        height: fit-content;
        flex-shrink: 0;
        order: 3;

        @include respond-below(l) {
            position: absolute;
            right: rem(16px);
            bottom: rem(16px);
        }
    }

    &__close {
        font-size: rem(16px);
        cursor: pointer;
        order: 4;
    }

    /* success */
    &[data-semantic-type='1'] {

        #{$root}__wrapper {
            background-color: lightness(--color-semantic1, 94.9%);
        }

        #{$root}__icon {
            background-image: url('#{$resourcesFolder}/corporate/icons/checked-green.svg');
        }

        #{$root}__text {
            color: var(--color-semantic1);
        }
    }

    /* error */
    &[data-semantic-type='2'] {

        #{$root}__wrapper {
            background-color: lightness(--color-semantic2, 94.9%);
        }

        #{$root}__icon {
            background-image: url('#{$resourcesFolder}/corporate/icons/alert-red.svg');
        }

        #{$root}__text {
            color: var(--color-semantic2);
        }
    }

    /* warning */
    &[data-semantic-type='3'] {

        #{$root}__wrapper {
            background-color: lightness(--color-semantic3, 94.9%);
        }

        #{$root}__icon {
            background-image: url('#{$resourcesFolder}/corporate/icons/alert-yellow.svg');
        }

        #{$root}__text {
            color: var(--color-semantic3);
        }
    }

    /* info */
    &[data-semantic-type='4'] {

        #{$root}__wrapper {
            background-color: lightness(--color-semantic4, 94.9%);
        }

        #{$root}__icon {
            background-image: url('#{$resourcesFolder}/corporate/icons/info.svg');
        }

        #{$root}__text {
            color: var(--color-semantic4);
        }
    }
}
