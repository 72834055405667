@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.ru003-popup {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: setZ(popup);
    pointer-events: none;

    &__content {
        position: relative;
        margin: 0 rem(5px) 0 rem(5px);
        max-height: 95vh;

        @include respond-above(l) {
            margin: unset;
        }

        &--animate {
            animation: zoomIn 0.5s;
        }
    }

    &__backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: alpha(--color-black, 0.6);
        display: block;
        z-index: setZ(negative);
        opacity: 0;

        &--unclosable {
            pointer-events: none;
        }
    }

    &--open {
        display: flex;
        pointer-events: all;
    }

    &--open &__backdrop {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
}
