@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;
@use '../../../../../../style/modules/mixins' as *;

.rl2-anchors-layout {
    $anchors-width: rem(225px);
    @extend %container;
    display: flex;
    flex-flow: column;
    
    @include respond-above(l) {
        position: relative;
    }

    &__titles {
        @include respond-above(l) {
            margin-left: $anchors-width;
            margin-bottom: rem(32px);
        }
    }

    &__main {
        @include respond-above(l) {
            display: flex;
        }
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        color: lightness(--color-black, 20%);
        margin-top: rem(40px);
        
        @include respond-above(l) {
            font-size: rem(48px);
            line-height: rem(56px);
        }

        &::after {
            display: block;
            content: ' ';
            width: 32px;
            height: 4px;
            border-radius: 4px;
            background-color: var(--color-primary2);
            margin-top: rem(10px);
        }
    }

    &__subtitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
    }
    /* subtitle desktop */
    &__titles &__subtitle {
        margin-top: rem(16px);

        @include respond-below(l) {
            display: none;
        }
    }
    /* subtitle mobile */
    &__main &__subtitle {
        margin-bottom: rem(16px);

        @include respond-above(l) {
            display: none;
        }
    }

    &__anchors {
        flex-shrink: 0;
        margin: rem(32px) 0;

        @include respond-above(l) {
            width: $anchors-width;
            margin: unset;
        }
    }

    &__components {
        flex-grow: 1;
    }

    &__title ~ &__anchors,
    &__title ~ &__components {
        @include respond-above(l) {
            margin-top: rem(146px);
        }
    }

    &__item {
        &--spaced-top-none {
            padding-top: 0;
        }

        &--spaced-top {
            padding-top: rem(32px);
        }

        &--spaced-bottom {
            padding-bottom: rem(32px);
        }

        &--spaced-bottom-none {
            padding-bottom: 0;
        }

        &--spaced-top-2x {
            padding-top: rem(64px);
        }

        &--spaced-bottom-2x {
            padding-bottom: rem(64px);
        }
    }
}
