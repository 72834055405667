@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner-fmf {
    $root: &;
    
    &[data-flyer-variant='1'] {
        height: 100%;
        display: flex;
        flex-flow: column;

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(14px);
            color: var(--color-banner-text);

            @include respond-above(l) {
                font-size: rem(16px);
                line-height: rem(16px);
            }
        }

        #{$root}__name {
            @extend %dmSansFontBold;
            font-size: rem(20px);
            line-height: rem(24px);
            color: var(--color-banner-text);
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: rem(16px);
            margin-bottom: rem(8px);

            @include respond-above(l) {
                font-size: rem(28px);
                line-height: rem(36px);
                margin-top: rem(20px);
                margin-bottom: rem(10px);
            }
        }

        #{$root}__mobLink {
            @include respond-above(l) {
                display: none;
            }
        }

        #{$root}__address {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: var(--color-banner-text);

            @include respond-above(l) {
                font-size: rem(16px);
                line-height: rem(24px);
                margin-bottom: rem(16px);
            }
        }

        #{$root}__link {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-banner-text);
            white-space: nowrap;
            margin-top: auto;

            @include respond-below(l) {
                display: none;
            }
        }

        #{$root}__icon {
            flex-shrink: 0;
            align-self: flex-start;
            display: none;
            font-size: rem(48px);
            color: var(--color-banner-text);
            padding: rem(20px);
            border-radius: 50%;
            background-color: var(--color-white);
            box-shadow: 0 1px 1px 0 alpha(--color-darkCharcoal, 0.15),
                        0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
            margin-bottom: rem(16px);

            @include respond-above(l) {
                display: inline-block;
            }
        }
    }

    &[data-flyer-variant='2'] {
        height: 100%;

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(20px);
            line-height: rem(28px);
            color: var(--color-banner-text);
        }

        #{$root}__text {
            @extend %dmSansFontRegular;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-banner-text);
            margin-top: rem(8px);

            @include respond-above(l) {
                margin-top: rem(8px);
            }
        }

        #{$root}__icon {
            display: none;
            width: rem(80px);
            height: rem(80px);
            background-image: url('#{$resourcesFolder}/corporate/icons/bassiefissi.jpg');
            background-size: rem(52px) rem(52px);
            background-repeat: no-repeat;
            background-position: center;
            padding: rem(14px);
            background-color: var(--color-white);
            border-radius: 50%;
            margin-bottom: rem(16px);

            @include respond-above(l) {
                display: block;
            }
        }
    }

    &[data-flyer-variant='3'] {
        height: 100%;
        display: flex;
        flex-flow: column;

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(20px);
            line-height: rem(28px);
            color: var(--color-banner-text);

            @include respond-above(l) {
                font-size: rem(24px);
                line-height: rem(32px);
            }
        }

        #{$root}__link {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-banner-text);
            white-space: nowrap;
            margin-top: auto;
        }

        #{$root}__viewAll {
            margin-top: rem(16px);
        }

        #{$root}__icon {
            flex-shrink: 0;
            align-self: flex-start;
            display: none;
            font-size: rem(48px);
            color: var(--color-banner-text);
            padding: rem(16px);
            border-radius: 50%;
            background-color: var(--color-white);
            box-shadow: 0 1px 1px 0 alpha(--color-darkCharcoal, 0.15),
                        0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
            margin-bottom: rem(16px);

            @include respond-above(l) {
                display: inline-block;
            }
        }
    }
}
