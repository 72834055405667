@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt100-hero-carta {
    @extend %container;
    display: flex;
    flex-direction: column;

    @include respond-above(l) {
        flex-direction: row;
    }

    @include respond-below(l) {
        padding: 0;
        margin: 0;
    }

    /* default texts style */
    &__title {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        font-weight: 500;
        max-width: 73%;
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(40px);
            line-height: rem(48px);
            max-width: 100%;
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        color: var(--color-primaryGray);
        font-size: rem(16px);
        line-height: rem(24px);
    }

    // Left section
    &__left {
        width: 100%;
        @extend %container;
        flex: 0 0 auto;

        @include respond-above(l) {
            width: 45%;
            padding: 0;
            margin: 0;
            margin-right: rem(80px);
        }
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(24px);
        margin-top: rem(16px);
    }

    &__cta {
        margin-top: rem(8px);
    }

    // right section
    &__right {
        flex: 1 1 auto;

        @include respond-above(l) {
            height: 100%;
        }
    }

    &__image.rt022-picture--cover {
        height: rem(208px);

        @include respond-above(m) {
            height: rem(352px);
            border-radius: rem(12px);
        }
    }

    &__insieme,
    &__insiemepiu {
        width: rem(80px);
        height: rem(50px);
        background-size: cover;
    }

    &__insieme {
        background-image: url('#{$resourcesFolder}/corporate/icons/insieme.png');
    }

    &__insiemepiu {
        background-image: url('#{$resourcesFolder}/corporate/icons/insiemepiu.png');
    }

    &__card {
        margin-bottom: rem(22px);
        width: rem(80px);
        height: rem(50px);
    }

    &__showMore {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        margin-top:rem(16px);
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
        
        @include respond-below(l) {
            margin-top:rem(24px);
            margin-bottom: rem(24px);
        }
    }

    &__icon {
        margin-left: rem(8px);
    }
}
