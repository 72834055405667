@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt038-category-filter {
    $root: &;
    width: rem(95px);
    height: rem(120px);
    padding: rem(16px);
    padding-bottom: rem(28px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: rem(12px);
    border: solid rem(1px) var(--color-grayscale10);
    cursor: pointer;

    &__icon {
        width: rem(80px);
        height: rem(63px);
        display: flex;
        justify-content: center;
        font-size: rem(45px);
        color: var(--color-grayscale10);
    }

    &__name {
        @extend %dmSansFontBold;
        font-size: rem(12px);
        line-height: rem(12px);
        text-align: center;
        color: var(--color-darkCharcoal);
    }

    &__input {
        display: none;
    }

    &--validState {
        //box-shadow: 0 0 10px -2px #f38933;
        border-radius: rem(12px);
        box-shadow: 0 0 8px 0px alpha(--color-primary1, 0.9);
        border: 1px solid alpha(--color-primary1, 0.05);
        
        #{$root}__icon {
            color: var(--color-darkCharcoal);
        }
    }
}
