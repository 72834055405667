@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt140-hero-premio {
    padding-top: rem(34px);
    padding-bottom: rem(43px);
    position: relative;
    min-height: 518px;
    display: flex;
    flex-flow: column;
    
    @include respond-above(l) {
        padding-bottom: unset;
        min-height: 376px;
    }

    &__bgImage {
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0 0 0 0;
        max-width: map-get($map: $breakpoints, $key: xl);
        margin: 0 auto;
    }

    &__wrapper {
       @extend %container;

    }

    &__wrapperTextImage {
        display: flex;
        flex-flow: column;
        gap: rem(24px);
        flex-grow: 1;

        @include respond-above(l) {
            flex-flow: row;
            gap: unset;
        }
    }

    &__text {
        position: relative;

        @include respond-above(l) {
            flex-grow: 1;
        }
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        color: var(--color-darkCharcoal);
        margin-top: rem(36px);

        @include respond-above(l) {
            margin-top: rem(50px);
        }
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(20px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        margin-top: rem(20px);
        margin-bottom: rem(40px);

        @include respond-above(l) {
            margin-top: rem(16px);
        }
    }

    &__bolliniText {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        margin-bottom: rem(8px);
    }

    &__pointsLabel {
        @extend %dmSansFontMedium;
        font-size: rem(10px);
        line-height: rem(12px);
        color: var(--color-primaryGray);
    }

    &__pointsText {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%); 
        padding-bottom: rem(21px);
    
    }

    &__logo1 {
        max-width: rem(102px);
        height: auto;
    }
    /* logo1 desktop*/
    &__text &__logo1 {
        margin-top: rem(13px);
        margin-bottom: rem(21px);

        @include respond-below(l) {
            display: none;
        }
    }
    /* logo1 mobile */
    &__logos &__logo1 {
        @include respond-above(l) {
            display: none;
        }
    }

    &__images {
        @include respond-above(l) {
            display: flex;
            flex-flow: row-reverse;
        }
    }

    &__logos {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include respond-above(l) {
            justify-content: flex-end;
            align-self: flex-start;
        }
    }

    &__logo2 {
        max-width: rem(102px);
        height: auto;
    }

    &__thumbnail {
        max-width: rem(218px);
        height: auto;
        margin: rem(9px) auto 0;

        @include respond-above(l) {
            max-width: rem(344px);
            margin: unset;
            align-self: flex-end;
        }
    }
    &--padThumbRightDsk &__thumbnail {
        @include respond-above(l) {
            margin-right: rem(95px);
        }
    }

    &__texts {
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: unset;
        }
    }
    &__title + &__texts {
        margin-top: rem(16px);
    }

    &__text1, 
    &__text2 {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        //color: lightness(--color-black, 20%);
    }
    &__divider {
        width: 144px;
        height: 1px;
        background-color: lightness(--color-black, 20%);
        margin: rem(16px) 0;

        @include respond-above(l) {
            margin: rem(8px) 0;
        }
    }
    &__cta {
        margin-top: rem(16px);

        @include respond-above(l) {
            margin-top: rem(32px);
        }
        &--hidden {
            display: none;
        }
    }
    &__suitable {
        @extend %dmSansFontBold; 
        background-color: #deeacc; 
        color: #689f13;
        border-radius: rem(4px); 
        gap: rem(8px); 
        font-size: rem(18px); 
        line-height: 1;
        display: flex; 
        align-items: center;
        align-self: flex-end; 
        padding-left: rem(8px); 
        padding-right: rem(18px); 
        padding-top: rem(4px); 
        padding-bottom: rem(4px); 
        
        &--invisible {
            visibility: hidden; 
        }
    }
}
