@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt017-accordion {
    $root: &;
    $transition-time: .5s;

    background-color: var(--color-white);
    padding-left: rem(16px);
    padding-right: rem(16px);
    
    &__heading {
        display: flex;
        align-items: center;
        width: 100%;
        height: rem(40px);
        cursor: pointer;
    }
    &__heading:focus-visible {
        @include outlineFocusVisible();
    }
    &--alt &__heading {
        height: rem(72px);
    }

    &__title {
        flex-grow: 1;
        @extend %dmSansFontMedium;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-darkCharcoal);
    }
    &--alt &__title {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
    }

    &__badge {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-black);
        display: none;
        height: rem(24px);
        padding-top: rem(5px);
        padding-right: rem(16px);
        padding-bottom: rem(5px);
        padding-left: rem(16px);
        background-color: alpha(--color-darkCharcoal, 0.05);
        border-radius: 12px;
        margin-right: rem(16px);
    }
    &--alt &__badge {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-white);
        text-align: center;
        width: rem(24px);
        border-radius: 50%;
        background-color: var(--color-primary1);
        padding-top: rem(6px);
        padding-right: rem(6px);
        padding-bottom: rem(6px);
        padding-left: rem(6px);
    }
    &--badge &__badge {
        display: inline-block;
    }

    &__arrow {
        font-size: rem(24px);
        transition: transform $transition-time ease-in-out;
    }
    &--open > &__heading > &__arrow {
        transform: rotateX(180deg);
    }

    &__content {
        padding-top: 0;
        padding-bottom: 0;
        overflow-y: hidden;
        visibility: hidden;
        pointer-events: none;
        max-height: 1px;
        transition: visibility $transition-time ease-in-out,
                    max-height $transition-time ease-in-out,
                    padding-top .4s ease-in-out,
                    padding-bottom .4s ease-in-out;
    }
    &--open > &__content {
        pointer-events: all;
        padding-top: rem(8px);
        padding-bottom: rem(16px);
    }

    &__separator {
        height: 1px;
        border-bottom: 1px solid lightness(--color-white, 90%);
    }
}
