@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt205-card-elev-brands {
    width: rem(343px);
    min-height: rem(360px);
    border-radius: rem(12px);
    background-color: var(--color-white);
    margin-bottom: rem(8px);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    
    @include respond-above(l) {
        width:100%;
        min-height: rem(376px);
    }

    &__image.rt022-picture--cover {
        height: rem(256px);
        border-top-left-radius: rem(12px);
        border-top-right-radius: rem(12px);
    }

    &__title {
        padding: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            padding: rem(32px) rem(24px) rem(32px) rem(32px);
        }
    }

    &__media {
        position: relative;
    }

    &__logoCont {
        display: flex;
        justify-content: center;
    }
    
    &__logo {
        position: absolute;
        width: rem(152px);
        height: auto;
        max-height: rem(66px);
        top: rem(24px);
        right: auto;
        object-fit: contain;

        @include respond-above(l) {
            justify-self: center;
        }
    }
}
