@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt215-card-flyer {
    $root: &;
    display: flex;
    justify-content: stretch;
    gap: rem(16px);
    background-color: var(--color-white);
    border-radius: rem(12px);
    padding: rem(8px);

    /* variant: 1 (desktop large), 2 (desktop + mobile), 3 (small) */

    &[data-variant='1'] {
        position: relative;

        #{$root}__image {
            flex-shrink: 0;
            width: rem(62px);
            height: rem(72px);
            object-fit: contain;
            align-self: center;
        }

        #{$root}__info {
            flex-grow: 1;
            flex-basis: auto;
            display: flex;
            flex-flow: column;
            gap: rem(8px);
        }

        #{$root}__validity {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: lightness(--color-black, 20%);
            flex-shrink: 0;
        }

        #{$root}__download {
            font-size: rem(14px);
            cursor: pointer;
            position: absolute;
            top: calc(50% - 20px);
            left: rem(19px);
            padding: rem(13px);
            background-color: alpha(--color-white, 80%);
            border-radius: 50%;
        }

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(16px);
            line-height: rem(24px);
            color: lightness(--color-black, 20%);
            flex-grow: 1;
            word-break: break-all;
        }
        
        #{$root}__validity ~ #{$root}__title {
            margin-top: rem(-4px);
        }

        #{$root}__link {
            flex-shrink: 0;
            font-size: rem(12px);
            line-height: rem(16px);
            white-space: nowrap;
        }
    }
    &--dskEnabled[data-variant='1'] {
        @include respond-above(l) {
            flex-flow: column;
            min-width: rem(224px);
            padding: rem(16px);

            #{$root}__image {
                width: rem(102px);
                height: rem(100px);
            }
            #{$root}__download {
                font-size: rem(24px);
                top: rem(16px);
                right: rem(16px);
                left: unset;
                padding: unset;
                border-radius: unset;
                background-color: unset;
            }
            #{$root}__title {
                margin-top: unset;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
            #{$root}__info {
                gap: rem(8px);
            }
        }
    }

    &[data-variant='2'] {
        #{$root}__image {
            flex-shrink: 0;
            width: rem(62px);
            height: rem(72px);
            object-fit: contain;
        }

        #{$root}__info {
            flex-grow: 1;
            flex-basis: auto;
            display: flex;
            flex-flow: column;
        }

        #{$root}__validity {
            @extend %dmSansFontRegular;
            font-size: rem(10px);
            line-height: rem(14px);
            color: lightness(--color-black, 20%);
            flex-shrink: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        #{$root}__download {
            font-size: rem(14px);
            cursor: pointer;
        }

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            color: lightness(--color-black, 20%);
            margin-top: rem(4px);
            flex-grow: 1;
        }

        #{$root}__link {
            flex-shrink: 0;
            font-size: rem(12px);
            line-height: rem(16px);
            white-space: nowrap;
        }
    }
    &--dskEnabled[data-variant='2'] {
        @include respond-above(l) {
            flex-shrink: 0;
            max-width: rem(304px);
            padding: rem(16px);

            #{$root}__image {
                width: rem(90px);
                height: rem(100px);
            }
            #{$root}__validity {
                font-size: rem(12px);
                line-height: rem(16px);
            }
            #{$root}__title {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }
    }

    &[data-variant='3'] {
        #{$root}__image {
            flex-shrink: 0;
            width: rem(62px);
            height: rem(72px);
            object-fit: contain;
        }

        #{$root}__info {
            flex-grow: 1;
            flex-basis: auto;
            display: flex;
            flex-flow: column;
        }

        #{$root}__validity {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: lightness(--color-black, 20%);
            flex-shrink: 0;
        }

        #{$root}__download {
            display: none;
        }

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(14px);
            line-height: rem(20px);
            color: lightness(--color-black, 20%);
            margin-top: rem(4px);
            flex-grow: 1;
        }

        #{$root}__link {
            display: none;
        }
    }
}
