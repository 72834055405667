@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/global/animations' as *;
@use '../../../../../style/modules/mixins' as *;

.rt021-store-card {
    $root: &;
    border-radius: rem(12px);
    flex-shrink: 0;
    pointer-events: all;

    &--selected {
        box-shadow: 0 0 10px -2px alpha(--color-primary1, 0.6);
    }

    &__content {
        display: flex;
        gap: rem(30px);
        background-color: var(--color-white);
        padding: rem(16px);
        border-top-left-radius: rem(12px);
        border-top-right-radius: rem(12px);
    }
    &__content:last-child {
        border-bottom-left-radius: rem(12px);
        border-bottom-right-radius: rem(12px);
    }

    &__left::after {
        content: '';
        display: block;
        height: rem(80px);
        background-image: url('#{$resourcesFolder}/corporate/icons/store-gray.svg');
        background-size: rem(80px);
        background-repeat: no-repeat;
        min-width: rem(80px);
    }
    &--selected &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/store-orange.svg');
    }

    /* store types */
    &[data-store-type='eye'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/eye-gray.svg');
    }
    &[data-store-type='pharmacy'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/pharmacy-gray.svg');
    }
    &[data-store-type='fuel'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/fuel-gray.svg');
    }
    &[data-store-type='pet'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/pet-gray.svg');
    }
    /* store types selected */
    &--selected[data-store-type='eye'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/eye-orange.svg');
    }
    &--selected[data-store-type='pharmacy'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/pharmacy-orange.svg');
    }
    &--selected[data-store-type='fuel'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/fuel-orange.svg');
    }
    &--selected[data-store-type='pet'] &__left::after {
        background-image: url('#{$resourcesFolder}/corporate/icons/pet-orange.svg');
    }
    
    &__distance {
        white-space: nowrap;
    }

    &__right {
        flex-grow: 1;
    }

    &__heading {
        display: flex;
        position: relative;
        align-items: flex-start;
    }

    &__name {
        font-weight: 500;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-black);
        text-decoration: underline;
        text-decoration-color: var(--color-black);
        flex-grow: 1;
    }
    &__name:hover {
        color: lightness(--color-black, 20%);
    }
    &--simplified &__name {
        flex-grow: unset;
        white-space: nowrap;
    }

    &__favorite {
        @extend %ecIcon;
        flex-shrink: 0;
        font-size: rem(24px);
        color: var(--color-primary1);
        cursor: pointer;
        display: none;

        &::before {
            content: $ec-icon-like-off;
        }

        &--show {
            display: block;
        }
    }

    &--favorite &__favoriteBadge{
        display: block;
    }

    &--favorite &__favorite{
        display: none;
    }

    &--savedDisabled &__favorite{
        color: #929292;
    }

    &--saved &__favorite::before {
        content: $ec-icon-like-on;
    }
    &__favorite:hover {
        color: alpha(--color-primary1, 0.9);
    }
    &--favorite &__favorite:hover {
        color: var(--color-primary1);
        cursor: default;
    }

    &__favoriteBadge{
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: var(--color-primary1);
        background-color: rgb(254,240,229);
        padding: rem(5px) rem(16px);
        border-radius: 12px;

        display: none;
    }

    &__tooltip {
        opacity: 0;
        position: absolute;
        bottom: calc(100% + 5px);
        right: -16px;
        padding: 1px 8px 3px;
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0 0 10px -2px alpha(--color-black, 0.3);
        pointer-events: none;

        /* tip */
        & > div:last-child {
            background-color: var(--color-white);
            width: 7px;
            height: 7px;
            transform: rotate(45deg);
            position: absolute;
            bottom: -2px;
            right: 24px;
        }
    }

    &__tooltipText {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-primary1);
        white-space: nowrap;
    }

    &__tooltipMaxStores {
        opacity: 0;
        position: absolute;
        width: rem(193px);
        bottom: calc(100% + 5px);
        height: fit-content;
        right: -16px;
        padding: 1px 8px 3px;
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0 0 10px -2px alpha(--color-black, 0.3);
        pointer-events: none;

        /* tip */
        & > div:last-child {
            background-color: var(--color-white);
            width: 7px;
            height: 7px;
            transform: rotate(45deg);
            position: absolute;
            bottom: -2px;
            right: 24px;
        }
    }

    &--firstOne &__tooltipMaxStores {
        top: calc(100% + 5px);
        bottom: 0;

        & > div:last-child {
            top: -2px;
            bottom: 0;
        }
    }

    &__tooltipMaxStoresText {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(16px);
        color: #808080;
        white-space: break-spaces;
    }

    &__tooltipMaxStoresLink {
        display: inline;
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-semantic4);
        text-decoration: underline;
    }

    &--showTooltip &__tooltip {
        animation: opacityOnOff 3s normal forwards;
        animation-delay: 0.2s;
    }

    &--showTooltipMaxStores &__tooltipMaxStores {
        opacity: 1;
        pointer-events: all;
    }

    &--showTooltipMaxStores &__tooltipMaxStores > a{
        z-index:10;
        pointer-events: all;
    }

    &__popup {
        opacity: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: calc(100% + 4px);
        right: -13px;
        width: rem(239px);
        height: fit-content;
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0 0 10px -2px alpha(--color-black, 0.3);
        z-index: 30;
        pointer-events: none;

        /* tip */
        & > button:last-child {
            padding-top: rem(11px);
            padding-bottom: rem(16px);
            border-style: none;
        }
    }

    &--showPopup &__popup {
        display: block;
        animation: enterRight 0.2s ease-in forwards;
        pointer-events: all;
    }

    &__popupItem {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(14px);
        padding: rem(16px) rem(8px) rem(11px) rem(8px);
        margin: rem(0px) rem(8px) rem(0px) rem(8px);
        border-style: solid;
        border-width: rem(0px) rem(0px) rem(1px) rem(0px);
        border-color: #d6d6d6;
        color: var(--color-semantic4);
        cursor: pointer;
    }

    &__address {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
    }

    &__opening {
        display: flex;
        gap: 8px;
        align-items: center;
        margin: rem(16px) 0 rem(20px);

        & > span:first-of-type {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: var(--color-primaryGray);
        }
    }

    &__services {
        display: flex;
        align-items: center;
        padding: 0 rem(16px) rem(10px);
        background-color: var(--color-white);
        margin-top: rem(-24px);

        /* label "in store" */
        & > span:first-of-type {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: lightness(--color-black, 20%);
            margin-right: rem(35px);
            flex-shrink: 0;
        }
    }
    &__services:last-child {
        border-bottom-left-radius: rem(12px);
        border-bottom-right-radius: rem(12px);
    }

    /* service images */
    &__serviceImage {
        display: block;
        height: 0;
        width: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    /* parafarmacia */
    &__serviceImage[data-service='1'],
    &__serviceImage[data-service='2'],
    &__serviceImage[data-service='29'],
    &__serviceImage[data-service='54'] {
        height: rem(28px);
        width: rem(78px);
        background-image: url('#{$resourcesFolder}/corporate/icons/pharmacy-pos.png');
    }
    /* ottico */
    &__serviceImage[data-service='3'],
    &__serviceImage[data-service='4'],
    &__serviceImage[data-service='28'],
    &__serviceImage[data-service='55']  {
        height: rem(28px);
        width: rem(78px);
        background-image: url('#{$resourcesFolder}/corporate/icons/eye.png');
    }
    /* pet store */
    &__serviceImage[data-service='33'] {
        height: rem(28px);
        width: rem(78px);
        background-image: url('#{$resourcesFolder}/corporate/icons/pet-store.png');
    }
    /* carburante */
    &__serviceImage[data-service='5'] {
        height: rem(28px);
        width: rem(78px);
        background-image: url('#{$resourcesFolder}/corporate/icons/fuel.png');
    }

    &__flyers {
        overflow: hidden;
        border-bottom-left-radius: rem(12px);
        border-bottom-right-radius: rem(12px);
    }

    &__flyersHeading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: rem(56px);
        width: 100%;
        background-color: alpha(--color-semantic3, 0.2);
        padding: rem(21px) rem(16px);
        cursor: pointer;

        & > span:last-of-type {
            @extend %ecIcon;
            flex-shrink: 0;
            font-size: rem(16px);
            font-weight: 600;
            color: lightness(--color-black, 20%);

            &::before {
                content: $ec-icon-chevron-down;
            }
        }
    }
    &__flyersHeading:focus-visible {
        @include outlineFocusVisible(-4px);
    }
    &__flyers--open > &__flyersHeading > span:last-of-type::before {
        content: $ec-icon-chevron-up;
    }

    &__flyersLabel {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(14px);
        color: lightness(--color-black, 20%);
    }

    &__flyersContent {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        background-color: var(--color-white);
        padding-left: rem(24px);
        padding-right: rem(24px);
        visibility: hidden;
        max-height: 0;
        transition: max-height .2s ease-in-out;
    }
    &__flyers--open > &__flyersContent {
        max-height: 1000px;
    }

    &__flyer {
        display: flex;
        justify-content: stretch;
        gap: rem(16px);

        &:first-child {
            margin-top: rem(14px);
        }
        &:last-child {
            margin-bottom: rem(14px);
        }
    }

    &__flyerImage {
        flex-shrink: 0;
        width: rem(62px);
        height: rem(72px);
        object-fit: contain;
    }

    &__flyerInfo {
        flex-grow: 1;
        flex-basis: auto;
        display: flex;
        flex-flow: column;
    }

    &__flyerValidity {
        @extend %dmSansFontRegular;
        font-size: rem(10px);
        line-height: rem(14px);
        color: lightness(--color-black, 20%);
        flex-shrink: 0;
    }

    &__flyerTitle {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        margin-top: rem(4px);
        flex-grow: 1;
    }

    &__flyerLink {
        flex-shrink: 0;
        width: 100%;
        font-size: rem(12px);
        line-height: rem(16px);
    }
}