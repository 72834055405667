@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt102-carrot-banner-fsf {
    $root: &;
    
    &[data-flyer-variant='1'],
    &[data-flyer-variant='2'],
    &[data-flyer-variant='3'] {

        #{$root}__top {
            display: flex;
            gap: rem(16px);
            padding-top: rem(16px);
            padding-bottom: rem(16px);

            @include respond-above(l) {
                padding-top: unset;
                padding-bottom: unset;
            }
        }

        #{$root}__top > img {
            flex-shrink: 0;
            width: rem(124px);
            height: rem(138px);
            object-fit: contain;

            @include respond-above(l) {
                width: rem(141px);
                height: rem(157px);
            }
        }

        #{$root}__top > div:first-of-type {
            flex-grow: 1;
            display: flex;
            flex-flow: column;
        }

        #{$root}__validity {
            @extend %dmSansFontMedium;
            font-size: rem(12px);
            line-height: rem(12px);
            color: var(--color-banner-text);
        }

        #{$root}__title {
            @extend %dmSansFontBold;
            font-size: rem(28px);
            line-height: rem(30px);
            color: var(--color-banner-text);
            margin: rem(4px) 0;

            @include respond-above(l) {
                font-size: rem(32px);
                line-height: rem(40px);
            }

            @include respond-below(m) {
                word-break: break-word;
            }
        }

        #{$root}__ctas {
            display: flex;
            flex-flow: row wrap;
            margin-top: auto;
        }

        #{$root}__link {
            @extend %dmSansFontMedium;
            font-size: rem(14px);
            line-height: rem(18px);
            color: var(--color-banner-text);
        }
        #{$root}__link:hover {
            color: var(--color-banner-text);
        }

        #{$root}__download,
        #{$root}__share {
            color: var(--color-banner-text);
            flex: 1 0 auto;
            display: flex;
            gap: rem(10px);

            & > span:first-child {
                font-size: rem(16px);
            }
            
            .ec-icon-share {
                font-weight: bold;
            }
        }
        #{$root}__share {
            position: relative;
        }
    }

    &[data-flyer-variant='1'] {
        #{$root}__bottom {
            border-top: 1px solid var(--color-orange);

            @include respond-above(l) {
                margin-top: rem(20px);
            }
        }

        #{$root}__heading {
            display: flex;
            cursor: pointer;
            padding-top: rem(16px);
            padding-bottom: rem(16px);

            @include respond-above(l) {
                cursor: default;
                padding-bottom: rem(8px);
            }
        }
        #{$root}__flyersHeading {
            @extend %dmSansFontBold;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-banner-text);
            flex-grow: 1;
        }

        #{$root}__acc,
        #{$root}__prev,
        #{$root}__next {
            color: var(--color-banner-text);
        }

        #{$root}__acc {
            font-size: rem(24px);
            transition: transform .2s ease-in-out;

            @include respond-above(l) {
                display: none;
            }
        }
        #{$root}__bottom--flyersOpen #{$root}__acc {
            transform: rotateX(180deg);
        }

        #{$root}__prev,
        #{$root}__next {
            font-size: rem(16px);
            cursor: pointer;

            @include respond-below(l) {
                display: none;
            }

            &--disabled {
                cursor: default;
                pointer-events: none;
                opacity: 0.5;
            }
        }
        #{$root}__prev {
            margin-left: auto;
        }
        #{$root}__next {
            margin-left: rem(16px);
        }

        #{$root}__flyers {
            overflow: hidden;

            @include respond-below(l) {
                display: flex;
                flex-flow: column;
                max-height: 0;
                transition: max-height .2s ease-in-out;
            }
        }
        #{$root}__bottom--flyersOpen #{$root}__flyers {
            @include respond-below(l) {
                max-height: 550px;
            }
        }

        #{$root}__carousel {
            width: 100%;
        }

        #{$root}__track {
            display: flex;
            flex-flow: row;
            width: 100%;
            
            @include respond-below(l) {
                gap: rem(16px);
                flex-flow: column;
            }
        }

        #{$root}__flyer {
            flex-shrink: 0;

            @include respond-below(l) {
                /* !important needed to override style attribute put by Swiper */
                margin-right: 2px !important;

                &:last-child {
                    margin-bottom: rem(16px);
                }
            }
        }
        #{$root}__flyer--hidden {
            @include respond-below(l) {
                display: none;
            }
        }

        #{$root}__loadMore {
            @extend %dmSansFontMedium;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-banner-text);
            margin-top: rem(16px);
            margin-bottom: rem(16px);
            align-self: center;
    
            @include respond-above(l) {
                display: none;
            }
        }
        #{$root}__loadMore:hover {
            color: var(--color-banner-text);
        }
        #{$root}__loadMore--hidden {
            display: none;
        }
    }
    &--noSwipe[data-flyer-variant='1'] {
        @include respond-above(l) {
            #{$root}__prev,
            #{$root}__next {
                display: none;
            }

            #{$root}__track {
                gap: rem(16px);
            }
        }
    }

    &[data-flyer-variant='2'] {
        #{$root}__bottom {
            border-top: 1px solid var(--color-orange);
            margin-top: rem(6px);

            @include respond-above(l) {
                margin-top: rem(24px);
            }
        }

        #{$root}__heading {
            display: flex;
            cursor: pointer;
            padding-top: rem(16px);
            padding-bottom: rem(16px);

            @include respond-above(l) {
                display: none;
            }
        }
        #{$root}__flyersHeading,
        #{$root}__readMore {
            @extend %dmSansFontBold;
            font-size: rem(16px);
            line-height: rem(24px);
            color: var(--color-banner-text);
            flex-grow: 1;
        }

        #{$root}__acc {
            font-size: rem(24px);
            color: var(--color-banner-text);
            transition: transform .2s ease-in-out;
        }
        #{$root}__bottom--textOpen #{$root}__acc {
            transform: rotateX(180deg);
        }

        #{$root}__readMoreText {
            @extend %dmSansFontRegular;
            font-size: rem(14px);
            line-height: rem(20px);
            color: var(--color-banner-text);

            @include respond-below(l) {
                overflow: hidden;
                max-height: 0;
                transition: all .2s ease-in-out;
            }

            @include respond-above(l) {
                margin-top: rem(16px);
            }
        }
        #{$root}__bottom--textOpen #{$root}__readMoreText {
            @include respond-below(l) {
                padding-bottom: rem(16px);
            }
        }
    }
}
