@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt004-radio {
    position: relative;

    &__label {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        position: relative;
        width: fit-content;
    }

    &__radios {
        display: flex;
        flex-flow: row wrap;
        gap: rem(20px) rem(16px);
        margin-top: rem(17px);
    }

    &__radioLabel {
        position: relative;
        cursor: pointer;
        padding-left: rem(16px);
    }
    &__radioLabel--disabled {
        pointer-events: none;
    }
    &__radioLabel--disabled > &__inputLabel::before {
        opacity: 0.2;
    }

    &__input {
        opacity: 0;
        position: absolute;
        cursor: pointer;
    }
    &__empty {
        display: none;
        pointer-events: none;
    }

    &__inputLabel {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        padding-left: rem(12px);

        &::before, &::after {
            content: '';
            position: absolute;
            vertical-align: middle;
            border-radius: 50%;
        }
        &::before {
            display: inline-block;
            width: rem(20px);
            height: rem(20px);
            top: rem(1px);
            left: 0;
            border: 2px solid;
            border-color: alpha(--color-darkCharcoal, 0.4);
        }
        &::after {
            display: none;
            width: rem(12px);
            height: rem(12px);
            top: rem(5px);
            left: rem(4px);
            border: 1px solid;
            border-color: alpha(--color-primary1, 0.6);
            background-color: var(--color-primary1);
        }
        &:hover::before {
            border-color: alpha(--color-primary1, 0.6);
        }
    }
    &__input:checked + &__inputLabel::after {
        display: block;
    }
    &__input:checked + &__inputLabel::before {
        border-color: alpha(--color-primary1, 0.8);
    }

    &--errorState &__inputLabel::before {
        border-color: var(--color-semantic2);
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        align-self: flex-start;
        padding-top: 3px;
        position: absolute;
        bottom: rem(-16px);
        display: none;
        user-select: none;
    }

    &--errorState &__error {
        display: inline;
    }
}
