@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt150-disaggregated-flyer {
    &--hidden {
        opacity: 0;
        width: 0;
        height: 0;
        pointer-events: none;
    }

    &[data-filters-in-page] &__filtersWrapper {
        margin-bottom: rem(9px);

        @include respond-above(l) {
            margin-bottom: rem(40px);
        }
    }

    &__orderByWrapper {
        @extend %container;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: rem;
    }

    &__labelOrderBy {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: lightness(--color-black, 60%);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: none;
        text-align: left;
        padding-top: rem(8px);

        @include respond-above(m) {
            display: block;
            flex: 0 0 auto;
        }
    }

    &__registerBox {
        @extend %container;
        padding-top: rem(29px);
        transition:opacity 1s;

        &--hide {
            opacity: 0;
            min-height: 400px;
        }

        &--remove {
            min-height: 0;
            padding: 0;
            height: 0;
            transition: min-height 500ms ease-in-out;
        }

        &--wrapperEl {
            display: flex;
            flex-direction: column;
            grid-column: span 1;
            justify-content: space-between;
            background-color: var(--color-white);
            border-radius: rem(12px);
            padding: rem(25px) rem(30px);
            position: relative;

            @include respond-above(l) {
                padding: rem(35px) rem(50px);
                flex-direction: row;
                align-items: center;
            }

            &:before {
                content: '';
                position: absolute;
                top: rem(-0.5px);
                right: 0;
                bottom: rem(-0.5px);
                left: 0;
                z-index: -1;
                margin: rem(-1.5px) rem(-2px);
                border-radius: inherit;
                background: linear-gradient(to bottom right, var(--color-primary2), var(--color-primary1), var(--color-redPromotion));

                @include respond-above(l) {
                    top: rem(-1px);
                    bottom: rem(-1px);
                    margin: rem(-1px) rem(-2px);
                }
            }

            .rt150-disaggregated-flyer__registerBox-title {
                @extend %dmSansFontBold;
                margin-bottom: rem(4px);
                max-width: 73%;
                font-size: rem(24px);
                line-height: rem(32px);

                @include respond-above(m) {
                    width: rem(190px);
                    margin-bottom: rem(14px);
                }

                &--hide {
                    visibility: hidden;
                }
            }

            .rt150-disaggregated-flyer__registerBox-separator {
                @extend %separator;
                margin-bottom: rem(5px);
                border-radius: rem(4px);
            }

            .rt150-disaggregated-flyer__registerBox-text {
                @extend %dmSansFontRegular;
                font-size: rem(16px);
                line-height: rem(24px);
                color: var(--color-primaryGray);
                margin-bottom: rem(17px);

                @include respond-above(l) {
                    width: rem(290px);
                    margin-bottom: 0;
                }

                &--hide {
                    visibility: hidden;
                }
            }

            .rt150-disaggregated-flyer__registerBox-cta.rt002-cta {
                &--hide {
                    visibility: hidden;
                }
            }

            .rt150-disaggregated-flyer__registerBox-cta {
                &--hide {
                    visibility: hidden;
                }
            }
        }

        &--wrapperSuggestedText {
            .rt150-disaggregated-flyer__registerBox-title {
                @extend %dmSansFontBold;
                margin-bottom: rem(16px);
                padding-top: rem(16px);
                max-width: 73%;
                font-size: rem(28px);
                line-height: rem(30px);

                @include respond-above(m) {
                    max-width: 100%;
                    font-size: rem(32px);
                    line-height: rem(40px);
                }

                &--hide {
                    visibility: hidden;
                }
            }

            .rt150-disaggregated-flyer__registerBox-separator {
                @extend %separator;
                margin-bottom: rem(40px);
                border-radius: rem(4px);

                @include respond-above(l) {
                    margin-bottom: rem(24px);
                }

            }

            .rt150-disaggregated-flyer__registerBox-text {
                margin-bottom: rem(32px);
                @extend %dmSansFontRegular;
                font-size: rem(16px);
                line-height: rem(24px);
                color: var(--color-primaryGray);

                &--hide {
                    visibility: hidden;
                }
            }

            .rt150-disaggregated-flyer__registerBox-cta.rt002-cta {
                margin-bottom: rem(24px);

                &--hide {
                    visibility: hidden;
                }
            }

            .rt150-disaggregated-flyer__registerBox-cta {
                &--hide {
                    visibility: hidden;
                }
            }
        }

        &--productsSuggested {
            display: grid;
            grid-template-columns: repeat(2, 48%);
            grid-auto-flow: row dense;
            gap: rem(14px) rem(14px);
            margin-top: rem(40px);

            @include respond-between(m, l) {
                grid-template-columns: repeat(2, 49%);
                grid-auto-flow: row dense;
                gap: rem(16px);
            }

            @include respond-above(l) {
                grid-template-columns: repeat(4, 24%);
                gap: rem(16px);
            }
        }

        &-product {
            min-width: rem(164px);
            min-height: rem(293px);
            position: relative;

            @include respond-above(l) {
                min-width: rem(224px);
                min-height: rem(344px);
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-top: rem(29px);

        &--grid {
            @extend %container;
            display: grid;
            grid-template-columns: repeat(2, 49% [col-start]);
            grid-auto-flow: row dense;
            gap: rem(14.4px) rem(14.4px);

            @include respond-between(m, l) {
                grid-template-columns: repeat(2, 49%);
                grid-auto-flow: row dense;
                gap: rem(16px);
            }

            @include respond-above(l) {
                grid-template-columns: repeat(4, 24%);
                gap: rem(16px);
            }
        }
    }

    &__selectOrderBy {
        flex: 0 0 auto;
        width: auto;
    }

    &__loader {
        width: 100%;
        height: rem(100px);
        background-image: url('#{$resourcesFolder}/corporate/icons/loader.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem(50px);

        &--hidden {
            visibility: hidden;
        }
    }

    &__block[data-promo-name="ND"]:first-child {
        padding-top: rem(0px);
    }
}
