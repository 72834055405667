@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;


.rt007-label-mdd {
    background-color: var(--color-primary1);
    border-radius: 8px;

    @extend %dmSansFontBold;
    font-size: rem(16px);
    line-height: rem(16px);
    color: var(--color-neutral);
    padding: rem(8px);
    width: fit-content;
}
