@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt058-store-info {
    @extend %container;

    &__title,
    &__titleSer,
    &__titleRep {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(24px);
            line-height: rem(32px);
        }
    }

    &__titleSpec {
        @extend %dmSansFontBold;
        font-size: rem(20px);
        line-height: rem(28px);
        color: var(--color-darkCharcoal);
    }

    &__arrow {
        font-size: rem(24px);
        transition: transform 0.3s ease-in-out;
        &--close {
            transform: rotateX(180deg);
        }
    }

    &__arrowMob {
        display: block;
        @include respond-above(l) {
            display: none;
        }
    }

    &__orario {
        padding-bottom: rem(16px);
        border-bottom: 1px solid lightness(--color-white, 90%);
        margin-bottom: rem(16px);

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__heading,
    &__orario {
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__heading {
        justify-content: space-between;

        &--close {
            padding-bottom: rem(24px);
            border-bottom: 1px solid lightness(--color-white, 90%);
        }
    }

    &__headingSub {
        @include respond-above(l) {
            cursor: default;
        }

        &--close {
            @include respond-below(l) {
                padding-bottom: rem(24px);
                border-bottom: 1px solid lightness(--color-white, 90%);
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-top: rem(24px);
        border-bottom: 1px solid lightness(--color-white, 90%);

        @include respond-above(l) {
            flex-direction: row;
            justify-content: space-between;
        }

        &--close {
            display: none;
        }
    }

    &__orarioCont {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include respond-above(l) {
            width: 50%;
            padding-right: rem(30px);
        }
    }

    &__orarioContSpec {
        margin-top: rem(24px);
    }

    &__orariStraordinari {
        width: 100%;
        margin-top: rem(32px);

        @include respond-above(l) {
            width: 50%;
            margin-top: 0;
            margin-left: rem(51px);
        }
    }

    &__giornoLabel {
        width: 100%;
        text-transform: capitalize;
        @include respond-above(l) {
            width: rem(224px);
        }
    }

    &__pomeriggio {
        margin-left: rem(24px);
    }

    &__contentSub {
        flex-wrap: wrap;
        border-bottom: none;
        row-gap: rem(16px);

        &--close {
            @include respond-below(l) {
                display: none;
            }
        }
    }

    &__orarioLabel {
        white-space: nowrap;
        @include respond-above(l) {
            margin-right: rem(27px);
        }
    }

    &__item {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-darkCharcoal);
        display: flex;
        flex-direction: row;
        align-items: center;

        @include respond-above(l) {
            font-size: rem(11px);
            width: calc(50% - 8px);
            line-height: rem(16px);
        }
    }

    &__icon {
        margin-right: rem(8px);
        font-size: rem(24px);
    }

    &__servizi {
        margin-top: rem(24px);
        @include respond-above(l) {
            width: 50%;
            margin-top: 0;
            padding-right: rem(30px);
        }

        &--border {
            @include respond-above(l) {
                border-right: 1px solid lightness(--color-white, 90%);
            }
        }
    }

    &__reparti {
        margin-top: rem(24px);

        @include respond-above(l) {
            width: 50%;
            margin-top: 0;
            margin-left: rem(51px);
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            flex-direction: row;
            min-height: rem(225px);
            margin-top: rem(60px);
            justify-content: space-between;
        }
    }

    &__feedback,
    &__feedbackMob {
        margin: 0;
        padding: 0;
        margin-bottom: rem(24px);
    }

    &__feedback {
        @include respond-below(l) {
            display: none;
        }
    }

    &__feedbackMob {
        display: none;

        @include respond-below(l) {
            display: block;
        }
    }
}
