@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt304-refresh-confirm-popup {
    @include actionPopup();

    @include respond-above(m) {
        max-width: rem(720px);
        width: rem(720px);
    }

    @include respond-above(l) {
        max-width: rem(794px);
        width: rem(794px);
    }

    &__topper {
        display: flex;
        flex-flow: column;
        padding-left: rem(40px);
        padding-right: rem(40px);
        padding-top: rem(24px);
    }

    &__title {
        font-weight: bold;
        @extend %font-heading3;
        padding-top: rem(24px);
        padding-left: 0;
        padding-right: 0;
    }

    &__text {
        padding-left: 0;
    }

    &__bottom {
        padding-left: rem(40px);
        padding-right: rem(40px);
        padding-bottom: rem(20px);
        margin-top: rem(70px);
        display: flex;
        flex-flow: column;
        gap: rem(20px);
        justify-content: flex-end;

        @include respond-above(m) {
            flex-flow: row;
        }
    }

    &__ctaAction {
        @extend %buttonReset;
        background-color: #ffffff;
        border-radius: rem(12px);
        border: solid rem(2px) #f06c00;
        font-size: rem(16px);
        line-height: rem(16px);
        letter-spacing: normal;
        padding: rem(20px) rem(32px);
        color: var(--color-darkCharcoal);
        margin-bottom: 0;

        @include respond-above(m) {
            margin-bottom: rem(24px);
        }
    }

    &__ctaCancel {
        @extend %buttonReset;
        background-color: #eb5305;
        padding: rem(20px) rem(32px);
        border-radius: rem(12px);
        font-size: rem(16px);
        line-height: rem(16px);
        letter-spacing: normal;
        text-decoration: none;
        color: #ffffff;

        @include respond-above(m) {
            margin-bottom: rem(24px);
        }
    }
}
