@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt030-hotspot-card {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    width: rem(186px);
    padding: rem(16px);
    background-color: var(--color-white);
    box-shadow: 0 8px 16px -4px alpha(--color-darkCharcoal, 0.15), 
                0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    border-radius: 12px;
    z-index: setZ(default);

    &--show {
        visibility: visible;
        pointer-events: all;
    }

    a {
        @extend %dmSansFontMedium;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-semantic4);
        text-decoration: underline;
        cursor: pointer;
    }
}
