@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt059-advice {
    @extend %container;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-above(l) {
        width: 68%;
        max-width: rem(968px);
    }

    &__separator {
        @extend %separator;
        margin-bottom: rem(24px);
    }

    /* default texts style */

    &__text {
        margin-bottom: rem(24px);
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            margin-bottom: rem(16px);
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__title {
        @extend %robotoFontMedium;
        font-size: rem(32px);
        line-height: rem(40px);
        margin-bottom: rem(16px);
        color: var(--color-darkCharcoal);
    }

    &__ctaBookmark,
    &__ctaShare {
        border-radius: 50%;
        display: inline-block;
        color: var(--color-bookmark);
        box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
        &:hover {
            background-color: #ffffff;
        }
    }

    &__ctaShare {
        .rt002-cta__icon {
            font-weight: 800;
        }
    }
    
    &__image.rt022-picture--cover {
        width: 100%;
        height: rem(275px);
        border-radius: rem(12px);
        margin-bottom: rem(24px);

        @include respond-above(xl) {
            height: rem(450px);
        }
    }

    &__ctaBookmark {
        margin-right: rem(10px);
    }

    &__ctas {
        display: flex;
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(16px);
    }

    &__pencil {
        color: var(--color-grayscale10);
        margin-right: rem(4px);
    }

    &__category {
        @extend %dmSansFontBold;
        color: var(--color-bookmark);
        font-size: rem(12px);
        font-weight: 500;
        line-height: rem(16px);
        margin-bottom: rem(18px);

        @include respond-above(l) {
            font-size: rem(14px);
            line-height: rem(14px);
        }
    }

    &__authorDate {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-darkCharcoal);
    }

    &__authorName {
        font-weight: 500;
    }

    &__date {
        font-weight: 200;
        color: var(--color-primaryGray);
    }

    &__articleBody {
        color: var(--color-darkCharcoal);
    }
}
