@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt020-map-info-window {
    $root: &;

    &__heading {
        display: flex;
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        flex-grow: 1;
    }

    &__favorite {
        @extend %ecIcon;
        flex-shrink: 0;
        font-size: rem(24px);
        color: var(--color-primary1);
        cursor: pointer;

        &::before {
            content: $ec-icon-like-off;
        }
    }
    &--favorite &__favorite::before {
        content: $ec-icon-like-on;
    }
    &__favorite:hover {
        color: alpha(--color-primary1, 0.9);
    }
    &--favorite &__favorite:hover {
        color: var(--color-primary1);
        cursor: default;
    }

    &__subtitle {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        margin: rem(5px) 0 rem(8px);
    }

    &__directions {
        display: flex;
        gap: 8px;
        align-items: center;

        & > span:first-of-type {
            font-size: rem(16px);
        }
    }

    &__directionsLink {
        flex-grow: 1;
        font-weight: 500;
        font-size: rem(12px);
        line-height: rem(16px);
        color: var(--color-black);
        text-decoration: underline;
        text-decoration-color: var(--color-black);
    }
    &__directionsLink:hover {
        color: lightness(--color-black, 20%);
    }

    &__opening {
        display: flex;
        gap: 8px;
        align-items: center;
        margin-top: rem(16px);
        margin-bottom: rem(8px);

        @include respond-above(m) {
            margin-bottom: rem(20px);
        }

        & > span:first-of-type {
            @extend %dmSansFontRegular;
            font-size: rem(12px);
            line-height: rem(16px);
            color: var(--color-primaryGray);
        }
    }
    &--simplified &__opening {
        margin-bottom: unset;
    }

    &__ctas {
        display: flex;
        flex-flow: row-reverse;
        gap: 8px;
    }

    &__flyerLink {
        flex-grow: 1;
        white-space: nowrap;
    }

    &__poiLink {
        white-space: nowrap;
        margin-right: auto;
    }

    &__flyerLink + &__poiLink {
        flex-grow: 1;
        margin-right: unset;
    }

    @include respond-below(l) {
        &--mixed &__ctas {
            flex-flow: column-reverse;
            align-items: stretch;
            text-align: center;
        }

        &--mixed &__poiLink,
        &--mixed &__flyerLink {
            margin-right: 0;
            width: 100%;
        }
    }
}

div.gm-style-iw-tc {
    display: none;
}

/* hide default infoWindow elements */
div.gm-style-iw-t {
    &::after {
        visibility: hidden;
        pointer-events: none;
    }

    & button[type='button'] {
        visibility: hidden;
        pointer-events: none;
    }
}

/* adjust padding */
div.gm-style-iw-d {
    padding: 12px;
    overflow: hidden !important;

    @include respond-above(l) {
        padding-right: 12px;
    }
}

div.gm-style-iw-chr {
    display: none;
}

div.gm-style-iw-c {
    padding: 0 !important;
}
