@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt254-filters {
    @include modal(&, true);

    &--accOpen &__topBar {
        display: none;
    }
    &--accOpen &__wrapper {
        margin-top: rem(16px);
        margin-bottom: rem(16px);
    }

    h3#{&}__title {
        font-size: rem(20px);
        line-height: rem(24px);
    }

    &__wrapper {
        margin-bottom: rem(31px);

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            width: 4px;
            background-color: alpha(--color-black, 0.2);
            margin-top: 0;
            margin-bottom: 0;
            margin-right: rem(52px);
            opacity: 0.6;
            border-radius: 2px;
            visibility: hidden;
            pointer-events: none;

            @include respond-above(l) {
                visibility: visible;
                pointer-events: all;
            }

            & > .ps__thumb-y {
                width: 4px;
                right: 0;
                background-color: var(--color-black);
                border-radius: 2px;
            }
        }
    }

    &__accordion {
        border: 1px solid alpha(--color-darkCharcoal, 30%);
        border-radius: rem(16px);
        background-color: var(--color-white);
        overflow: hidden;
        margin-bottom: rem(8px);
        transition: all .2s ease-in-out;
    }
    &__accordion--open {
        position: absolute;
        inset: 0 rem(16px);
        border-color: var(--color-black);
        display: flex;
        flex-flow: column;
    }

    &__heading {
        @extend %dmSansFontMedium;
        font-size: rem(17px);
        line-height: rem(17px);
        color: lightness(--color-black, 20%);
        padding: rem(16px);
        display: flex;
        align-items: center;
        cursor: pointer;

        & > span:first-child {
            flex-grow: 1;
        }
        & > span + span:not(:last-child) {
            flex-shrink: 0;
            font-size: rem(12px);
            line-height: rem(12px);
            color: var(--color-white);
            display: none;
            width: rem(24px);
            height: rem(24px);
            background-color: var(--color-primary1);
            border-radius: 50%;
            padding: rem(6px) 0;
            text-align: center;
            margin-right: rem(10px);
        }
        & > span:last-child {
            flex-shrink: 0;
            font-size: rem(24px);
            transition: transform .2s ease-in-out;
        }
    }
    &__accordion--showCount &__heading > span + span:not(:last-child) {
        display: block;
    }
    &__accordion--open {
        margin-bottom: unset;
    }
    &__accordion--open &__heading > span:last-child {
        transform: rotateX(180deg);
    }

    &__content {
        max-height: 0;
        pointer-events: none;
        display: flex;
        flex-flow: column;
        transition: all .2s ease-in-out;
    }
    &__accordion--open &__content {
        pointer-events: all;
        max-height: 100%;
        flex-grow: 1;
    }

    &__form {
        display: flex;
        flex-flow: column;
        gap: rem(16px);
        padding: 1px rem(16px) rem(16px);
        transition: all .2s ease-in-out;
        position: relative;
        overflow: hidden;

        /* adjust scrollbar style */
        &.ps > .ps__rail-y {
            display: none;
        }
    }
    &__accordion--open &__form {
        flex-grow: 1;
    }

    &__ctas {
        flex-shrink: 0;
        display: none;
        justify-content: flex-end;
        align-items: center;
        gap: rem(16px);
        padding: rem(20px) rem(16px) rem(16px);
        background-color: var(--color-white);
        box-shadow: 0 -4px 8px -2px alpha(--color-darkCharcoal, 0.15), 
                    0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }
    &__accordion--open &__ctas {
        display: flex;
    }
}
