@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt106-lancio {
    $root: &;
    @extend %container;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;

    @include respond-above(l) {
        //min-height: rem(352px);
        flex-direction: row;
    }

    /* left */

    &__logo {
        height: rem(54px);
        height: fit-content;
        width: fit-content;
        margin-bottom: rem(8px);

        @include respond-above(l) {
            height: rem(63px);
        }
    }

    /* default texts style */
    &__title {
        grid-area: title;
    }

    &__titleText {
        @extend %dmSansFontBold;
        font-size: rem(24px);
        line-height: rem(32px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(32px);
            line-height: rem(40px);
            max-width: 100%;
        }
    }

    &__text {
        grid-area: text;
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-primaryGray);
        margin-top: rem(32px);

        @include respond-above(l) {
            margin-top: rem(24px);
        }
    }

    &__separator {
        @extend %separator;
        margin-top: rem(16px);
    }

    &__cta {
        grid-area: cta;
        margin-top: rem(40px);
        height: fit-content;

        @include respond-below(l) {
            text-align: center;
            width: 100%;
        }
    }

    &__left {
        width: 100%;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        @include respond-above(l) {
            width: 41%;
        }
    }

    &__imageMob,
    &__videoMob {
        height: rem(208px);
        border-radius: rem(12px);
        margin-top: rem(24px);

        @include respond-above(l) {
            display: none;
        }
    }

    /* rigth */
    &__image,
    &__video {
        width: 49.153%;
        border-radius: rem(12px);
        height: rem(280px);
        margin-bottom: rem(16px);
        align-self: center;
        margin-top: 0;

        @include respond-below(l) {
            display: none;
        }
    }

    &__right {
        display: flex;
    }

    /* slider */

    &__carousel {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: 0 auto;
    }

    &__slideContainer {
        width: 100%;
        height: auto;
        position: relative;
        flex-shrink: 0;
        transition-property: transform;
    }

    &__slide {
        display: flex;
        width: 100%;
        height: 100%;

        @include respond-below(l) {
            flex-direction: column;
            padding: 0;
        }

        @include respond-above(l) {
            justify-content: space-between;
        }
    }

    &__track {
        width: 100%;
        height: 100%;
        display: flex;
        transition-property: transform;

        &--vertical3 {
            @include respond-above(l) {
                flex-direction: column;
                height: auto;
            }
        }

        &--vertical4 {
            @include respond-above(l) {
                flex-direction: column;
                height: auto;
            }
        }
    }

    &__pagination {
        grid-area: pagination;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: rem(24px);

        @include respond-above(l) {
            margin-top: 0;
            margin-left: 0;
            margin-right: 23%;
            justify-content: flex-end;
        }

        &--hidden {
            display: none;
        }

        &--vertical {
            @include respond-above(l) {
                position: absolute;
                right: 4%;
                bottom: 55%;
                grid-area: pagination;
                flex-direction: column;
                margin-top: 0;
                margin-right: 0;
            }
        }

        &--variant2 {
            @include respond-above(l) {
                justify-content: flex-start;
                margin-left: 23%;
                margin-right: 0;
            }
        }
    }

    button#{$root}__bullet:focus-visible {
        outline: 1px solid black;
        outline-offset: 1px;
    }
    &__bullet {
        position: relative;
        flex: 0 0 auto;
        height: rem(16px);
        width: rem(16px);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem(14px);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: rem(4px);
            width: rem(4px);
            background-color: var(--color-black);
            border-radius: 50%;
        }

        &--active::after {
            height: rem(16px);
            width: rem(16px);
            background-color: var(--color-primary2);
        }
    }

    button#{$root}__bulletVertical:focus-visible {
        outline: 1px solid black;
        outline-offset: 1px;
    }
    &__bulletVertical {
        position: relative;
        flex: 0 0 auto;
        height: rem(16px);
        width: rem(16px);
        cursor: pointer;

        &:not(:last-child) {
            margin-right: rem(14px);
            @include respond-above(l) {
                margin-right: 0;
                margin-bottom: rem(14px);
            }
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: rem(4px);
            width: rem(4px);
            background-color: var(--color-black);
            border-radius: 50%;
        }

        &--active::after {
            height: rem(16px);
            width: rem(16px);
            background-color: var(--color-primary2);
        }
    }

    /* variant 2 */

    &__slide--variant2 {
        @include respond-above(l) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        #{$root}__left {
            width: 100%;

            @include respond-above(l) {
                width: 41%;
            }
        }

        #{$root}__title {
            margin-right: 0;
        }

        #{$root}__text {
            margin-right: 0;
        }

        #{$root}__cta {
        }

        #{$root}__image,
        #{$root}__video {
            @include respond-above(l) {
                width: 49.153%;
                align-self: center;
            }
        }
    }

    /* variant 3 and variant 5*/

    &__slide--variant3,
    &__slide--variant5 {
        align-items: center;
        flex-direction: column-reverse;
        @include respond-above(l) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        #{$root}__left {
            display: flex;
            flex-direction: column;

            @include respond-above(l) {
                width: 41%;
            }

            &--pagination {
                @include respond-above(l) {
                    margin-right: rem(50px);
                }
            }
        }

        #{$root}__title,
        #{$root}__text {
            margin-right: 0;
        }

        #{$root}__right {
            width: 100%;
            height: fit-content;

            @include respond-above(l) {
                width: 52%;
            }
        }

        #{$root}__dynamic {
            @include respond-below(l) {
                height: rem(593px);
            }
        }
    }

    /* variant 4 */

    &__slide--variant4 {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        #{$root}__left {
            width: 41%;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            height: fit-content;

            @include respond-below(l) {
                width: 100%;
            }

            &--pagination {
                @include respond-above(l) {
                    margin-right: rem(50px);
                }
            }
        }

        #{$root}__right {
            @include respond-below(l) {
                height: 0;
                opacity: 0;
                pointer-events: none;
            }

            @include respond-above(l) {
                width: 50%;
                position: relative;
                min-height: rem(540px);
            }
        }

        #{$root}__rightMob {
            @include respond-below(l) {
                min-height: rem(284px);
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: rem(24px);
            }

            @include respond-above(l) {
                height: 0;
                opacity: 0;
                pointer-events: none;
            }
        }

        #{$root}__prodContainer {
            margin-right: rem(16px);

            @include respond-above(l) {
                position: absolute;
                top: 0;
                left: 0;
            }

            &:nth-child(2n) {
                @include respond-above(l) {
                    left: rem(240px);
                    top: rem(100px);
                }

                @include respond-above(xl) {
                    left: rem(316px);
                }
            }
        }

        #{$root}__prod {
            @include respond-above(xl) {
                width: rem(300px);
            }
        }

        #{$root}__text {
            margin-right: rem(16px);
        }

        #{$root}__cta {
            flex-shrink: 0;
        }
    }
}
