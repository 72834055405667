@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/functions' as *;

.rt014-file-upload {
    $root: &;

    position: relative;
    overflow: hidden;
    width: 100%;

    &--iconLeft {
        #{$root}__title {
            flex-flow: row-reverse;

            & > span:first-of-type {
                margin-right: rem(4px);
            }
        }
    }

    &--iconRight {
        #{$root}__title {
            flex-flow: row;

            & > span:first-of-type {
                margin-left: rem(4px);
            }
        }
    }

    &--complex {
        #{$root}__text,
        #{$root}__supportText {
            display: block;
        }
        #{$root}__title {
            @extend %dmSansFontRegular;
        }
        #{$root}__title > span:last-child {
            display: none;
        }
        #{$root}__text {
            @extend %dmSansFontBold;
            font-size: rem(12px);
            line-height: rem(16px);
        }
        #{$root}__supportText {
            @extend %dmSansFontRegular;
        }
    }

    &__label {
        background-color: var(--color-white);
        border-radius: rem(12px);
        border-width: 1px;
        border-style: dotted;
        border-color: alpha(--color-darkCharcoal, 0.3);
        display: flex;
        flex-flow: column;
        align-items: center;
        cursor: pointer;
        padding: rem(32px);
    }
    &__label:hover {
        background-color: alpha(--color-white, 0.9);
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-darkCharcoal);
        display: flex;
        align-items: center;

        & > span:first-of-type {
            font-size: rem(16px);
        }
    }

    &__text {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);
        color: lightness(--color-black, 20%);
        padding: rem(6px) 0 rem(46.5px);
        display: none;
    }

    &__preview:first-child {
        margin-top: rem(16px);
    }

    &__supportText {
        @extend %dmSansFontBold;
        font-size: rem(14px);
        line-height: rem(20px);
        color: lightness(--color-black, 20%);
        display: none;

        & > span {
            position: relative;
            bottom: -2px;
            font-size: rem(16px);
            margin-right: rem(4px);
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
        width: 100%;
        left: 0;
        display: block;
        pointer-events: none;
    }

    &__fileUploaded {
        padding: rem(16px) 0 rem(16px);
        border-bottom: 1px solid alpha(--color-darkCharcoal, 0.3);

        &--invalid #{$root}__icon {
            display: inline-block;
            width: rem(16px);
            height: rem(16px);
            background-image: url('#{$resourcesFolder}/corporate/icons/alert-red.svg');
            background-repeat: no-repeat;
            background-size: rem(16px);
        }
        &--invalid #{$root}__icon::before {
            display: none;
        }
        &--invalid #{$root}__error {
            display: inline;
        }
    }

    &__fileUploaded > p {
        display: flex;
        align-items: center;

        #{$root}__icon {
            font-size: rem(16px);
            margin-right: rem(8px);
        }
        #{$root}__remove {
            font-size: rem(24px);
            cursor: pointer;
        }
    }

    &__icon {
        flex-shrink: 0;
    }

    &__fileName {
        @extend %dmSansFontRegular;
        font-size: rem(16px);
        line-height: rem(24px);
        color: lightness(--color-black, 20%);
        flex-grow: 1;
    }

    &__error {
        @extend %dmSansFontRegular;
        font-size: rem(13px);
        line-height: rem(16px);
        color: var(--color-semantic2);
        display: none;
    }
}
