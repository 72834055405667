@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;

.rt218-card-premi {
    $root: &;
    border-radius: rem(12px);
    width: 100%;
    min-height: rem(195px);
    background-color: var(--color-white);
    box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);

    @include respond-above(l) {
        width: 100%;
        min-height: rem(224px);
    }

    &__container {
        padding: rem(16px);
        padding-bottom: rem(24px);
        display: flex;
        flex-direction: column;
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
    }

    &__texts {
        display: flex;
        flex-direction: column;
    }

    &__imageCont {
        display: flex;
        justify-content: center;
    }

    &__image {
        max-width: rem(138px);
        max-height: rem(123px);
        justify-self: center;

        @include respond-above(l) {
            max-width: rem(166px);
            max-height: rem(168px);
        }
    }

    &__title {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        margin-bottom: rem(8px);
        color: var(--color-darkCharcoal);

        @include respond-above(l) {
            font-size: rem(20px);
            line-height: rem(28px);
        }
    }

    &__abstract {
        @extend %dmSansFontRegular;
        font-size: rem(12px);
        line-height: rem(16px);

        @include respond-above(l) {
            font-size: rem(14px);
            line-height: rem(20px);
        }
    }

    &__top {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: rem(16px);
    }

    &__or {
        @extend %dmSansFontMedium;
        color: var(--color-primaryGray);
        margin-right: rem(4px);
        font-size: rem(10px);
        line-height: rem(14px);
    }

    &__bollini {
        @extend %dmSansFontBold;
        color: var(--color-semantic4);
        font-size: rem(16px);
        line-height: rem(16px);
        border-radius: rem(12px);
        background-color: var(--color-lavender);
        margin-right: rem(16px);
        padding: rem(8px);
        white-space: nowrap;
    }

    &__punti {
        @extend %dmSansFontMedium;
        font-size: rem(12px);
        line-height: rem(12px);
        color: var(--color-semantic4);
    }

    &__suitable {
        @extend %dmSansFontBold;
        background-color: #deeacc;
        color: #689f13;
        border-radius: rem(4px);
        gap: rem(8px);
        font-size: rem(12px);
        line-height: 1;
        display: flex;
        align-items: center;
        align-self: flex-end;
        padding-left: rem(8px);
        padding-right: rem(18px);
        padding-top: rem(4px);
        padding-bottom: rem(4px);
        margin-right: rem(16px);
        margin-bottom: rem(16px);
        margin-left: rem(16px);

        &--invisible {
            visibility: hidden;
        }

        @include respond-above(l) {
            font-size: rem(18px);
        }
    }

    &--small {
        width: rem(164px);
        min-height: rem(260px);
        flex-direction: column;
        margin-bottom: rem(8px);

        @include respond-above(l) {
            width: rem(224px);
            min-height: rem(300px);
        }

        #{$root}__container {
            padding-bottom: rem(16px);
            @include respond-below(m) {
                padding: rem(8px);
            }
        }

        #{$root}__top {
            flex-direction: column;
            align-items: flex-start;
        }

        #{$root}__bollini {
            margin-bottom: rem(8px);
            margin-right: 0;
            @include respond-below(l) {
                font-size: rem(15px);
            }
        }

        #{$root}__bottom {
            flex-direction: column;
            align-items: unset;
        }

        #{$root}__imageCont {
            display: flex;
            align-items: center;

            @include respond-above(m) {
                height: rem(140px);
            }
        }

        #{$root}__image {
            max-width: rem(78px);
            max-height: rem(107px);

            @include respond-above(l) {
                max-width: rem(106px);
                max-height: rem(140px);
            }
        }

        #{$root}__title {
            font-size: rem(12px);
            line-height: rem(16px);

            @include respond-above(l) {
                font-size: rem(16px);
                line-height: rem(24px);
            }
        }

        #{$root}__texts {
            align-items: flex-start;
        }
    }
}
