@use '../../../../../style/modules/functions' as *;
@use '../../../../../style/modules/commons' as *;
@use '../../../../../style/modules/variables' as *;
@use '../../../../../style/modules/mixins' as *;


.rt250-select-pos-step1 {
    @include modal(&, true);

    &__error {
        margin-top: rem(24px);
        overflow: hidden;

        &:before {
            display: inline-block;
            font-size: rem(16px);
            content: url('#{$resourcesFolder}/corporate/icons/alert-red.svg');
            vertical-align: middle;
            margin-right: rem(16px);
        }

        @extend %font-body2;
        color: var(--color-semantic2);
        padding: rem(17px) rem(16px);
        background: lightness(--color-semantic2, 90%);
        border-radius: rem(16px);

        &--hidden {
            display: none;
        }
    }

    &__boxes {
        display: flex;
        flex-flow: column;
        margin-top: rem(24px);

        @include respond-above(l) {
            flex-flow: row-reverse;
            gap: rem(16px);
        }
    }

    &__box {
        padding: rem(24px) rem(8px) rem(24px) rem(24px);
        border-radius: 12px;
        box-shadow: 0 4px 8px -2px alpha(--color-darkCharcoal, 0.15), 0 0 1px 0 alpha(--color-darkCharcoal, 0.2);
    }
    &__box + &__box {
        margin-top: rem(16px);
    }
    @include respond-above(l) {
        &__box {
            flex: 1 0 0;
        }
        &__box,
        &__box + &__box {
            margin-top: unset;
        }
    }

    &__boxTitle {
        @extend %dmSansFontBold;
        font-size: rem(16px);
        line-height: rem(24px);
        color: var(--color-black);
    }

    &__boxText {
        @extend %dmSansFontRegular;
        font-size: rem(14px);
        line-height: rem(20px);
        color: var(--color-primaryGray);
        margin: rem(8px) 0 rem(32px);
    }

    &__boxCtas {
        display: flex;
        align-items: center;
        gap: rem(20px);
    }
}
