@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;

.rt002-cta-tertiary {

    /* STATUS */
    background-color: var(--color-white);
    color: var(--color-darkCharcoal);

    &:hover {
        color: lightness(--color-black, 10%);
    }

    &--disabled {
        pointer-events: none;
        background-color: transparent;
        color: alpha(--color-darkCharcoal, 0.2);
    }

    &--success {
        background-color: var(--color-white);
        color: var(--color-semantic1);
    }
    &--success:hover {
        color: alpha(--color-semantic1, 0.8);
    }

    &--neutralLight {
        background-color: transparent;
        color: var(--color-white);
    }
    &--neutralLight:hover {
        color: alpha(--color-darkCharcoal, 0.2);
    }
}