@use '../../../../../../style/modules/functions' as *;
@use '../../../../../../style/modules/commons' as *;
@use '../../../../../../style/modules/variables' as *;

.rt002-cta-secondary {

    /* STATUS */
    background-color: var(--color-white);
    color: var(--color-darkCharcoal);
    border: solid 2px var(--color-primary1);

    &:hover {
        color: alpha(--color-primary1, 0.9);
        border-color: alpha(--color-primary1, 0.9);
    }
    &:focus {
        border: solid 2px;
        border-color: var(--color-primary1);
    }

    &--disabled {
        pointer-events: none;
        background-color: transparent;
        color: alpha(--color-darkCharcoal, 0.2);
        border: solid 2px;
    }

    &--success {
        background-color: var(--color-white);
        color: var(--color-semantic1);
        border: solid 2px;
        border-color: var(--color-semantic1);
    }
    &--success:hover {
        color: alpha(--color-semantic1, 0.8);
        border-color: alpha(--color-semantic1, 0.8);
    }
    &--success:focus {
        border-color: var(--color-semantic1);
    }

    &--neutralDark {
        background-color: var(--color-white);
        color: var(--color-darkCharcoal);
        border: solid 2px;
        border-color: var(--color-darkCharcoal);
    }
    &--neutralDark:hover {
        color: lightness(--color-black, 10%);
        border-color: var(--color-grayscale14);
    }
    &--neutralDark:focus {
        border-color: var(--color-darkCharcoal);
    }

    &--neutralLight {
        background-color: transparent;
        color: var(--color-white);
        border: solid 2px;
        border-color: var(--color-white);
    }
    &--neutralLight:hover {
        color: var(--color-white);
        border-color: var(--color-white);
        background-color: alpha(--color-black, 0.1);
    }
    &--neutralLight:focus {
        border-color: var(--color-white);
    }
}
